import { API_URL } from "../utils/constants";

const serviceCategoriesApi = {

    getAllCategories: async () => {
        try {
            const response = await fetch(`${API_URL}/categories/join-subcategorie`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la liste des categories de service');
        }
    },

    getServicesCategoriesByBeautician: async(beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/categories/beautician/${beautician_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la liste des categories de service');
        }
    },

    // Liste des catégories
    getServiceCategoriesById: async () => {
        try {
            const response = await fetch(`${API_URL}/services/category`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la liste des categories de service');
        }
    },
    // Créer un catégorie
    createService: async(token, serviceData) => {
        try {
            const response = await fetch(`${API_URL}/services`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(serviceData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du service');
        }
    },
    // Mettre à jour un service
    updateService: async(token, serviceID, serviceData) => {
        try {
            const response = await fetch(`${API_URL}/services/${serviceID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(serviceData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du service');
        }
    },

    // Supprimer un service
    deleteService: async(token, serviceID) => {
        try {
            const response = await fetch(`${API_URL}/services/${serviceID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppresion du service');
        }
    },
    // Création d'un sous service
    createServiceSubcategories: async(token, subcategoriesData) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subcategoriesData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du sous service');
        }
    },
    // Mettre à jour un sous service
    updateServiceSubcategories: async(token, subcategoriesID, subcategoriesData) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${subcategoriesID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subcategoriesData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du sous service');
        }
    },
    // Supprimer un service
    deleteServiceSubcategories: async(token, subcategoriesID) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${subcategoriesID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppresion du sous service');
        }
    },
};

export default serviceCategoriesApi;