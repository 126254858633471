import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { motion } from "motion/react"

import defaultProfile from "../../assets/images/default-profile.png"

import { FaX } from "react-icons/fa6"
import { dashboardContext } from '../../pages/dashboard/Dashboard'
import { FaStar } from 'react-icons/fa'
import { BiStar } from 'react-icons/bi'
import { BASE_URL } from '../../utils/constants'
import MySpinner from '../loader/Spinner'
import { beauticiansSubCategoriesApi } from '../../api/beauticians_subcategories.api'
import { groupPrestationsByCategoryId } from '../../utils/functions'
import { sitesApi } from '../../api/sites.api'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import beauticianApi from '../../api/beautician.api'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import SiteInfoComponent from './SiteInfoComponent';


const UserSheet = () => {
    const {
        showDeletionModal,
        showEditModal,
        userData,
        showUserSheetModal,
        reloadTrigger,
        setShowBeauticianCommentaire,
        setReloadTrigger
    } = useContext(dashboardContext)

    const [data, setData] = useState(userData)
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [localIsAvailable, setLocalIsAvailable] = useState(false);
    const [localIsActive, setLocalIsActive] = useState(false);

    const getRating = (rate) => {
        return (
            <span className="flex items-center gap-2">
                {rate > 0 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 1 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 2 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 3 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
                {rate > 4 ? <FaStar className="text-[#bd193b]" /> : <BiStar />}
            </span>
        )
    }

    const acceptRegistration = (id, name, email) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        id,
                        email,
                        name
                    );

                    setLocalIsActive(true);

                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };

    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = (first_name, last_name, id, is_available) => {
        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${first_name} ${last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                setLocalIsAvailable(prev => !prev);
                await beauticianApi.updateAvailability(localStorage.getItem('token'), id, { is_available: !is_available });
                //setReloadTrigger(prev => !prev);
            }
        });
    };

    useEffect(() => {
        const getAllData = async () => {
            setIsLoading(true);
            setLocalIsAvailable(userData['is_available']);
            const sites = await sitesApi.getSiteByBeauticianId(userData["id"]);
            userData["social_networks"] = sites.data;
            const services = await beauticiansSubCategoriesApi.getByBauticianId(userData["id"])
            const grouped_cat = groupPrestationsByCategoryId(services);

            userData["prestations"] = grouped_cat;

            setLocalIsActive(userData['is_active']);

            setData({
                ...userData
            })
            setIsLoading(false);
        }

        getAllData()

        return () => {
            setData([])
        }
    }, [reloadTrigger])

    const budgetCategory = {
        'moderate': 'Modérée',
        'economic': 'Economique',
        'premium': 'Premium'
    };

    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile;
        try {
            return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
        } catch (error) {
            console.error('Error with image path:', error);
            return defaultProfile;
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute top-0 z-20 left-0 w-screen h-screen bg-gray-700 bg-opacity-80 flex items-center justify-center"
        >
            <div
                onSubmit={(e) => e.preventDefault()}
                className={`relative ${data['user_type'] === 'beautician' ? 'w-5/6 min-h-[50vh]' : 'w-1/2 min-h-[20vh]'} p-4 max-h-[90vh] overflow-y-auto flex flex-col gap-2 bg-white border rounded shadow`}
            >
                <button
                    onClick={() => {
                        showUserSheetModal(false);
                        setReloadTrigger(prev => !prev);
                    }}
                    className="absolute top-6 right-6"
                >
                    <FaX />
                </button>

                {isLoading ? <div>
                    <MySpinner height={"40vh"} />
                </div> :
                    <div>
                        <div className="flex items-center justify-between gap-3 py-2 px-5 bg-gray-100">
                            <div className="flex gap-3 items-center">
                                <button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={() => { showEditModal(true) }}>Modifier les informations</button>
                                <button className='px-3 py-2 text-sm rounded bg-red-500 hover:bg-red-600 text-white' onClick={() => { showDeletionModal(true) }}>Supprimer le profil</button>
                                {data['user_type'] === 'beautician' && <>
                                    {/*<button className='px-3 py-2 text-sm rounded bg-yellow-400 hover:bg-yellow-500' onClick={() => {showSiteInformation(true)}}>Modifier les caractéristiques</button>*/}
                                    {!localIsActive &&
                                        <button className='px-3 py-2 text-sm rounded text-white bg-green-500 hover:bg-green-600' onClick={() => acceptRegistration(data['id'], (data['first_name'] + ' ' + data['last_name']), data['email'])}>Accepter l'inscription</button>
                                    }
                                    <div className="flex items-center gap-2">
                                        <ReactSwitch
                                            checked={localIsAvailable}
                                            onChange={() => handleAvailabilityChange(data['first_name'], data['last_name'], data['id'], data['is_available'])}
                                            offColor="#f87171" // Couleur désactivée
                                            onColor="#4ade80" // Couleur activée
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            width={40} // Largeur du switch
                                            height={20} // Hauteur du switch
                                            handleDiameter={15}
                                        />
                                        <label htmlFor=""> Disponibilité</label>
                                    </div>
                                </>}
                            </div>
                            {data['user_type'] === 'beautician' && <>
                                <button className='px-3 py-2 text-sm rounded bg-gray-200 border border-gray-800 hover:bg-gray-300' onClick={() => navigate(`/beauticians/${data['slug']}`)}>Voir le profile</button>
                            </>}
                        </div>
                        <div className='flex w-full gap-3 mt-2'>
                            <div className={`${data['user_type'] === 'beautician' ? 'w-1/2' : 'w-full'}`}>
                                <section className="flex flex-col gap-2 mt-4 w-full">
                                    <section className={`flex gap-3 w-full`}>
                                        {
                                            data['profile_photo'] && <div className="size-48 border rounded shadow">
                                                <img className="w-full h-full" alt={data["last_name"]} src={data['profile_photo'] ? getImageUrl(data['profile_photo']) : defaultProfile} />
                                            </div>
                                        }
                                        <div className="flex flex-col gap-1 w-full">
                                            <div className='flex justify-between gap-2 w-full'>
                                                <h5 className='underline'>Informations personnelles</h5>
                                            </div>
                                            <span className="text-base"><b>Nom complet:</b> {`${data["first_name"]} ${data["last_name"]}`}</span>
                                            {data['business_name'] && <span className="text-base"><b>Nom commercial:</b> {data["business_name"]}</span>}
                                            {data['address'] && <span className="text-base"><b>Adresse:</b> {data["address"]}</span>}
                                            {data['budget_category'] && <span className="text-base"><b>Catégorie budgétaire:</b> {budgetCategory[data["budget_category"]]}</span>}
                                            {data['average_rating'] && <div className='flex items-center gap-2'><b>Note globale :</b> {getRating(data['average_rating'])}</div>}
                                            <span><b>Date inscription: </b>{format(data['created_at'], 'd MMMM yyyy à HH:mm', { locale: fr })}</span>
                                        </div>
                                    </section>
                                    <div className="flex flex-col gap-1">
                                        <h5 className='underline'>Contacts</h5>
                                        <div className="flex items-center gap-2">
                                            <span className="font-semibold">Email: </span>
                                            <span>{data["email"]}</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <span className="font-semibold">Téléphone: </span>
                                            <span>{data["phone"]}</span>
                                        </div>
                                    </div>
                                    {data['user_type'] === "beautician" && <>
                                        <div className="flex flex-col gap-1">
                                            <h5 className='underline'>A propos</h5>
                                            <p>{data['description']}</p>
                                        </div>
                                        <div>
                                            <button className='px-3 py-2 border border-gray-500 bg-gray-200 hover:bg-gray-300 rounded' onClick={() => setShowBeauticianCommentaire(true)}>Voir les commentaires</button>
                                        </div>
                                    </>}
                                </section>
                            </div>
                            {data['user_type'] === 'beautician' && <div className='w-1/2'>
                                <SiteInfoComponent />
                            </div>}
                        </div>
                    </div>
                }

            </div>
        </motion.div>
    )
}

export default UserSheet