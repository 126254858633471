import React, { useContext, useEffect } from 'react'

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { dashboardContext } from '../../pages/dashboard/Dashboard';

const ErrorPopUp = ({ message }) => {
    const { setError } = useContext(dashboardContext)

    useEffect(() => {
        setTimeout(() => setError(""), 3000)
    })

    return (
        <ToastContainer
            className="p-3"
            position={"bottom-center"}
            style={{ zIndex: 1 }}
        >
            <Toast bg="danger" >
                <Toast.Header closeButton={false} >
                    <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                    />
                    <strong className="me-auto">Une erreur s'est produite</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{message}</Toast.Body>
            </Toast>
        </ToastContainer>   
    )
}

export default ErrorPopUp