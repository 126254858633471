import React, { useState } from 'react';
import {
  User,
  Bell,
  Shield,
  Clock,
  CreditCard,
  ChevronRight,
} from 'lucide-react';
import './BeauticianSettings.css';
import AvailabilitySettings from './settings/AvailabilitySettings';
import NotificationSettings from './settings/NotificationSettings';
import SecuritySettings from './settings/SecuritySettings';
import ProfileSettings from './settings/ProfileSettings';
import AbonnementSettings from './settings/AbonnementSettings';

const BeauticianSettings = () => {
  const [activeSection, setActiveSection] = useState('profile');

  const settingsSections = [
    {
      id: 'profile',
      title: 'Gérer les informations',
      icon: <User size={20} />,
      description: 'Gérez vos informations personnelles et professionnelles'
    },
    {
      id: 'notifications',
      title: 'Notifications',
      icon: <Bell size={20} />,
      description: 'Configurez vos préférences de notifications'
    },
    {
      id: 'security',
      title: 'Sécurité et confidentialité',
      icon: <Shield size={20} />,
      description: 'Gérez la sécurité de votre compte'
    },
    {
      id: 'availability',
      title: 'Disponibilités',
      icon: <Clock size={20} />,
      description: 'Définissez vos horaires de travail'
    },
    {
      id: 'abonnement',
      title: 'Abonnements',
      icon: <CreditCard size={20} />,
      description: 'Gérez vos abonnements mensuel'
    }
  ];

  const renderSettingsContent = () => {
    switch (activeSection) {
      case 'profile':
        return (
          <ProfileSettings />
        );

      case 'notifications':
        return (
          <NotificationSettings />
        );

      case 'security':
        return (
          <SecuritySettings />
        );

      case 'availability':
        return (
          <AvailabilitySettings />
        );

      case 'abonnement':
        return (
          <AbonnementSettings />
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings-container">
      {/* Navigation des paramètres */}
      <div className="settings-navigation">
        {settingsSections.map((section) => (
          <button
            key={section.id}
            className={`settings-nav-item ${activeSection === section.id ? 'active' : ''}`}
            onClick={() => setActiveSection(section.id)}
          >
            <div className="nav-item-icon">{section.icon}</div>
            <div className="nav-item-content">
              <h3>{section.title}</h3>
              <p>{section.description}</p>
            </div>
            <ChevronRight size={20} className="nav-item-arrow" />
          </button>
        ))}
      </div>

      {/* Contenu des paramètres */}
      <div className="settings-content">
        <div className="settings-header">
          <h2>{settingsSections.find(s => s.id === activeSection)?.title}</h2>
        </div>
        {renderSettingsContent()}
      </div>
    </div>
  );
};

export default BeauticianSettings;