import Swal from "sweetalert2";

const showSuccessMessage = (message) => {
    Swal.fire({
        title: 'Succès!',
        text: message,
        icon: 'success',
        confirmButtonColor: '#bd193b'
    });
};

const showWarningMessage = (message) => {
    Swal.fire({
        title: 'Informations!',
        text: message,
        icon: 'warning',
        confirmButtonColor: '#bd193b'
    });
};

const showErrorMessage = (message) => {
    Swal.fire({
        title: 'Erreur',
        text: message,
        icon: 'error',
        confirmButtonColor: '#bd193b'
    });
};

export {showSuccessMessage, showErrorMessage, showWarningMessage};