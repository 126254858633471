import React, { useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import ShowInfoClientModal from './ShowInfoClientModal';
import './Calendar.css';

const CalendarComponent = ({ events }) => {
  const calendarRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const renderEventContent = (eventInfo) => (
    <>
      <b>{eventInfo.timeText}</b>{' '}
      <i>
        {eventInfo.event.title}:{' '}
        {eventInfo.event.extendedProps.services.map((element, index) => (
          <span key={index}>{element.subcategory_name}</span>
        ))}
      </i>
    </>
  );

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const handleTodayClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridDay"
        headerToolbar={{
          left: 'prev,next,customToday',
          center: 'title',
          right: 'timeGridDay,timeGridWeek,dayGridMonth',
        }}
        customButtons={{
          customToday: {
            text: "Aujourd'hui",
            click: handleTodayClick,
          },
        }}
        locale={frLocale}
        events={events}
        eventClick={handleEventClick}
        height="100%"
        slotMinTime="05:00:00"
        slotMaxTime="23:00:00"
        slotDuration="00:30:00"
        buttonText={{
          today: "Aujourd'hui",
          month: 'Mois',
          week: 'Semaine',
          day: 'Jour',
        }}
        allDaySlot={false}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        eventClassNames={() => ['fc-event-custom', 'rounded', 'border-0']}
        dayMaxEvents={3}
        moreLinkContent={(args) => `+${args.num} événements`}
        eventContent={renderEventContent}
      />
      {selectedEvent && (
        <ShowInfoClientModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          selectedEvent={selectedEvent}
        />
      )}
    </div>
  );
};

export default CalendarComponent;
