import React, { useState, useRef, useEffect } from 'react';
import './UserMenu.css';
import { BASE_URL } from '../../utils/constants';

const UserMenu = ({ firstName, lastName, userType, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const isDashboardEnabled = userType === 'admin' || userType === 'operator';
  //const dashboardUrl = `${BASE_URL}/dashboard`;
  const dashboardUrl = "/dashboard";
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();

  //console.log('UserMenu Props:', { firstName, lastName, userType }); // Debug log
  //console.log('isDashboardEnabled:', isDashboardEnabled); // Debug log

  const getUserRoleLabel = (type) => {
    const roles = {
      'admin': 'Administrateur',
      'operator': 'Opérateur',
      'beautician': 'Esthéticienne',
      'client': 'Client'
    };
    return roles[type] || 'Utilisateur';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    setIsOpen(false);
    if (onLogout) onLogout();
  };

  return (
    <div className="user-menu-container" ref={menuRef}>
      <button 
        className="user-avatar"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Menu utilisateur"
      >
        {initials}
      </button>

      {isOpen && (
        <>
          <div className="menu-backdrop" onClick={() => setIsOpen(false)} />
          
          <div className="user-dropdown">
            <div className="user-info">
              <div className="user-avatar-large">{initials}</div>
              <div className="user-details">
                <div className="user-name">{`${firstName} ${lastName}`}</div>
                <div className="user-role">{getUserRoleLabel(userType)}</div>
              </div>
            </div>

            <div className="dropdown-divider" />
            
            <div className="dropdown-actions">
              {isDashboardEnabled && (
                <>
                  <a 
                    href={dashboardUrl}
                    className="logout-button"
                    style={{ marginBottom: '10px' }}
                  >
                    <span className="logout-icon">📊</span>
                    Dashboard
                  </a>
                  <div className="dropdown-divider" />
                </>
              )}

              {userType === "client" &&  <>
                  <a 
                    href={"/client-dashboard"}
                    className="logout-button"
                    style={{ marginBottom: '10px' }}
                  >
                    <span className="logout-icon">📊</span>
                    Dashboard
                  </a>
                  <div className="dropdown-divider" />
                </>}

                {userType === "beautician" &&  <>
                  <a 
                    href={"/beautician-dashboard"}
                    className="logout-button"
                    style={{ marginBottom: '10px' }}
                  >
                    <span className="logout-icon">📊</span>
                    Dashboard
                  </a>
                  <div className="dropdown-divider" />
                </>}
              <button 
                className="logout-button" 
                onClick={handleLogout}
              >
                <span className="logout-icon">→</span>
                Déconnexion
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserMenu;