import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import {
    UserCircle,
    Calendar,
    Clock,
    Bell
} from 'lucide-react'

const ClientPortalLayout = () => {

    const menuItems = [
        {
            title: "Mon Profil",
            icon: <UserCircle size={20} />,
            path: "/client-dashboard/",
            exact: true, // Propriété pour activer uniquement ce chemin exact
        },
        {
            title: "Mes rendez-vous",
            icon: <Calendar size={20} />,
            path: "/client-dashboard/agenda",
        }, 
        {
            title: 'Historiques',
            icon: <Clock size={20} />,
            path: "/client-dashboard/historiques",
        },
        {
            title: 'Notifications',
            icon: <Bell size={20} />,
            path: "/client-dashboard/notifications",
        }
    ]


    return (
        <div className="min-h-[82vh] flex border">
            <aside className="w-1/4 flex flex-col border-r">
                <h1 className="p-3 text-xl text-[#bd193b] font-semibold border-b">BeautyPro</h1>

                <div className="p-3 flex flex-col gap-4">
                    {menuItems.map((item, index) =>
                        <NavLink
                            key={index}
                            to={item.path}
                            //onClick={(e) => handleRedirection(e, menu.path, menuItems, setMenuItems, setActiveLink)}
                            end={item.exact}
                            className={({ isActive }) =>
                                `flex items-center gap-2 no-underline duration-300 ${isActive ? "text-[#bd193b]" : "text-gray-800"}`
                            }
                        >
                            {item.icon}
                            {item.title}
                        </NavLink>
                    )}
                </div>
            </aside>
            <div className="w-full flex">
                <Outlet />
            </div>
        </div>
    )
}

export default ClientPortalLayout;