import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, X, MapPin, Loader2 } from 'lucide-react';
import { AppContext } from '../../contexts/AppContext';
import debounce from 'lodash/debounce';
import './SearchBar.css';

const DEBOUNCE_MS = 300;
const MIN_SEARCH_LENGTH = 2;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const searchCache = new Map();

const SearchBar = () => {
  const navigate = useNavigate();
  const { beauticians, serviceCategories } = useContext(AppContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  // Fermeture suggestions au clic extérieur
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Fonction de recherche avec cache
  const performSearch = useCallback(async (query) => {
    const cacheKey = query.toLowerCase();
    const now = Date.now();

    // Vérifier le cache
    if (searchCache.has(cacheKey)) {
      const { data, timestamp } = searchCache.get(cacheKey);
      if (now - timestamp < CACHE_DURATION) {
        return data;
      }
      searchCache.delete(cacheKey);
    }

    try {
      const params = new URLSearchParams();
      params.append('query', query);

      // Ajouter la géolocalisation si disponible
      if ('geolocation' in navigator) {
        try {
          const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, {
              timeout: 5000,
              maximumAge: 300000
            });
          });
          params.append('lat', position.coords.latitude);
          params.append('lng', position.coords.longitude);
        } catch (error) {
          console.log('Géolocalisation non disponible');
        }
      }

      const response = await fetch(`/api/geo/search?${params.toString()}`);
      if (!response.ok) throw new Error('Erreur réseau');

      const data = await response.json();
      
      // Mettre en cache
      searchCache.set(cacheKey, {
        data,
        timestamp: now
      });

      return data;
    } catch (error) {
      throw new Error('Impossible de récupérer les suggestions');
    }
  }, []);

  // Debounce de la recherche
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      if (value.length < MIN_SEARCH_LENGTH) {
        setSuggestions([]);
        setShowSuggestions(false);
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const results = await performSearch(value);
        const formattedSuggestions = [
          ...(results.prestations || []),
          ...(results.subCategories || []),
          ...(results.communes || []),
          ...(results.beauticians || [])
        ];
        setSuggestions(formattedSuggestions);
        setShowSuggestions(true);
      } catch (err) {
        setError(err.message);
        setSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    }, DEBOUNCE_MS),
    []
  );

  // Dans SearchBar.js, modifiez la fonction handleSearch
  const handleSearch = (searchValue, type, params = {}) => {
    const searchParams = new URLSearchParams();
    
    switch(type) {
        case 'prestation':
            if (params.categoryName) {  // C'est une sous-catégorie
                // Utiliser params.id directement pour la sous-catégorie
                searchParams.append('subcategoryId', params.id);
            } else {
                searchParams.append('categoryId', params.id);
            }
            break;
        case 'ville':
            if (params.coordinates) {
                searchParams.append('lat', params.coordinates[0]);
                searchParams.append('lng', params.coordinates[1]);
            }
            searchParams.append('city', searchValue);
            break;
        case 'business':
            searchParams.append('beautician', params.id);
            break;
        default:
            searchParams.append('q', searchValue);
    }

    navigate({
        pathname: '/search',
        search: searchParams.toString()
    });
};

  // Gestion de la navigation clavier
  const handleKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex(prev => 
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(prev => prev > -1 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex > -1) {
          const selected = suggestions[selectedIndex];
          handleSearch(selected.text, selected.type, {
            id: selected.id,
            subCategoryId: selected.subCategoryId,
            coordinates: selected.coordinates
          });
        } else if (searchTerm.length >= MIN_SEARCH_LENGTH) {
          handleSearch(searchTerm, 'query');
        }
        break;
      case 'Escape':
        e.preventDefault();
        setShowSuggestions(false);
        break;
      default:
        break;
    }
  };

  // Rendu du composant
  return (
    <div className="search-bar-wrapper" ref={wrapperRef}>
      <div className="search-bar-container">
        <div className="search-input-group">
          <input
            ref={inputRef}
            type="text"
            className="search-input"
            placeholder="Rechercher un service, une esthéticienne..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              debouncedSearch(e.target.value);
            }}
            onFocus={() => searchTerm.length >= MIN_SEARCH_LENGTH && setShowSuggestions(true)}
            onKeyDown={handleKeyDown}
            aria-expanded={showSuggestions}
            aria-controls="search-suggestions"
            aria-describedby={error ? "search-error" : undefined}
          />
          {searchTerm && (
            <button
              className="clear-button"
              onClick={() => {
                setSearchTerm('');
                setSuggestions([]);
                setShowSuggestions(false);
                inputRef.current?.focus();
              }}
              aria-label="Effacer la recherche"
            >
              <X size={16} />
            </button>
          )}
          <button 
            className="search-button" 
            onClick={() => searchTerm.length >= MIN_SEARCH_LENGTH && handleSearch(searchTerm, 'query')}
          >
            <Search size={24} color="white"/>
          </button>
        </div>

        {error && (
          <div id="search-error" className="error-message" role="alert">
            {error}
          </div>
        )}
        
        {showSuggestions && (
          <div 
            id="search-suggestions" 
            className="suggestions-dropdown"
            role="listbox"
          >
            {isLoading ? (
              <div className="suggestion-loading">
                <Loader2 className="animate-spin" size={20} />
                <span>Recherche en cours...</span>
              </div>
            ) : suggestions.length > 0 ? (
              suggestions.map((suggestion, index) => (
                <div
                    key={`${suggestion.type}-${index}`}
                    className={`suggestion-item ${selectedIndex === index ? 'selected' : ''}`}
                    onClick={() => handleSearch(suggestion.text, suggestion.type, {
                        id: suggestion.id,
                        subCategoryId: suggestion.subCategoryId,
                        categoryName: suggestion.categoryName,  // Ajoutez cette ligne
                        coordinates: suggestion.coordinates
                    })}
                    role="option"
                    aria-selected={selectedIndex === index}
                >
                    <div className="suggestion-content">
                        <span className="suggestion-text">{suggestion.text}</span>
                        {suggestion.subtext && (
                            <span className="suggestion-subtext">
                                {suggestion.subtext}
                            </span>
                        )}
                        {suggestion.distance && (
                            <span className="suggestion-distance">
                                <MapPin size={14} />
                                {Math.round(suggestion.distance/1000)}km
                            </span>
                        )}
                    </div>
                    <span className={`suggestion-type ${suggestion.type}`}>
                        {suggestion.typeLabel}
                    </span>
                </div>
            ))
            ) : (
              <div className="suggestion-empty">Aucun résultat trouvé</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;