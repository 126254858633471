import React, { useContext, useState } from 'react'

import { BiStar } from 'react-icons/bi'
import { FaStar, FaPlus, FaX } from 'react-icons/fa6'
import { dashboardContext } from '../../pages/dashboard/Dashboard'

import reviewAPI from '../../api/review.api'
import { FaSpinner } from 'react-icons/fa'
import Swal from 'sweetalert2'
import ratingApi from '../../api/rating.api'
import { Modal } from 'react-bootstrap'

const RenderRate = ({ type, rate, setRate }) => {
    const updateRate = (e) => {
        setRate({
            ...rate,
            [type]: parseInt(e.target.dataset.rate)
        })
    }

    const categories = {
        "Welcome": "Acceuil",
        "Cleanliness": "Propreté",
        "Ambiance": "Cadre & Ambiance",
        "Service Quality": "Qualité de la préstation"
    }

    return <>
        <section className="flex flex-col gap-1">
            <label>{categories[type]}</label>
            <span className="flex items-center gap-2">
                <input type="checkbox" data-rate="1" id={`${type}-rate-1`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="2" id={`${type}-rate-2`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="3" id={`${type}-rate-3`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="4" id={`${type}-rate-4`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="5" id={`${type}-rate-5`} onClick={updateRate} hidden />

                { rate[type] >= 1 ? <label htmlFor="rate-1" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-1`}><BiStar /></label> }
                { rate[type] >= 2 ? <label htmlFor="rate-2" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-2`}><BiStar /></label> }
                { rate[type] >= 3 ? <label htmlFor="rate-3" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-3`}><BiStar /></label> }
                { rate[type] >= 4 ? <label htmlFor="rate-4" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-4`}><BiStar /></label> }
                { rate[type] >= 5 ? <label htmlFor="rate-5" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-5`}><BiStar /></label> }
            </span>
        </section>
    </>
}

const AddReview = ({ beautician_id, showAddReviewPanel, setShowAddReviewPanel, setReloadTrigger }) => {
    const [rate, setRate] = useState({
        "Welcome": 0,
        "Cleanliness": 0,
        "Ambiance": 0,
        "Service Quality": 0
    })

    const [commentaire, setCommentaire] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const validate = async() => {
        try {

            setIsLoading(true)
            const token = localStorage.getItem("token")
            const commentateur = `${localStorage.getItem("lastName")} ${localStorage.getItem("firstName")}`
    
            const data = {
                beautician_id: beautician_id,
                commentateur,
                comment: commentaire
            }
    
            const response = await reviewAPI.createReview(token, data)
    
            if(response.status === 500) {
                Swal.fire("Error", response.message, 'error');
            }
            else {
                const [_] = await Promise.all([
                    ratingApi.addRating(beautician_id, "Welcome", rate["Welcome"], response.data.review.id),
                    ratingApi.addRating(beautician_id, "Ambiance", rate["Ambiance"], response.data.review.id),
                    ratingApi.addRating(beautician_id, "Cleanliness", rate["Cleanliness"], response.data.review.id),
                    ratingApi.addRating(beautician_id, "Service Quality", rate["Service Quality"], response.data.review.id)
                ])
                
                Swal.fire("Succès", 'Commentaire ajouté avec succès!', 'success');
                setShowAddReviewPanel(false);
            }
    
            setIsLoading(false);
            setReloadTrigger(prev => !prev);
        }
        catch(error) {
            Swal.fire("Erreur", error.message, "error");
        }
    }

    return (
        <Modal
            centered
            show={showAddReviewPanel}
            onHide={() => setShowAddReviewPanel(false)}
        >
            <form
                onSubmit={(e) => e.preventDefault()}
                className="relative p-4 flex flex-col gap-3"
            >
                <button className="absolute top-6 right-6" onClick={() => setShowAddReviewPanel(false)}><FaX/></button>
                
                <h2 className="text-2xl text-center">Ajouter un commentaire</h2>

                <RenderRate type="Welcome" rate={rate} setRate={setRate}/>
                <RenderRate type="Cleanliness" rate={rate} setRate={setRate}/>
                <RenderRate type="Ambiance" rate={rate} setRate={setRate}/>
                <RenderRate type="Service Quality" rate={rate} setRate={setRate}/>

                <textarea
                    value={commentaire}
                    onChange={(e) => setCommentaire(e.target.value)}
                    placeholder="Ecrire votre commentaire..."
                    className="p-3 outline-[#bd193b] border rounded shadow resize-none"
                ></textarea>

                <div className="flex">
                    { isLoading ?
                    <button
                        disabled
                        onClick={validate}
                        className="px-3 py-2 ms-auto flex items-center gap-2 text-white bg-red-200 rounded shadow"
                    >
                        <FaSpinner className="animate-spin" /> Loading
                    </button> :
                    <button
                        onClick={validate}
                        className="px-3 py-2 ms-auto flex items-center gap-2 text-sm text-white bg-[#bd193b] rounded shadow"
                    >
                        <FaPlus /> Ajouter
                    </button> }
                </div>
            </form>
        </Modal>
    )
}

export default AddReview