import React, { useState } from 'react';
import './SignupModal.css';
import logo from '../../assets/images/8.png';
import userApi from '../../api/user.api';
import {showSuccessMessage} from '../../utils/messageHelper';

const SignupModal = ({ 
  isOpen, 
  onClose, 
  onLoginClick, 
  onSignupSuccess,
  redirectAfterSignup = true 
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    password: '',
    confirmPassword: ''
  });
  
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSignupSuccess = async (response) => {
    // Stocker les informations d'authentification
    localStorage.setItem('token', response.token);
    localStorage.setItem('userId', response.userId);
    localStorage.setItem('userType', response.userType);

    if (onSignupSuccess) {
      onSignupSuccess();
    }

    // Ne rediriger que si redirectAfterSignup est true
    if (redirectAfterSignup) {
      const baseUrl = window.location.origin;
      if (['admin', 'operator'].includes(response.userType)) {
        window.location.href = `${baseUrl}/dashboard`;
      } else {
        onClose();
        window.location.reload();
      }
    } else {
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (name === 'confirmPassword' || name === 'password') {
      if (name === 'confirmPassword' && value !== formData.password) {
        setPasswordError('Les mots de passe ne correspondent pas');
      } else if (name === 'password' && value !== formData.confirmPassword && formData.confirmPassword) {
        setPasswordError('Les mots de passe ne correspondent pas');
      } else {
        setPasswordError('');
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      password: '',
      confirmPassword: ''
    });
    setError('');
    setPasswordError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Les mots de passe ne correspondent pas');
      return;
    }

    if (formData.password.length < 6) {
      setPasswordError('Le mot de passe doit contenir au moins 6 caractères');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const userData = {
        first_name: formData.firstName.trim(),
        last_name: formData.lastName.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone.trim(),
        password: formData.password,
        user_type: 'client' // Type par défaut pour toute nouvelle inscription
      };

      const response = await userApi.registerTemporaryUser(userData);

      if (response.success) {
        // Réinitialiser le formulaire
        resetForm();
        setIsLoading(false);
        showSuccessMessage('Nous avons envoyé un email de verification à votre adresse email, Consulter le pour confirmer votre inscription!')
        //window.location = window.location.origin + "?status=new-user"
      } else {
        setError(response.message || 'Une erreur est survenue lors de l\'inscription');
      }
    } catch (error) {
      setError(error.message || 'Une erreur est survenue lors de l\'inscription');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetForm();
    if (onClose) {
      onClose();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal-content signup-content" onClick={handleContentClick}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}

        <button 
          className="modal-close" 
          onClick={handleClose}
          type="button"
          aria-label="Fermer"
          disabled={isLoading}
        >
          ×
        </button>
        
        <div className="modal-logo">
          <img src={logo} alt="Logo" />
        </div>

        <h2 className="modal-title">Créer un compte</h2>
        
        {error && (
          <div className="error-message global-error">
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="signup-form">
          <div className="form-row">
            <div className="form-group">
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Nom"
                required
                disabled={isLoading}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Prénom"
                required
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Adresse email"
              required
              disabled={isLoading}
            />
          </div>

          <div className="form-group">
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Téléphone"
              required
              disabled={isLoading}
              pattern="[0-9]{10}"
              title="Veuillez entrer un numéro de téléphone valide (10 chiffres)"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Mot de passe"
              required
              disabled={isLoading}
              minLength="6"
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirmer le mot de passe"
              required
              disabled={isLoading}
            />
            {passwordError && <span className="error-message">{passwordError}</span>}
          </div>

          <button 
            type="submit" 
            className="signup-button"
            disabled={isLoading}
          >
            {isLoading ? 'Inscription en cours...' : 'S\'inscrire'}
          </button>

          <div className="login-link">
            Déjà membre ? 
            <button 
              type="button" 
              onClick={onLoginClick}
              className="link-button"
              disabled={isLoading}
            >
              Se connecter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupModal;