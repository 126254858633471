import axios from "axios";
import { API_URL } from "../utils/constants";

export default {
    addRating: async(beautician_id, category, rating, comment_id) => {
        try {
            console.log('Sending rating data:', {
                beautician_id,
                category,
                rating,
                comment_id
            });
            
            const results = await axios.post(`${API_URL}/rating`, {
                beautician_id,
                category,
                rating,
                comment_id
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
    
            console.log('API Response:', results);
            const data = results.data;
            console.log('Parsed data:', data);
    
            return data;
        }
        catch(error) {
            console.error("Erreur complète:", error);
            console.log("Message d'erreur:", error.message);
            console.log("Réponse de l'API si disponible:", error.response?.data);
            return false;
        }
    },
    getRatingByBeauticianId: async(beautician_id) => {
        try {
            const results = await fetch(`${API_URL}/rating/${beautician_id}`);
            const response = await results.json();
            
            // Retournons la réponse complète
            return response;
        }
        catch(error) {
            console.error("Une erreur s'est produite:", error.message);
            throw error;
        }
    },

    getRatingByCommentId: async(comment_id) => {
        try {
            const response = await fetch(`${API_URL}/rating/comments/${comment_id}`);
            const data = await response.json();
            return data;
        }
        catch(error) {
            console.error("Une erreur s'est produite:", error.message);
            throw new Error('Erreur lors de la récupération de la note');
        }
    }
}