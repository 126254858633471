import { API_URL } from "../utils/constants";

const reviewApi = {
    // Liste des tout les avis
    getAllReview: async (limit=null) => {
        try {
            let url = `${API_URL}/review`;
            if(limit) {
                url += `?limit=${limit}`;
            }
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du liste des avis');
        }
    },
    // Liste des avis sur une esthéticienne
    getBeauticianReview: async (beauticianID) => {
        try {
            const response = await fetch(`${API_URL}/review/beautician/${beauticianID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error(`Erreur lors de la récupération des avis sur l'esthéticienne ${beauticianID}`);
        }
    },
    // Obtention d'un avis par ID
    getReviewByID: async (reviewID) => {
        try {
            const response = await fetch(`${API_URL}/review/${reviewID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'avis');
        }
    },
    // Création d'un avis
    createReview: async(token, reviewData) => {
        try {
            const response = await fetch(`${API_URL}/review`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(reviewData)
            })
            
            return {
                status: response.status,
                data: await response.json()
            }
        }
        catch(err) {
            return {
                status: 500,
                message: "Une Erreur est survenu lors de la création du commentaire"
            }
        }
    },
    // Mettre à jours un avis
    updateReview: async(token, reviewID, reviewData) => {
        try {
            const response = await fetch(`${API_URL}/review/${reviewID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reviewData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours de l\'avis');
        }
    },
    // Supprimer un avis
    deleteReview: async(token, reviewID) => {
        try {
            const response = await fetch(`${API_URL}/review/${reviewID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression de l\'avis');
        }
    }
};

export default reviewApi;