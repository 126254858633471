import React from 'react';
import { Star } from 'lucide-react';
import './Filters.css';

const Filters = ({ 
    selectedServices, 
    onServicesChange,
    selectedPrices,
    onPricesChange,
    minimumRating,
    onRatingChange,
    radius,
    onRadiusChange,
    serviceCategories,
    priceRanges
}) => {
    const ratings = [5, 4, 3, 2, 1];

    // Helper pour obtenir le symbole € basé sur la catégorie
    const getPriceSymbol = (category) => {
        switch(category) {
            case 'economic': return '€';
            case 'moderate': return '€€';
            case 'premium': return '€€€';
            default: return '';
        }
    };


    return (
        <div className="filters-container">
            {/* Services */}
            <div className="filter-section">
                <h3>Services ({serviceCategories?.length || 0} catégories)</h3>
                <div className="services-grid">
                    {serviceCategories?.map((service) => (
                        <label key={service.id} className="service-checkbox">
                            <input
                                type="checkbox"
                                checked={selectedServices.includes(service.id)}
                                onChange={() => {
                                    const updated = selectedServices.includes(service.id)
                                        ? selectedServices.filter(id => id !== service.id)
                                        : [...selectedServices, service.id];
                                    onServicesChange(updated);
                                }}
                            />
                            <span>{service.name}</span>
                            <span className="count">{service.count || 0}</span>
                        </label>
                    ))}
                </div>
            </div>

            {/* Prix */}
            <div className="filter-section">
                <h3>Budget</h3>
                {['economic', 'moderate', 'premium'].map((category) => {
                    const count = priceRanges?.find(pr => pr.budget_category === category)?.count || 0;
                    return (
                        <label key={category} className="price-checkbox">
                            <input
                                type="checkbox"
                                checked={selectedPrices.includes(category)}
                                onChange={() => {
                                    const updated = selectedPrices.includes(category)
                                        ? selectedPrices.filter(price => price !== category)
                                        : [...selectedPrices, category];
                                    onPricesChange(updated);
                                }}
                            />
                            <span>{getPriceSymbol(category)} </span>
                            <span className="count">{count}</span>
                        </label>
                    );
                })}
            </div>

            {/* Note minimum */}
            <div className="filter-section">
                <h3>Note minimum</h3>
                <div className="rating-filter">
                    {ratings.map((rating) => (
                        <label key={rating} className="rating-option">
                            <input
                                type="radio"
                                name="rating"
                                value={rating}
                                checked={minimumRating === rating}
                                onChange={() => onRatingChange(rating === minimumRating ? 0 : rating)}
                                className="rating-radio"
                            />
                            <div className="rating-content">
                                <div className="stars">
                                    {[...Array(5)].map((_, index) => (
                                        <Star
                                            key={index}
                                            size={16}
                                            className={`star ${index < rating ? 'filled' : ''}`}
                                        />
                                    ))}
                                </div>
                                <span className="rating-text">
                                    {rating} étoile{rating > 1 ? 's' : ''} et plus
                                </span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Filters;