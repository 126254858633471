import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Calendar,
  TrendingUp,
  Users,
  Clock,
  Award,
  Star,
  ChevronRight,
  DollarSign
} from 'lucide-react';
import './BeauticianDashboard.css';
import { AppContext } from "../../../contexts/AppContext"
import appointmentApi from "../../../api/appointement.api"
import userApi from '../../../api/user.api';
import beauticianApi from '../../../api/beautician.api';
import { Link, useNavigate } from 'react-router-dom';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';

const checkDateValidation = (appointment_date, start_time) => {
  const dateTime = new Date(appointment_date)
  const [hours, minutes, seconds] = start_time.split(':').map(Number)

  dateTime.setHours(hours)
  dateTime.setMinutes(minutes)
  dateTime.setSeconds(seconds)
  return dateTime >= new Date().setHours(0, 0) && dateTime <= new Date().setHours(24, 0);
}

const getDuration = (startTime, endTime) => {
  const start = new Date("1970-01-01T" + startTime + "Z");
  const end = new Date("1970-01-01T" + endTime + "Z");

  const diffInMs = end - start;
  const hours = Math.floor(diffInMs / 3600000);
  const minutes = Math.floor((diffInMs % 3600000) / 60000);

  return `${hours > 0 ? hours + " h " : ""}${minutes} min`;
}

const BeauticianDashboard = () => {
  //const { connectedUser } = useContext(AppContext);

  const navigate = useNavigate();

  const { isParentLoading, profileData } = useContext(BeauticianPortalContext);

  const [serviceDone, setServiceDone] = useState(0)
  const [dailyRevenue, setDailyRevenue] = useState(0);
  const [averageDuration, setAverageDuration] = useState(0);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pendingAppointmentCount, setPendingAppointmentCount] = useState(0);
  const [kpiData, setKpiData] = useState([
    { title: 'Clients du jour', value: 0, icon: <Users size={24} />, trend: 0, color: '#0ea5e9' },
    { title: 'Taux occupation', value: 0, icon: <Clock size={24} />, trend: 0, color: '#8b5cf6' },
    { title: 'Nouveaux clients', value: 0, icon: <Award size={24} />, trend: 0, color: '#f59e0b' },
    { title: 'Avis positifs', value: 0, icon: <Star size={24} />, trend: 0, color: '#10b981' },
  ]);

  const countPendingAppointment = async () => {
    try {
      const result = await appointmentApi.listAppointmentsValidByBeauticianJoinUserAndServices(localStorage.getItem('token'), profileData.beautician_id);
      const pendingAppt = [];
      if (result && Array.isArray(result)) {
        result.map((element) => {
          if (element.status === "pending") {
            pendingAppt.push(element);
          }
        });
      }
      setPendingAppointmentCount(pendingAppt.length);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const beautician = await userApi.fetchBeauticianUserByUserId(localStorage.getItem("token"), localStorage.getItem("userId"));

        if (beautician) {
          const b = await beauticianApi.getBeauticianById(beautician.beautician_id);

          const result = await appointmentApi.getAppointmentByBeautician(beautician.beautician_id);

          setServiceDone(result.filter(r => r.status === "completed").length)
          setDailyRevenue(result.filter(r => r.status === "completed").reduce((sum, item) => sum + parseFloat(item.total_prices), 0))

          setKpiData(prevKpiData => prevKpiData.map(kpi => {
            if (kpi.title === 'Avis positifs')
              return { ...kpi, value: b.data.average_rating }
            else if (kpi.title === 'Clients du jour')
              return { ...kpi, value: result.filter(r => r.appointment_date.startsWith((new Date()).toISOString().split('T')[0])).length }
            else if (kpi.title === 'Nouveaux clients')
              return { ...kpi, value: result.filter(r => checkDateValidation(r.created_at, r.start_time)).length }
            else
              return kpi
          }));

          setUpcomingAppointments(result.filter(r => r.status === "confirmed" && checkDateValidation(r.appointment_date, r.start_time)).map(r => ({
            time: r.start_time,
            client: `${r.last_name} ${r.first_name}`,
            service: r.service_name,
            duration: getDuration(r.start_time, r.end_time),
            status: r.status
          })));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    fetchDashboardData();
    countPendingAppointment();
  }, []);

  const kpiCards = useMemo(() => kpiData.map((kpi, index) => (
    <div key={index} className="kpi-card">
      <div className="kpi-icon" style={{ backgroundColor: `${kpi.color}15`, color: kpi.color }}>
        {kpi.icon}
      </div>
      <div className="kpi-info">
        <span className="kpi-title">{kpi.title}</span>
        <div className="kpi-stats">
          <span className="kpi-value">{kpi.value}</span>
          <span className="kpi-trend">{kpi.trend}</span>
        </div>
      </div>
    </div>
  )), [kpiData]);

  const appointmentCards = useMemo(() => upcomingAppointments.map((appointment, index) => (
    <div key={index} className={`appointment-card ${appointment.status}`}>
      <div className="appointment-time">{appointment.time}</div>
      <div className="appointment-info">
        <div className="client-name">{appointment.client}</div>
        <div className="service-details">
          {appointment.service} • {appointment.duration}
        </div>
      </div>
      <div className="appointment-status">
        {appointment.status === 'confirmed' ? 'Confirmé' : 'En attente'}
      </div>
    </div>
  )), [upcomingAppointments]);

  return (
    <div className="dashboard">
      <div className="dashboard-wrapper">
        <header className="dashboard-header">
          <div className="header-content flex justify-between">
            <h1>Tableau de bord</h1>
            <div className="today-date">
              {new Date().toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </div>
            <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={(e) => {navigate('/beautician-dashboard/agenda?page=pending')}}>
              <Clock size={16} /> Réservation en attentes
              <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                {pendingAppointmentCount}
              </span>
            </button>
          </div>
        </header>

        {(!isParentLoading && profileData?.is_active) ?
          <div class='flex flex-col-reverse'>
            <div className="kpi-grid">
              {kpiCards}
            </div>

            <div className="dashboard-main">
              <div className="dashboard-section appointments-section">
                <div className="section-header">
                  <div className="section-title">
                    <Calendar size={20} />
                    <h2>Rendez-vous du jour</h2>
                  </div>
                  <Link to="agenda" className="view-all-button no-underline">
                    <span>Voir tout</span>
                    <ChevronRight size={16} />
                  </Link>
                </div>
                <div className="appointments-list">
                  {appointmentCards}
                </div>
              </div>

              <div className="dashboard-section stats-section">
                <div className="section-header">
                  <div className="section-title">
                    <TrendingUp size={20} />
                    <h2>Performances du jour</h2>
                  </div>
                </div>
                <div className="stats-grid">
                  <div className="stat-card">
                    <DollarSign size={20} className="stat-icon" />
                    <div className="stat-title">Chiffre d'affaires</div>
                    <div className="stat-value">{dailyRevenue} €</div>
                    <div className="stat-trend positive"></div>
                  </div>
                  <div className="stat-card">
                    <Clock size={20} className="stat-icon" />
                    <div className="stat-title">Services réalisés</div>
                    <div className="stat-value">{serviceDone}</div>
                    <div className="stat-trend positive"></div>
                  </div>
                  <div className="stat-card">
                    <Users size={20} className="stat-icon" />
                    <div className="stat-title">Durée moyenne</div>
                    <div className="stat-value">{averageDuration} min</div>
                    <div className="stat-trend neutral"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <div className=''>
              <h3>Votre compte est en cours de validation!</h3>
              <p>Vous allez recevoir un mail lorsque votre compte sera confirmé par l'administration.</p>
          </div>
        }
      </div>
    </div>
  );
};

export default BeauticianDashboard;
