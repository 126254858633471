import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';

const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  // Initialiser `selectedServices` depuis le sessionStorage (ou un tableau vide par défaut)
  const [selectedServices, setSelectedServices] = useState(() => {
    const storedServices = sessionStorage.getItem('selectedServices');
    return storedServices ? JSON.parse(storedServices) : [];
  });

  const [currentBeauticianSlug, setCurrentBeauticianSlug] = useState(() => {
    const storedBeauticianId = sessionStorage.getItem('currentBeauticianSlug');
    return storedBeauticianId ? JSON.parse(storedBeauticianId) : null;
  });

  const [error, setError] = useState(null);
  const [giveAGift, setGiveAGift] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  // Synchroniser `selectedServices` avec le sessionStorage
  useEffect(() => {
    sessionStorage.setItem('selectedServices', JSON.stringify(selectedServices));
  }, [selectedServices]);

  // Synchroniser `currentBeauticianSlug` avec le sessionStorage
  useEffect(() => {
    sessionStorage.setItem('currentBeauticianSlug', JSON.stringify(currentBeauticianSlug));
  }, [currentBeauticianSlug]);

  const addService = (service, slug) => {
    try {
      if (selectedServices.length === 0) {
        const newServices = [service];
        setCurrentBeauticianSlug(slug);
        setSelectedServices(newServices);
        return { success: true };
      }

      const newServices = [...selectedServices, service];
      setSelectedServices(newServices);
      return { success: true };
    } catch (err) {
      toast.error('Erreur lors de l\'ajout du service');
      return { success: false };
    }
  };

  const removeService = (serviceId) => {
    try {
      const updatedServices = selectedServices.filter(
        (service) => service.beautician_subcategory_id !== serviceId
      );
  
      setSelectedServices(updatedServices);
  
      // Si aucun service n'est sélectionné après la suppression, réinitialisez l'ID de l'esthéticienne
      if (updatedServices.length === 0) {
        setCurrentBeauticianSlug(null);
        setError(null);
      }
    } catch (err) {
      const errorMessage = 'Une erreur est survenue lors de la suppression du service';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };
  

  const clearBooking = () => {
    try {
      sessionStorage.removeItem('selectedServices');
      setSelectedServices([]);
      sessionStorage.removeItem('currentBeauticianSlug');
      setCurrentBeauticianSlug(null);
      setError(null);
      //toast.success('Réservation réinitialisée');
    } catch (err) {
      const errorMessage = 'Une erreur est survenue lors de la réinitialisation';
      setError(errorMessage);
      //toast.error(errorMessage);
    }
  };

  return (
    <BookingContext.Provider
      value={{
        selectedServices,
        currentBeauticianSlug: currentBeauticianSlug,
        error,
        addService,
        removeService,
        clearBooking,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        giveAGift,
        setGiveAGift
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export const useBooking = () => {
  const context = useContext(BookingContext);
  if (!context) throw new Error('useBooking must be used within a BookingProvider');
  return context;
};

export default BookingContext;