import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  Calendar as CalendarIcon,
  Clock,
} from 'lucide-react';
import './BeauticianAgenda.css';
import appointmentApi from '../../../api/appointement.api';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Swal from 'sweetalert2';
import { sendEmailBookingStatusToClient } from '../../../utils/mailServices';
import CalendarComponent from './agenda/CalendarComponent';
import { useSearchParams } from 'react-router-dom';

const BeauticianAgenda = () => {
  const [activePage, setActivePage] = useState('my_agenda');
  const [confirmedAppointment, setConfirmedAppointment] = useState([]);
  const [pendingAppointment, setPendingAppointment] = useState([]);
  const { profileData, isParentLoading } = useContext(BeauticianPortalContext);
  const [changeTrigger, setChangeTrigger] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (profileData) {
      fetchValidAppointment();
      let page = searchParams.get('page');
      if(page && page === 'pending') {
        setActivePage('booking_pending');
      }
    }
  }, [isParentLoading, changeTrigger]);


  const fetchValidAppointment = async () => {
    try {
      const result = await appointmentApi.listAppointmentsValidByBeauticianJoinUserAndServices(localStorage.getItem('token'), profileData.beautician_id);
      const confirmedAppt = [];
      const pendingAppt = [];
      if (result && Array.isArray(result)) {
        result.map((element) => {
          if (element.status === "pending") {
            pendingAppt.push(element);
          } else if (element.status === "confirmed") {
            confirmedAppt.push(element);
          }
        });
      }
      const formatedConfirmedAppt = formatConfirmedAppointment(confirmedAppt);
      setConfirmedAppointment(formatedConfirmedAppt);
      setPendingAppointment(pendingAppt);
    } catch (error) {
      console.log(error);
    }
  }

  const formatConfirmedAppointment = (confirmedAppt) => {
    const formatedConfirmedAppt = [];
    confirmedAppt.forEach((element) => {
      let start = new Date(element.appointment_date).setHours(element.start_time.split(":")[0], element.start_time.split(":")[1]);
      let end = new Date(element.appointment_date).setHours(element.end_time.split(":")[0], element.end_time.split(":")[1]);
      const formatedElement = {
          id: element.id,
          appointment_id: element.id,
          title: `${element.first_name}`,
          user_id: element.user_id,
          appointment_date: element.appointment_date,
          start_time: element.start_time,
          end_time: element.end_time,
          start: start,
          end: end,
          status: element.status,
          total_prices: element.total_prices,
          payed: element.payed,
          payment_type: element.payment_type,
          payment_intent: null,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
          phone: element.phone,
          services: element.services
      }
      formatedConfirmedAppt.push(formatedElement);
    })
    return formatedConfirmedAppt;
  }

  const handleConfirmAppointment = (appointment) => {
    Swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      title: 'Voulez vous confirmer ce rendez-vous?',
      text: "L'action est irreversible",
      confirmButtonText: "Oui, confirmer",
      cancelButtonText: "Non",
      icon: 'question'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const request = await appointmentApi.updateAppointmentStatus(localStorage.getItem('token'), appointment.id, { status: "confirmed" });
        if (request.appointment) {
          sendEmailBookingStatusToClient(
            {
              email: appointment.email,
              first_name: appointment.first_name,
              last_name: appointment.last_name,
            },
            `${profileData.first_name} ${profileData.last_name}`,
            appointment.appointment_date,
            appointment.start_time,
            appointment.end_time,
            'confirmation'
          );
          Swal.fire("Informations", 'Le rendez-vous a été confirmé avec succès!', 'success').then(() => setChangeTrigger(prev => !prev));
        }
      }
    })
  }

  const handleRejectAppointment = (appointment) => {
    Swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      title: 'Voulez vous rejeter ce rendez-vous?',
      text: "L'action est irreversible",
      confirmButtonText: "Oui, rejeter",
      cancelButtonText: "Non",
      icon: "question"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const request = await appointmentApi.updateAppointmentStatus(localStorage.getItem('token'), appointment.id, { status: "cancelled" });
        if (request.appointment) {
          sendEmailBookingStatusToClient(
            {
              email: appointment.email,
              first_name: appointment.first_name,
              last_name: appointment.last_name,
            },
            `${profileData.first_name} ${profileData.last_name}`,
            appointment.appointment_date,
            appointment.start_time,
            appointment.end_time,
            'cancel'
          );
          Swal.fire("Informations", 'Le rendez-vous a été rejeté avec succès!', 'success').then(() => setChangeTrigger(prev => !prev));
        }
      }
    })
  }


  const AppointmentCard = ({ appointment }) => {

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return <div className={`appointment-card ${appointment.status}`}>
      <div className="appointment-time">{activePage === 'booking_pending' && format(appointment.appointment_date, "EEEE d MMMM yyyy", { locale: fr })} à {appointment.start_time.slice(0, 5)}</div>
      <div className="appointment-info">
        <div className="client-name">{appointment.first_name} {appointment.last_name}</div>
        <div className="service-details">
          {appointment.services.map((service, subIndex) => (
            <div key={subIndex}>
              {service.subcategory_name} • {service.subcategory_duration} min
            </div>
          ))}
        </div>
      </div>
      <div className="appointment-status">
        {appointment.status === 'confirmed' ? 'Confirmé' : 'En attente'}
      </div>
      <div className="flex gap-2">
        <button className='py-2 px-3 bg-gray-200 hover:bg-gray-300 rounded text-gray-900' onClick={handleShowModal} >
          A propos client
        </button>
        {activePage === "booking_pending" && (<>
          <button onClick={() => handleConfirmAppointment(appointment)} className="appointment-card-btn confirm">
            Confirmer
          </button>
          <button onClick={() => handleRejectAppointment(appointment)} className="appointment-card-btn reject">
            Rejeter
          </button>
        </>)
        }
        {activePage === "my_agenda" &&
          <button className="appointment-card-btn reject">
            Annuler
          </button>
        }
      </div>
      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Informations du client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <strong>Nom :</strong> {appointment?.first_name} {appointment?.last_name}
          </div>
          <div>
            <strong>Email :</strong> {appointment?.email}
          </div>
          <div>
            <strong>Téléphone :</strong> {appointment?.phone}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  };


  const handleNavigationClick = (e, link) => {
    e.preventDefault();
    setActivePage(link);
  }


  return (
    <div className="agenda-container">
      {/* En-tête de l'agenda */}
      <div className="agenda-header">
        <div className='flex flex-wrap items-center'>
          {activePage === 'my_agenda' ?
            <h4>Mon Agenda</h4> :
            <h4>Réservations en attentes</h4>
          }
        </div>

        <div className='flex justify-center items-center'>
          {activePage !== "my_agenda" ?
            <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={(e) => handleNavigationClick(e, 'my_agenda')}><CalendarIcon size={16} /> Mon agenda</button> :
            <button className='flex justify-center gap-2 items-center px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 text-white' onClick={(e) => handleNavigationClick(e, 'booking_pending')}>
              <Clock size={16} /> Réservation en attentes
              <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                {pendingAppointment.length}
              </span>
            </button>

          }
        </div>
      </div>

      {/* Vue de l'agenda */}
      <div className="agenda-view">

        {/* Zone des rendez-vous */}
        <div className="appointments-container">


          <div className="appointments-layer">
            {(pendingAppointment.length === 0 && activePage === "booking_pending") && (
              <div className="empty-state">Aucun rendez-vous en attente.</div>
            )}
            {activePage === "booking_pending" && pendingAppointment
              .map((appointment, index) => (
                <AppointmentCard key={index} appointment={appointment} />
              ))}
          {activePage === "my_agenda" && <CalendarComponent events={confirmedAppointment}/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeauticianAgenda;