import React, { useState, useEffect } from 'react';
import './Loader.css';

const Loader = ({ isLoading }) => {
  const [shouldRender, setShouldRender] = useState(isLoading);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setShouldRender(true);
      setFadeOut(false);
    } else {
      setFadeOut(true);
      const timer = setTimeout(() => {
        setShouldRender(false);
      }, 500); // Correspond à la durée de l'animation fadeOut
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  if (!shouldRender) return null;

  return (
    <div className={`loader-container ${fadeOut ? 'fade-out' : ''}`}>
      <div className="loader">
        <div className="loader-circle"></div>
        <div className="loader-circle loader-inner"></div>
      </div>
    </div>
  );
};

export default Loader;