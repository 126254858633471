import React, { useState, useEffect } from "react";
import appointmentApi from '../../../api/appointement.api';
import { motion } from "motion/react";
import classNames from "classnames";
import Table from "react-bootstrap/Table";

import { formatTime } from "../../../utils/functions";
import { truncateText } from "../../../utils/stringHelper";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import MySpinner from "../../loader/Spinner";
import NoAppointmentMessage from "./NoAppointmentMessage";
import ShowHistoryModal from "./historique/ShowHistoryModal";


const Historique = () => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const ITEMS_PER_PAGE = 10;

    const fetchAppointmentsHistory = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem("userId");
            const result = await appointmentApi.getUserAppointmentHistory({
                userId: userId,
                token: localStorage.getItem('token'),
                page: currentPage,
                limit: ITEMS_PER_PAGE,
            });
            setData(result.appointments);
            setTotalPages(Math.ceil(result.total_count / ITEMS_PER_PAGE));
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous :", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointmentsHistory();
    }, []);

    useEffect(() => {
        fetchAppointmentsHistory();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleItemClick = async (appointment) => {
        setSelectedAppointment(appointment);
    }

    const statusLabels = {
        pending: "Terminé",
        cancelled: "Annulé",
        confirmed: "Terminé", //les dates passe en terminé
        completed: "Terminé",
    };

    const getStatusClass = (status) =>
        classNames("px-3 py-1 w-full h-full rounded border-2 text-sm", {
            "bg-gray-100 text-gray-400 border-gray-300": status === "completed" || status === "confirmed" || status === "pending",
            "bg-red-100 text-red-400 border-red-300": status === "cancelled",
        });

    return <div className="w-full h-full">
        {isLoading ? (
            <MySpinner height="70vh" />
        ) : (
            <div className="min-h-[80vh] flex flex-col justify-between pb-1">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="overflow-y-auto"
                >
                    {data.length > 0 ? (
                        <Table bordered striped hover>
                            <thead>
                                <tr>
                                    <th className="text-center">Esthéticienne</th>
                                    <th className="text-center">Date et heure</th>
                                    <th className="text-center">Services</th>
                                    <th className="text-center">Adresse</th>
                                    <th className="text-center">Statut</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((appointment, index) => {
                                    return (
                                        <tr key={index} className="cursor-pointer"
                                            onClick={() => handleItemClick(appointment)}
                                        >
                                            <td>{appointment.business_name || `${appointment.first_name} ${appointment.last_name}`}</td>
                                            <td>
                                                {format(
                                                    new Date(appointment.appointment_date),
                                                    "EEEE d MMMM yyyy",
                                                    { locale: fr }
                                                )} {formatTime(appointment.start_time)}
                                            </td>
                                            <td>{appointment.services.map((service, index) => (<span key={index}>{service.subcategory_name}, </span>))}</td>
                                            <td>{truncateText(appointment.address, 25)}</td>
                                            <td className="text-center">
                                                <div className={getStatusClass(appointment.status)}>
                                                    {statusLabels[appointment.status]}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAppointmentMessage />
                    )}
                </motion.div>

                {/* Pagination */}
                <div className="flex justify-center items-center gap-2 mt-2">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                        className={`px-3 py-1 border rounded ${currentPage === 1 ? "text-gray-400" : "text-blue-600"
                            }`}
                    >
                        Précédent
                    </button>
                    <span className="text-gray-700">
                        Page {currentPage} sur {totalPages}
                    </span>
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                        className={`px-3 py-1 border rounded ${currentPage === totalPages ? "text-gray-400" : "text-blue-600"
                            }`}
                    >
                        Suivant
                    </button>
                </div>
            </div>
        )}

        {/* Modal */}
        {selectedAppointment && (
            <ShowHistoryModal
                appointment={selectedAppointment}
                onClose={() => setSelectedAppointment(null)}
            />
        )}

    </div>

}


export default Historique;