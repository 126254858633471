// src/api/beautician.api.js
import { API_URL } from "../utils/constants";

const beauticianApi = {
    getAllBeauticians: async (activePage = "all", limit=null) => {
        try {
            let url = `${API_URL}/beauticians`;
            if (activePage!=='all') url += `?type=${encodeURIComponent(activePage)}`
            if (limit) url += activePage !== 'all' ? `&limit=${limit}` : `?limit=${limit}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians:', error);
            throw error;
        }
    },

    getAllAvailableBeauticians: async (activePage = "all", limit=null) => {
        try {
            let url = `${API_URL}/beauticians/available`;
            if (activePage!=='all') url += `?type=${encodeURIComponent(activePage)}`
            if (limit) url += activePage !== 'all' ? `&limit=${limit}` : `?limit=${limit}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching beauticians:', error);
            throw error;
        }
    },

    getBeauticianById: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne: ' + error.message);
        }
    },

    getBeauticianBySlug: async (slug) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/slug/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de l\'esthéticienne: ' + error.message);
        }
    },

    searchBeauticians: async (searchParams) => {
        try {
            const queryString = new URLSearchParams(searchParams).toString();
            const response = await fetch(`${API_URL}/beauticians/search?${queryString}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la recherche: ' + error.message);
        }
    },

    getAllPortfolioPhotos: async (id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération du portfolio: ' + error.message);
        }
    },

    getPortfolioPhotoById: async (id, photoId) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio/${photoId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la photo: ' + error.message);
        }
    },

    // Routes protégées
    createBeautician: async (token, beauticianData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(beauticianData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la création du profil: ' + error.message);
        }
    },

    updateBeautician: async (token, id, updateData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    deleteBeautician: async (token, id) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression du profil: ' + error.message);
        }
    },

    updateAvailability: async (token, id, availabilityData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/availability`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(availabilityData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour de la disponibilité: ' + error.message);
        }
    },

    addPortfolioPhoto: async (token, id, photoData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(photoData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de l\'ajout de la photo: ' + error.message);
        }
    },

    deletePortfolioPhoto: async (token, id, photoId) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/portfolio/${photoId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression de la photo: ' + error.message);
        }
    },

    createBeauticianProfile: async (token, profileData) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/create-profile`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profileData)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    updateBudgetCategorie: async (token, id, dataCatBudget) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${id}/edit-category-budget`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataCatBudget)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du catégorie budget : ' + error.message);
        }
    },

    acceptRegistration: async (token, beautician_id, email, name) => {
        try {
            const response = await fetch(`${API_URL}/beauticians/${beautician_id}/accept-registration`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    is_active: true
                })
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    }
};

export default beauticianApi;