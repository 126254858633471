import { API_URL } from "../utils/constants";


export const photoEtablissementApi = {

    getByBauticianId: async (id) => {
        try {
            const response = await fetch(`${API_URL}/photo-etablissement/get-by-beautician-id/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la récupération des données: ' + error.message);
        }
    },

    savePhotoEtablissement: async (id, token, images) => {
        try {
            const formData = new FormData();
            
            // Ajout de l'ID dans les données
            formData.append('beautician_id', id);
            
            images.forEach((image, index) => {
                formData.append(`files`, image.file);
            });
    
            // Requête fetch avec le FormData
            const response = await fetch(`${API_URL}/photo-etablissement/save`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });
    
            // Traitement de la réponse
            if (!response.ok) {
                throw new Error(`Erreur serveur: ${response.statusText}`);
            }
    
            return await response.json();
        } catch (error) {
            console.error(error);
            throw new Error('Erreur lors de l\'enregistrement des images');
        }
    },

    deleteImageById: async (id, token) => {
        try {
            const response = await fetch(`${API_URL}/photo-etablissement/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression de l\'image: ' + error.message);
        }
    }
}