import Logo from "../../assets/images/logoRouge.png"
import { FaPlus } from "react-icons/fa"
import { useEffect } from "react";
import React, { useState } from "react"
import CreateOperatorModal from "./CreateOperatorModal"
import DashboardUserMenu from "./DashboardUserMenu"
import { REDIRECT_AFTER_LOGOUT } from "../../utils/constants"

const Header = ({ createUser, activePage, setActivePage }) => {
    const [showCreateOperatorModal, setShowCreateOperatorModal] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        type: '',
    });

    const handleCloseCreateOperatorModal = () => setShowCreateOperatorModal(false);
    const handleShowCreateOperatorModal = () => setShowCreateOperatorModal(true);

    const getName = () => {
        let name = '';
        if (isAuthenticated) {
            name = userData.first_name + ' ' + userData.last_name;
        }
        return name;
    }

    const handleLogout = () => {
        localStorage.clear();
        setIsAuthenticated(false);
        setUserData({
            first_name: '',
            last_name: '',
            email: '',
            type: '',
        });
        window.location.href = REDIRECT_AFTER_LOGOUT;
    };



    useEffect(() => {
        // Vérifier l'authentification
        const token = localStorage.getItem('token');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const userType = localStorage.getItem('userType');
        const email = localStorage.getItem('email');

        if (token && firstName && lastName) {
            setIsAuthenticated(true);
            setUserData({ first_name: firstName, last_name: lastName, email: email, type: userType }); // Inclusion du userType
        }

    }, []);

    return <>
        <nav className="relative px-14 flex items-center justify-between shadow">
            <a href="/">
                <img width="100" src={Logo} alt="Logo" />
            </a>

            <ul className="absolute left-[50%] -translate-x-[50%] flex gap-4">
                <li>
                    <span onClick={(e) => { e.preventDefault(); setActivePage("all"); }} className={`cursor-pointer anchor ${['all', 'indépendant', 'établissement'].includes(activePage) ? 'active' : ''}`}>Professionnelles</span>
                </li>
                {userData.type === 'admin' && <>
                    <li>
                        <span onClick={(e) => { e.preventDefault(); setActivePage("operator"); }} className={`cursor-pointer anchor ${['operator', 'admin'].includes(activePage) ? 'active' : ''}`}>Opérateurs</span>
                    </li>
                    <li>
                        <span onClick={(e) => { e.preventDefault(); setActivePage("client"); }} className={`cursor-pointer anchor ${activePage === 'client' ? 'active' : ''}`}>Clients</span>
                    </li>
                </>
                }
            </ul>

            <DashboardUserMenu user_name={getName()} user_email={userData.email} user_type={userData.type} onLogout={handleLogout} />
        </nav>
        <section className="w-full flex items-center justify-between px-4">
            <div className="flex gap-3 flex-wrap items-center">
                <button
                    onClick={() => createUser(true)}
                    className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                >
                    <FaPlus />
                    {activePage === "client" ? "Créer un client" : "Créer un fiche professionnel"}
                </button>

                {userData.type === "admin" && <>
                    <button
                        onClick={handleShowCreateOperatorModal}
                        className="px-4 py-2 text-white bg-[#bd193b] flex items-center gap-2 rounded shadow active:scale-[101%] duration-200"
                    >
                        <FaPlus />
                        Créer un opérateur
                    </button>
                    <CreateOperatorModal show={showCreateOperatorModal} handleClose={handleCloseCreateOperatorModal} />
                </>
                }
            </div>
        </section>
    </>
}

export default Header