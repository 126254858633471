import React, { useState, useContext, useRef, useEffect } from 'react';
import { Bell, LogOut, Menu, UserCircle, ChevronDown } from 'lucide-react';
import './BeauticianPortalHeader.css';
import { REDIRECT_AFTER_LOGOUT } from '../../../utils/constants';
import Swal from 'sweetalert2';
import { AppContext } from '../../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';

const BeauticianPortalHeader = ({
  isSidebarOpen,
  onSidebarToggle
}) => {
  const { connectedUser, setIsLoggedIn } = useContext(AppContext);
  const { isParentLoading, profileData } = useContext(BeauticianPortalContext);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Référence pour le dropdown

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Effet pour gérer le clic à l'extérieur
  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Si le clic est en dehors du dropdown, fermer le dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Ajouter l'écouteur d'événement au document
    document.addEventListener('click', handleOutsideClick);

    // Nettoyer l'écouteur lors du démontage
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Voulez vous vraiment se deconnecter?",
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: "Non",
      confirmButtonText: "Oui"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        setIsLoggedIn(false);
        window.location.href = REDIRECT_AFTER_LOGOUT;
      }
    })
  }

  return (
    <header className="app-header">
      <div className="header-wrapper">
        {/* Left section */}
        <div className="header-left">
          <button className="menu-button" onClick={onSidebarToggle}>
            <Menu size={22} />
          </button>
        </div>

        {/* Right section */}
        <div className="header-right">
          {(!isParentLoading && profileData?.is_active) &&
            <div className="notifications-menu">
              <button
                className="icon-button"
                onClick={() => navigate('/beautician-dashboard/notifications')}
              >
                <Bell size={20} />
                <span className="notification-indicator"></span>
              </button>
            </div>
          }

          <div className="user-menu relative" ref={dropdownRef}>
            <button
              className="user-profile-button"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown();
              }}
            >
              <UserCircle size={28} />
              <span className="user-name">{connectedUser?.first_name} {connectedUser?.last_name}</span>
              <ChevronDown size={16} />
            </button>
            {showDropdown && (
              <div className="absolute mt-2 right-0 w-64 bg-gray-50 shadow-md rounded-lg p-2 z-50">
                <div className="text-sm text-gray-600 p-2">
                  <strong></strong> {connectedUser.email}
                </div>
                <div className="p-2 hover:bg-gray-100">
                  <a
                    href="/"
                    className="text-blue-600 no-underline hover:text-blue-800 text-sm"
                  >
                    Revenir à la page d'accueil
                  </a>
                </div>
                {(!isParentLoading && profileData?.is_active) &&

                  <div className="p-2 hover:bg-gray-100">
                    <a
                      href="/beautician-dashboard/profile"
                      className="text-blue-600 no-underline hover:text-blue-800 text-sm"
                    >
                      Voir mon profile
                    </a>
                  </div>
                }
              </div>
            )}
          </div>

          <button className="logout-button" aria-label="Déconnexion" onClick={handleLogout}>
            <LogOut size={20} />
          </button>
        </div>
      </div>
    </header>
  );
};

export default BeauticianPortalHeader;