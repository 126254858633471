import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import './AddSubcategoryModal.css';

const AddSubCategoryModal = ({ show, handleClose, handleSubmit, categoryName, categoryId }) => {
    const [subCategoryName, setSubCategoryName] = useState("");
    const [error, setError] = useState("");

    const onSubmit = async () => {
        if (!subCategoryName.trim()) {
            setError("Veuillez entrer un nom pour la sous-catégorie.");
            return;
        }

        try {
            await handleSubmit(categoryId, subCategoryName.trim());
            setSubCategoryName(""); // Réinitialise l'état après succès
            setError(""); // Réinitialise les erreurs
            handleClose(); // Ferme le modal
        } catch (e) {
            setError(e.message || "Une erreur s'est produite.");
        }
    };

    return (
        <Modal show={show} onHide={handleClose} centered backdropClassName="custom-backdrop">
            <Modal.Header closeButton>
                <Modal.Title>Ajouter une sous-catégorie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Pour la catégorie : <strong>{categoryName}</strong></p>
                <Form>
                    <Form.Group controlId="subCategoryName">
                        <Form.Label>Nom de la sous-catégorie</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le nom de la sous-catégorie"
                            value={subCategoryName}
                            onChange={(e) => {
                                setSubCategoryName(e.target.value);
                                setError(""); // Efface l'erreur en cas de modification
                            }}
                            isInvalid={!!error}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    Ajouter
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddSubCategoryModal;
