import beauticianApi from "../api/beautician.api";
import emailApi from "../api/email.api";
import { fr } from "date-fns/locale";
import { format } from "date-fns";

const sendEmailNotification = async (beautician_id, appointment_date, start_time, end_time) => {

    // Récupérer les informations de l'esthéticienne
    const beautician = await beauticianApi.getBeauticianById(beautician_id);

    try {
        const message = `
      Un client a pris rendez-vous avec vous le 
      ${format(appointment_date, 'EEEE d MMMM yyyy', { locale: fr })} de ${start_time} à ${end_time}.
      Vous pouvez le confirmer en allant dans votre tableau de bord !
    `;
        await emailApi.sendEmailNotification(
            beautician.data.email,
            'Notification de réservation!',
            `${beautician.data.first_name} ${beautician.data.last_name}`,
            message
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

const sendEmailBookingStatusToClient = async (user, beautician_name, appointment_date, start_time, end_time, status) => {
    try {
        const message = `
      Votre rendez-vous avec ${beautician_name}, le
      ${format(appointment_date, 'EEEE d MMMM yyyy', { locale: fr })} de ${start_time} à ${end_time}
      a été ${status === 'confirmation' ? 'confirmé' : 'rejeté'}!
    `;
        await emailApi.sendEmailNotification(
            user.email,
            `Notification ${status === 'confirmation' ? 'de confirmation' : 'd\'annulation'} de rendez-vous!`,
            `${user.first_name} ${user.last_name}`,
            message
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

const sendEmaiBeauticianCreation = async (beauticianEmail, beauticianPassword) => {
    try {
        await emailApi.sendEmailRegistrationConfirmation(
            beauticianEmail,
            beauticianPassword
        );
    } catch (emailError) {
        console.log(emailError);
    }
}

export { sendEmailNotification, sendEmailBookingStatusToClient, sendEmaiBeauticianCreation };