
const MySpinner = ({height}) => {
    const spinnerStyle = {
        width: '40px',
        height: '40px',
        border: '4px solid #ccc',
        borderTop: '4px solid #870022',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
    };
    
    const spinnerContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: height,
        width: '100%',
    };
    
    return (<div style={spinnerContainerStyle}>
        <div style={spinnerStyle}></div>
    </div>);
}

export default MySpinner;