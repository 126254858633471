import React, { useState, useEffect } from 'react';
import abonnementApi from '../../../../../api/abonnement.api';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../../../../utils/constants';
import { showSuccessMessage } from '../../../../../utils/messageHelper';
import Swal from 'sweetalert2';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const ABONNEMENT_PRICING = 20; // A changer selon le preference de l'admin


const PaymentForm = ({ userId, months, setReloadLocalTrigger, onCloseForm }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handlePayment = async (e) => {
        e.preventDefault();

        Swal.fire({
            icon: 'question',
            title: 'Confirmer le reabonnement?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, confirmer",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                setMessage('');

                try {
                    const token = localStorage.getItem('token');
                    // Obtenez le clientSecret depuis le serveur
                    const data = {
                        userId: userId,
                        months
                    }

                    const { clientSecret } = await abonnementApi.createPaymentIntent(token, data);

                    // Confirmez le paiement
                    const cardElement = elements.getElement(CardElement);

                    const result = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: {
                            card: cardElement,
                        },
                    });

                    if (result.error) {
                        setMessage(result.error.message);
                    } else if (result.paymentIntent.status === 'succeeded') {
                        const abonnementData = {
                            months: months,
                            userId: userId,
                            paymentIntentId: result.paymentIntent.id
                        }
                        await abonnementApi.addOrUpdateAbonnement(token, abonnementData);
                        setMessage('');
                        onCloseForm();
                        setReloadLocalTrigger(prev => !prev);
                        showSuccessMessage("Votre abonnement a été effectué avec succès!");
                    }
                } catch (error) {
                    setMessage(error.message);
                } finally {
                    setLoading(false);
                }
            }
        })
    };

    return (
        <form onSubmit={handlePayment} className="mt-3">
            <label htmlFor="" className="block text-sm font-medium mb-2 text-gray-700">Informations de la carte :</label>
            <CardElement
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
                className="p-3 border rounded"
            />
            <button
                type="submit"
                disabled={!stripe || loading}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
                {loading ? 'Traitement...' : `Payer ${months * ABONNEMENT_PRICING} EURO`}
            </button>
            {message && <p className="mt-4 text-red-500">{message}</p>}
        </form>
    );
};

const AbonnementComponent = ({ userId }) => {

    const [months, setMonths] = useState(1);
    const [abonnement, setAbonnement] = useState(null);
    const [reloadLocalTrigger, setReloadLocalTrigger] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        async function fetchAbonnement() {
            setLoading(true); // Démarrer le spinner
            const token = localStorage.getItem('token');
            try {
                const data = await abonnementApi.getAbonnementByUserId(token, userId);
                setAbonnement(data);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'abonnement:', error);
            } finally {
                setLoading(false); // Arrêter le spinner
            }
        }
        fetchAbonnement();
    }, [userId, reloadLocalTrigger]);


    return (
        <div className="p-4">
            <h5>Etat de votre abonnement</h5>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader border-t-4 border-blue-500 w-8 h-8 rounded-full animate-spin"></div>
                </div>
            ) : abonnement ? (
                <div>
                    <p className="text-green-500">Abonnement actif jusqu'au {new Date(abonnement.end_date).toLocaleDateString()}</p>
                </div>
            ) : (
                <p className="text-red-500">Aucun abonnement actif</p>
            )}
            <hr />
            {!showForm ? (
                <button
                    onClick={() => setShowForm(true)}
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Acheter un abonnement
                </button>
            ) : (
                <div className="mt-4">
                    <h5>Formulaire de réabonnement</h5>
                    <h6>Le prix abonnement pour un mois est de {ABONNEMENT_PRICING} EURO</h6>
                    <Elements stripe={stripePromise}>
                        <div className="py-2">
                            <label htmlFor="months" className="block text-sm mb-2 font-medium text-gray-700">
                                Sélectionner le nombre de mois :
                            </label>
                            <input
                                type="number"
                                id="months"
                                value={months}
                                onChange={(e) => setMonths(parseInt(e.target.value, 10))}
                                className="mt-1 p-2 block w-full rounded-md border border-gray-400"
                                min="1"
                            />
                            <PaymentForm
                                userId={userId}
                                months={months}
                                setReloadLocalTrigger={setReloadLocalTrigger}
                                onCloseForm={() => setShowForm(false)}
                            />
                        </div>
                    </Elements>
                    <button
                        onClick={() => setShowForm(false)}
                        className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                        Annuler
                    </button>
                </div>
            )}
        </div>
    );
};

export default AbonnementComponent;