import { motion } from 'motion/react'

import React, { useEffect, useState } from 'react'
import MySpinner from "../../loader/Spinner";

import {
    Edit,
    Key,
    UserCircle,
    MessageSquare,
    Cog
} from "lucide-react"
import EditModal from './EditModal'
import userApi from '../../../api/user.api';
import ChangePasswordModal from './ChangePasswordModal';
import ChangeEmailModal from './ChangeEmailModal';

const Profile = () => {
    const [user, setUser] = useState({ email: "", first_name: "", last_name: "", phone: "" });
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [openModal, setOpenModal] = useState(false);
    const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
    const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);

    const getUserInfo = async () => {
        setIsLoading(true);
        const user = await userApi.getProfile(localStorage.getItem('token'));
        if (user) {
            setUser(user);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getUserInfo()
    }, []);

    useEffect(() => {
        getUserInfo()
    }, [reloadTrigger]);


    return (<React.Fragment>
        {isLoading ? <MySpinner height={"50vh"} /> :
            <motion.section
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .25 }}
                className="w-full flex items-center justify-center"
            >
                <aside className="p-3 py-5 w-1/2 flex flex-col items-center gap-2 border shadow rounded">
                    <div>
                        <UserCircle size={100} className="mb-2 text-gray-600" />
                    </div>

                    <h2 className="text-gray-600">{`${user.last_name} ${user.first_name}`}</h2>
                    <h3 className="text-xl text-gray-600 font-normal">{user.email}</h3>
                    <h3 className="text-xl text-gray-600 font-normal">{user.phone}</h3>

                    <button
                        onClick={() => setOpenModal(true)}
                        className="mt-2 flex items-center gap-2 hover:text-blue-600"
                    >
                        <Edit />  Modifier mes informations
                    </button>
                    <button
                        onClick={() => setOpenChangeEmailModal(true)}
                        className="mt-2 flex items-center gap-2 hover:text-blue-600"
                    >
                        <Cog />  Modifier mon adresse email
                    </button>
                    <button
                        onClick={() => setOpenChangePasswordModal(true)}
                        className="mt-2 flex items-center gap-2 hover:text-blue-600"
                    >
                        <Key />  Modifier mon mot de passe
                    </button>
                </aside>

                <EditModal
                    openModal={openModal}
                    user={user}
                    setOpenModal={setOpenModal}
                    setReloadTrigger={setReloadTrigger}
                />

                <ChangePasswordModal
                    openModal={openChangePasswordModal}
                    setOpenModal={setOpenChangePasswordModal}
                />

                <ChangeEmailModal
                    openModal={openChangeEmailModal}
                    setOpenModal={setOpenChangeEmailModal}
                    setReloadTrigger={setReloadTrigger}
                />
            </motion.section>}
    </React.Fragment>
    )
}

export default Profile