import React from 'react';
import { Check, CreditCard, Wallet, Calendar } from 'lucide-react';
import './PaymentOptions.css';

const PaymentOptions = ({ totalAmount, selectedOption, onOptionSelect }) => {
  const depositAmount = (Number(totalAmount) * 0.5).toFixed(2);
  const remainingAmount = (totalAmount - depositAmount).toFixed(2);

  return (
    <div className="payment-container p-3">
      <h3 className="payment-title">Options de paiement</h3>
      
      <div className="payment-options-wrapper">
        {/* Paiement complet */}
        {/*
        <button
          onClick={() => onOptionSelect('full')}
          className={`payment-card ${selectedOption === 'full' ? 'selected' : ''} payment-full`}
        >
          {selectedOption === 'full' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper full-payment-icon">
              <CreditCard />
            </div>
            <div className="payment-details">
              <div className="payment-label">Paiement</div>
              <div className="payment-amount">{totalAmount}€</div>
            </div>
          </div>
        </button>

        */}

        {/* Acompte */}

        {/*
        <button
          onClick={() => onOptionSelect('deposit')}
          className={`payment-card ${selectedOption === 'deposit' ? 'selected' : ''} payment-deposit`}
        >
          {selectedOption === 'deposit' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper deposit-payment-icon">
              <Wallet />
            </div>
            <div className="payment-details">
              <div className="payment-label">Acompte</div>
              <div className="payment-amount">{depositAmount}€</div>
              <div className="payment-subtitle">Reste: {remainingAmount}€</div>
            </div>
          </div>
        </button>

        */}

        {/* Payer au rendez-vous */}
        <button
          onClick={() => onOptionSelect('onsite')}
          className={`payment-card ${selectedOption === 'onsite' ? 'selected' : ''} payment-onsite`}
        >
          {selectedOption === 'onsite' && (
            <div className="check-icon">
              <Check />
            </div>
          )}
          <div className="payment-content">
            <div className="icon-wrapper onsite-payment-icon">
              <Calendar />
            </div>
            <div className="payment-details">
              <div className="payment-label">Sur place</div>
              <div className="payment-amount">{totalAmount}€</div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaymentOptions;