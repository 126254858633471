import { Table } from "react-bootstrap"
import React, { useEffect, useState, useContext } from "react"
import { FaPlus, FaX } from 'react-icons/fa6';


import CommentData from "./CommentData"

import reviewApi from "../../api/review.api"
import ReviewsPanel from "./ReviewsPanel";

import { dashboardContext } from '../../pages/dashboard/Dashboard';

const Comments = () => {
    const [datas, setDatas] = useState([])
    const { activeID, setShowBeauticianCommentaire} = useContext(dashboardContext);
    const [commentTrigger, setCommentTrigger] = useState(false);
    const [showReviewsPanel, setShowReviewsPanel] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getAllReviews = async () => {
            const data = await reviewApi.getBeauticianReview(activeID);
            setDatas(data);
        }
        setIsLoading(true);
        getAllReviews();
        setIsLoading(false);
    }, [commentTrigger]);

    return (
        <div className="absolute top-0 left-0 w-screen z-40 h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center">
            <div className="relative w-2/3 h-[95vh] overflow-y-auto p-4 flex flex-col gap-2 bg-white border rounded shadow">
            <button className="absolute top-6 right-6" onClick={() => setShowBeauticianCommentaire(false)}><FaX /></button>
            <h1 className="text-center text-2xl my-4">Liste des commentaires</h1>
            <div className="w-full flex justify-end items-center">
            <button
                    onClick={() => setShowReviewsPanel(true)}
                    className="px-3 py-2 flex gap-2 items-center text-white bg-blue-600 rounded shadow"
                >
                    <FaPlus />Ajouter commentaire
                </button>
            </div>
                <main>
                    <Table striped>
                        <thead>
                            <tr>
                                <th className="text-center"></th>
                                <th className="text-center">ID</th>
                                <th className="text-center">ID de l'esthéticienne</th>
                                <th className="text-center">Commentateur</th>
                                <th className="text-center">Commentaire</th>
                                <th className="text-center">Notes</th>
                                <th className="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && <p>Chargement...</p>}
                            {
                                (!isLoading && datas.length > 0) ? datas.map((data, key) =>
                                    <CommentData key={key} data={data} setCommentTrigger={setCommentTrigger} />
                                ) : <tr><td colSpan="7">Pas de commentaire à afficher</td></tr>
                            }
                        </tbody>
                    </Table>
                </main>
            </div>
            {showReviewsPanel && <ReviewsPanel setCommentTrigger={setCommentTrigger} setShowReviewsPanel={setShowReviewsPanel}/>}
        </div>
    )
}

export default Comments