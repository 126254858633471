import React from 'react';
import BeauticiansMap from '../map/BeauticiansMap';
import './MapColumn.css';

const MapColumn = ({ 
  beauticians, 
  selectedLocation, 
  radius,
  selectedBeautician 
}) => {
  return (
    <div className="map-column">
      <BeauticiansMap 
        beauticians={beauticians}
        selectedLocation={selectedLocation}
        radius={radius}
        selectedBeautician={selectedBeautician}
      />
    </div>
  );
};

export default MapColumn;