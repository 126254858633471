import React, { useState, useEffect } from 'react';
import BeauticianCard from './BeauticianCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import beauticianApi from '../../api/beautician.api';
import './BeauticianCardList.css';

const CarouselSection = ({ items, title }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState(5);

  useEffect(() => {
    const updateCardsPerPage = () => {
      if (window.innerWidth <= 768) {
        setCardsPerPage(4); // 4 cartes pour mobile (2x2)
      } else {
        setCardsPerPage(5); // 5 cartes pour desktop
      }
    };

    // Initial check
    updateCardsPerPage();

    // Add event listener
    window.addEventListener('resize', updateCardsPerPage);

    // Cleanup
    return () => window.removeEventListener('resize', updateCardsPerPage);
  }, []);

  // Reset currentPage when cardsPerPage changes
  useEffect(() => {
    setCurrentPage(0);
  }, [cardsPerPage]);

  const totalPages = Math.ceil(items.length / cardsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : prev));
  };

  const getCurrentCards = () => {
    const start = currentPage * cardsPerPage;
    return items.slice(start, start + cardsPerPage);
  };

  return (
    <div className="beautician-list-container">
      {title && <h2 className="text-2xl font-bold text-center mb-8">{title}</h2>}
      <div className="beauticians-carousel">
        <button 
          className="carousel-button prev" 
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          aria-label="Précédent"
        >
          <ChevronLeft size={24} />
        </button>

        <div className="beautician-grid">
          {getCurrentCards().map((item) => (
            <div key={item.id} className="beautician-grid-item">
              <BeauticianCard beautician={item} />
            </div>
          ))}
        </div>

        <button 
          className="carousel-button next" 
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          aria-label="Suivant"
        >
          <ChevronRight size={24} />
        </button>

        <div className="carousel-dots">
          {Array(totalPages).fill(0).map((_, index) => (
            <button
              key={index}
              className={`carousel-dot ${currentPage === index ? 'active' : ''}`}
              onClick={() => setCurrentPage(index)}
              aria-label={`Page ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const BeauticianCardList = () => {
  const [independants, setIndependants] = useState([]);
  //const [etablissements, setEtablissements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBeauticians = async () => {
      try {
        setLoading(true);
        const response = await beauticianApi.getAllAvailableBeauticians('all', 15);
        if (response.success && response.data) {
          setIndependants(response.data);
        } else {
          setError('Erreur lors du chargement des Professionnelles de Beauté indépendants');
        }
        /*const etabs = await beauticianApi.getAllBeauticians('établissement', 10);
        if (etabs.success && etabs.data) {
          setEtablissements(etabs.data);
        } else {
          setError('Erreur lors du chargement des établissements de Professionnelles de Beauté');
        }*/
      } catch (err) {
        setError('Erreur lors du chargement des Professionnelles de Beauté');
        console.error('Erreur:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBeauticians();
  }, []);

  if (loading) {
    return (
      <div className="beautician-list-container">
        <div className="beautician-grid" style={{ placeItems: 'center' }}>
          <div>Chargement...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="beautician-list-container">
        <div className="beautician-grid" style={{ placeItems: 'center' }}>
          <div>{error}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-16">
      {independants.length > 0 ? (
        <CarouselSection items={independants} />
      ) : (
        <div className="beautician-list-container">
          <div className="beautician-grid" style={{ placeItems: 'center' }}>
            <div>Aucune Professionnelle de Beauté disponible pour le moment.</div>
          </div>
        </div>
      )}
      {/*etablissements.length > 0 ? (
        <CarouselSection items={etablissements} title="Nos Établissements Partenaires" />
      ) : (
        <div className="beautician-list-container">
          <div className="beautician-grid" style={{ placeItems: 'center' }}>
            <div>Aucun Établissement Partenaire disponible pour le moment.</div>
          </div>
        </div>
      )*/}
    </div>
  );
};

export default BeauticianCardList;