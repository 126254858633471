import { FaTrash } from "react-icons/fa6"

import reviewApi from "../../api/review.api"
import Swal from "sweetalert2"
import { truncateText } from "../../utils/stringHelper"

const CommentData = ({ data, setCommentTrigger }) => {
    const token = localStorage.getItem("token")

    const deleteComment = async() => {
        Swal.fire({
            text: 'Voulez vous vraiment supprimer ce commentaire',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then(async (result) => {
            if(result.isConfirmed) {
                const response = await reviewApi.deleteReview(token, data["id"])
                if(response) {
                    setCommentTrigger((prev) => !prev);
                    Swal.fire("Succès", "Le commentire a été supprimé avec succès!", 'success');
                } else {
                    Swal.fire("Erreur", "Une erreur s'est produite", 'error');
                }
            }
        });
    }

    return <>
        <tr>
            <td className="text-center"><input type="checkbox" className="user-selection" id={data["id"]} /></td>
            <td className="text-center">{data["id"]}</td>
            <td className="text-center">{data["beautician_id"]}</td>
            <td className="text-center">{data["commentateur"]}</td>
            <td className="text-center">{truncateText(data["comment"], 50)}</td>
            <td className="text-center">{data["rating"]}</td>
            <td className="text-center">
                <button className="w-full flex items-center gap-2" onClick={() => deleteComment()}><FaTrash />Supprimer</button>
            </td>
        </tr>
    </>
}

export default CommentData