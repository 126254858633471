// payment.api.js
import { API_URL } from "../utils/constants";

const paymentApi = {
    createPaymentIntent: async (token, paymentData) => {
        try {
            const response = await fetch(`${API_URL}/payment/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(paymentData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans createPaymentIntent:', error);
            throw error;
        }
    },

    saveAppointment: async (token, appointmentData) => {
        try {
            const response = await fetch(`${API_URL}/payment/save-appointment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(appointmentData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la sauvegarde du rendez-vous');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans saveAppointment:', error);
            throw error;
        }
    }
}

export default paymentApi;