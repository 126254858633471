// FiltersColumn.js
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MapPin, X } from 'lucide-react';
import { searchLocations } from '../../data/searchUtils';
import Filters from '../../data/Filters';
import './FiltersColumn.css';

const FiltersColumn = ({
    searchQuery = '',
    setSearchQuery,
    selectedServices = [],
    setSelectedServices,
    selectedPrices = [],
    setSelectedPrices,
    minimumRating = 0,
    setMinimumRating,
    radius = 5000,
    setRadius,
    onLocationChange,
    serviceCategories = [],
    priceRanges = [],
    beauticians = [] // Ajout de la prop beauticians
}) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const wrapperRef = useRef(null);
    const [searchParams] = useSearchParams();

    // Gestion de la recherche
    const handleSearch = async (value) => {
        setSearchQuery(value);
        
        if (value.length < 2) {
            setSuggestions([]);
            setShowSuggestions(false);
            return;
        }

        try {
            setIsLoading(true);
            const results = await searchLocations(value, beauticians);
            setSuggestions(results);
            setShowSuggestions(true);
        } catch (error) {
            console.error('Erreur lors de la recherche:', error);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    };
    // Fonction pour effacer la recherche
    const handleClearSearch = () => {
        setSearchQuery('');
        setSuggestions([]);
        setShowSuggestions(false);
        onLocationChange(null);
    };

    

    // Fermeture des suggestions au clic extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Sélection d'une adresse
    const handleAddressSelect = (address) => {
        setSearchQuery(address.text);
        onLocationChange({
            lat: address.coordinates[0],
            lng: address.coordinates[1]
        });
        setShowSuggestions(false);
    };

    useEffect(() => {
        const serviceParam = searchParams.get('service');
        if (serviceParam && serviceCategories.length > 0) {
            // Chercher la catégorie correspondante (insensible à la casse)
            const matchingCategory = serviceCategories.find(
                category => category.name.toLowerCase() === serviceParam.toLowerCase()
            );

            if (matchingCategory && !selectedServices.includes(matchingCategory.id)) {
                // Ajouter à la sélection si pas déjà sélectionné
                setSelectedServices([...selectedServices, matchingCategory.id]);
            }
        }
    }, [searchParams, serviceCategories]); 

    return (
        <div className="filters-column">
            <div className="search-box-container" ref={wrapperRef}>
                <div className="search-box">
                    <MapPin size={20} className={isLoading ? 'animate-spin' : ''} />
                    <input
                        type="text"
                        placeholder="Entrez une adresse..."
                        value={searchQuery}
                        onChange={(e) => handleSearch(e.target.value)}
                        onFocus={() => searchQuery.length >= 2 && setShowSuggestions(true)}
                    />
                    {searchQuery && (
                        <button 
                            type="button"
                            className="clear-button"
                            onClick={handleClearSearch}
                            aria-label="Effacer la recherche"
                        >
                            <X size={16} />
                        </button>
                    )}
                </div>

                {showSuggestions && (
                    <div className="suggestions-dropdown">
                        {isLoading ? (
                            <div className="suggestion-loading">
                                <div className="loading-spinner"></div>
                                <span>Recherche en cours...</span>
                            </div>
                        ) : suggestions.length > 0 ? (
                            suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() => handleAddressSelect(suggestion)}
                                >
                                    <div className="suggestion-content">
                                        <span className="suggestion-text">{suggestion.text}</span>
                                        {suggestion.subtext && (
                                            <span className="suggestion-subtext">
                                                {suggestion.subtext}
                                            </span>
                                        )}
                                    </div>
                                    <span className={`suggestion-type ${suggestion.type}`}>
                                        {suggestion.type}
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="no-results">Aucun résultat trouvé</div>
                        )}
                    </div>
                )}
            </div>

            <div className="filters-scroll">
                <Filters
                    selectedServices={selectedServices}
                    onServicesChange={setSelectedServices}
                    selectedPrices={selectedPrices}
                    onPricesChange={setSelectedPrices}
                    minimumRating={minimumRating}
                    onRatingChange={setMinimumRating}
                    radius={radius}
                    onRadiusChange={setRadius}
                    serviceCategories={serviceCategories}
                    priceRanges={priceRanges}
                />
            </div>
        </div>
    );
};

export default FiltersColumn;