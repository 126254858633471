import { FaCalendarXmark } from "react-icons/fa6";

// Composant pour afficher un message lorsqu'il n'y a aucun rendez-vous
const NoAppointmentMessage = () => (
    <div className="-mt-6 w-full h-full flex flex-col items-center justify-center gap-4">
        <FaCalendarXmark className="text-8xl text-gray-400" />
        <h3 className="text-xl text-gray-500">Aucun rendez-vous à afficher.</h3>
    </div>
);

export default NoAppointmentMessage;