import React, { useState, useRef, useEffect } from 'react';
import { Star, Share2, Link2, Copy, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import defaultProfile from '../../assets/images/default-profile.png';
import './BeauticianCard.css';
import { FaLocationDot, FaLocationPin } from 'react-icons/fa6';
import { BASE_URL } from '../../utils/constants';
import { truncateText } from '../../utils/stringHelper';

const BeauticianCard = ({ beautician }) => {
  const navigate = useNavigate();
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const sharePopupRef = useRef(null);

  const fullName = truncateText(beautician.business_name || `${beautician?.first_name || ''} ${beautician?.last_name || ''}`.trim() || beautician.name, 16);
  const averageRating = parseFloat(beautician?.average_rating) || beautician.rating || 0;
  const reviewsCount = parseInt(beautician?.comment_count) || 0;
  const city = beautician.postal_code;

  const getImageUrl = (profilePhoto) => {
    if (!profilePhoto) return defaultProfile;
    try {
      return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
    } catch (error) {
      console.error('Error with image path:', error);
      return defaultProfile;
    }
  };

  const renderStars = (rating) => {
    if (!rating || isNaN(rating)) return null;
    
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 === 0.5;
    const totalStars = 5;

    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <Star 
          key={`full-${i}`}
          className="star filled"
          size={12}
          fill="#bd193b"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    if (hasHalfStar) {
      stars.push(
        <div key="half" className="half-star-container">
          <Star 
            className="star half-filled"
            size={12}
            fill="#bd193b"
            stroke="#bd193b"
            strokeWidth={2}
          />
          <Star 
            className="star empty-half"
            size={12}
            fill="none"
            stroke="#bd193b"
            strokeWidth={2}
          />
        </div>
      );
    }

    const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(
        <Star 
          key={`empty-${i}`}
          className="star"
          size={12}
          fill="none"
          stroke="#bd193b"
          strokeWidth={2}
        />
      );
    }

    return stars;
  };

  // Gestion du clic en dehors du popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sharePopupRef.current && !sharePopupRef.current.contains(event.target)) {
        setShowSharePopup(false);
      }
    };

    if (showSharePopup) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSharePopup]);

  const handleCardClick = (e) => {
    if (e.target.closest('.card-actions') || e.target.closest('.share-popup')) {
      return;
    }
    if (beautician?.slug) {
      navigate(`/beauticians/${beautician.slug}`);
    }
  };

  const handleShare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSharePopup(prev => !prev);
  };

  const handleShareTo = async (action, e) => {
    e.preventDefault();
    e.stopPropagation();
    const profileUrl = `${window.location.origin}/beauticians/${beautician.slug}`;

    try {
      switch (action) {
        case 'whatsapp':
          window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(profileUrl)}`, '_blank');
          break;
        case 'copy':
          await navigator.clipboard.writeText(profileUrl);
          setShowCopiedMessage(true);
          setTimeout(() => setShowCopiedMessage(false), 2000);
          break;
        case 'share':
          if (navigator.share) {
            await navigator.share({
              title: `Profil sur PlaniBeauty`,
              url: profileUrl,
            });
          }
          break;
      }
    } catch (err) {
      if (err.name !== 'AbortError') {
        console.error('Erreur lors du partage:', err);
      }
    }
    setShowSharePopup(false);
  };

  if (!beautician) return null;

  return (
    <div 
      className="beautician-card" 
      onClick={handleCardClick} 
      role="button" 
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCardClick(e);
        }
        
      }}
      aria-label={`Profil de ${fullName}`}
    >
      <div className="card-image-container">
        <img 
          src={getImageUrl(beautician.profile_photo || beautician.image)}
          alt={fullName}
          className="card-image"
          loading="lazy"
          onError={(e) => {
            if (e.target.src !== defaultProfile) {
              e.target.src = defaultProfile;
            }
          }}
        />
      </div>
      
      <div className="card-content">
        <div className="card-header">
          <div className="name-location">
            <h3 className="card-name">{fullName || 'Sans nom'}</h3>
            <span className="card-city"><FaLocationDot /> {city}</span>
          </div>
        </div>
        
        <div className="card-rating-row">
          <div className="card-rating" aria-label={`Note: ${averageRating} sur 5`}>
            {renderStars(averageRating)}
          </div>
          <div className="card-reviews">
            <span className="reviews-count">{reviewsCount}</span>
            <span className="reviews-text">avis</span>
          </div>
        </div>

        <div className="card-actions">
  <button 
    className="share-button"
    onClick={handleShare}
    aria-label="Partager le profil"
    type="button"
  >
    <Share2 size={16} />
  </button>
  <button 
    className="book-button"
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (beautician?.id) {
        navigate(`/beauticians/${beautician.slug}#prestation`);
      }
    }}
    type="button"
  >
    Prendre RDV
  </button>

  {showSharePopup && (
    <div 
      ref={sharePopupRef}
      className="share-popup" 
      onClick={e => e.stopPropagation()}
    >
      <button 
        className="close-popup" 
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowSharePopup(false);
        }}
        type="button"
      >
        <X size={16} />
      </button>
      <div className="share-options">
        <button 
          onClick={(e) => handleShareTo('whatsapp', e)}
          className="whatsapp-share"
          aria-label="Partager sur WhatsApp"
          type="button"
        >
          <Link2 size={20} />
          <span>WhatsApp</span>
        </button>
        <button 
          onClick={(e) => handleShareTo('copy', e)}
          className="copy-link"
          aria-label="Copier le lien"
          type="button"
        >
          <Copy size={20} />
          <span>Copier le lien</span>
        </button>
        {navigator.share && (
          <button 
            onClick={(e) => handleShareTo('share', e)}
            className="native-share"
            aria-label="Partager"
            type="button"
          >
            <Share2 size={20} />
            <span>Partager</span>
          </button>
        )}
      </div>
      {showCopiedMessage && (
        <div className="copied-message">Lien copié !</div>
      )}
    </div>
  )}
</div>
    </div>
    </div>
  );
};

export default BeauticianCard;