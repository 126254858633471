import { API_URL } from "../utils/constants";

const blockedDatesApi = {

    getBlockedDates : async (beautician_id) => {
        try {
            let url = `${API_URL}/blocked-dates/${beautician_id}`;
            
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: process.env.NODE_ENV === 'development' ? 'include' : 'same-origin'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching blocked dates:', error);
            throw error;
        }
    },

    updateBlockedDate: async (blocked_date_id, update_data, token) => {
        try {
            const response = await fetch(`${API_URL}/blocked-dates/${blocked_date_id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(update_data)
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    },

    createBlockedDate: async (beautician_id, create_data, token) => {
        try {
            let blocked_date = {...create_data, beautician_id: beautician_id};

            const response = await fetch(`${API_URL}/blocked-dates`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(blocked_date)
            });

            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la création : ' + error.message);
        }
    },

    deleteBlockedDate: async (blocked_date_id, token) => {
        try {
            const response = await fetch(`${API_URL}/blocked-dates/${blocked_date_id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            
            const data = await response.json();
            if (!response.ok) throw new Error(data.message);
            return data;
        } catch (error) {
            throw new Error('Erreur lors de la suppression: ' + error.message);
        }
    }
}

export default blockedDatesApi;