import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import userApi from '../../api/user.api';
import { showErrorMessage, showSuccessMessage } from '../../utils/messageHelper';

const PasswordEditComponent = ({actionAfterSuccess}) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Validation states
    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    // Validation functions
    const validateCurrentPassword = () => {
        if (!currentPassword) {
            return 'Le mot de passe actuel est requis';
        }
        return '';
    };

    const validateNewPassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;

        if (!newPassword) {
            return 'Le nouveau mot de passe est obligatoire';
        }
        if (newPassword.length < 8) {
            return 'Le mot de passe doit contenir au moins 8 caractères';
        }
        if (!passwordRegex.test(newPassword)) {
            return 'Le mot de passe doit contenir des lettres majuscules, minuscules, un chiffre et un caractère spécial';
        }
        return '';
    };

    const validateConfirmPassword = () => {
        if (!confirmPassword) {
            return 'Veuillez confirmer votre nouveau mot de passe';
        }
        if (newPassword !== confirmPassword) {
            return 'Les mots de passe ne correspondent pas';
        }
        return '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate all fields
        const currentPasswordError = validateCurrentPassword();
        const newPasswordError = validateNewPassword();
        const confirmPasswordError = validateConfirmPassword();

        // Update errors
        setErrors({
            currentPassword: currentPasswordError,
            newPassword: newPasswordError,
            confirmPassword: confirmPasswordError
        });

        // Check if there are any errors
        if (currentPasswordError || newPasswordError || confirmPasswordError) {
            return;
        }

        // If no errors, proceed with password change
        try {
            // Here you would typically call an API to change the password

            const res = await userApi.changePassword(localStorage.getItem('token'), { currentPassword: currentPassword, newPassword: newPassword });
            if (res.success) {
                showSuccessMessage("Votre mot de passe a été modifié avec succès!");
                // Reset form after successful submission
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setErrors({
                    currentPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                });

                if (actionAfterSuccess) {
                    actionAfterSuccess();
                }
            } else {
                showErrorMessage(res.message);
            }
        } catch (error) {
            console.error('Password change failed', error);
        }
    };

    // Helper function to render password input with toggle
    const renderPasswordInput = (
        value,
        setValue,
        placeholder,
        showPassword,
        toggleShowPassword,
        error
    ) => (
        <div className="relative">
            <input
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={placeholder}
                className={`w-full p-2 border rounded ${error ? 'border-red-500' : 'border-gray-300'
                    }`}
            />
            <button
                type="button"
                className="absolute right-2 top-3 text-gray-500"
                onClick={toggleShowPassword}
            >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
            {error && (
                <p className="text-red-500 text-sm mt-1">{error}</p>
            )}
        </div>
    );

    return (
        <div className="max-w-md mx-auto p-6">
            <h2 className="text-2xl font-bold mb-6 text-center">Changer le mot de passe</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-2 font-medium">Mot de passe actuel</label>
                    {renderPasswordInput(
                        currentPassword,
                        setCurrentPassword,
                        'Entrer le mot de passe actuel',
                        showCurrentPassword,
                        () => setShowCurrentPassword(!showCurrentPassword),
                        errors.currentPassword
                    )}
                </div>

                <div>
                    <label className="block mb-2 font-medium">Nouveau mot de passe</label>
                    {renderPasswordInput(
                        newPassword,
                        setNewPassword,
                        'Entrer le nouveau mot de passe',
                        showNewPassword,
                        () => setShowNewPassword(!showNewPassword),
                        errors.newPassword
                    )}
                    <p className="text-xs text-gray-500 mt-1">
                        Le mot de passe doit comporter au moins 8 caractères et inclure
                        des majuscules, des minuscules, des chiffres et des caractères spéciaux
                    </p>
                </div>

                <div>
                    <label className="block mb-2 font-medium">Confirmer le nouveau mot de passe</label>
                    {renderPasswordInput(
                        confirmPassword,
                        setConfirmPassword,
                        'Confirmer le mot de passe',
                        showConfirmPassword,
                        () => setShowConfirmPassword(!showConfirmPassword),
                        errors.confirmPassword
                    )}
                </div>

                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-300"
                >
                    Changer le mot de passe
                </button>
            </form>
        </div>
    );
};

export default PasswordEditComponent;