import React, {useContext} from "react";
import { Accordion } from "react-bootstrap";
import OpenTime from "./siteInfoAgenda/OpenTime";
import BlockedDay from "./siteInfoAgenda/BlockedDay";

import { dashboardContext } from '../../pages/dashboard/Dashboard';

const SiteInfoAgenda = () => {

    const { activeID } = useContext(dashboardContext);

    return (
        <React.Fragment>
            <Accordion.Header>Information sur mon agenda</Accordion.Header>
            <Accordion.Body>
                <OpenTime activeID={activeID}/>
                <BlockedDay activeID={activeID}/>
            </Accordion.Body>
        </React.Fragment>
    );
};

export default SiteInfoAgenda;
