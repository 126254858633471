import React, { useState } from 'react';
import userApi from '../../api/user.api';
import {showSuccessMessage} from '../../utils/messageHelper';

const ChangeEmailComponent = ({actionAfterSuccess}) => {
    const [newEmail, setNewEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Validations côté frontend
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        // Validation des champs
        if (!validateEmail(newEmail)) {
            setError('S\'il vous plaît, mettez une adresse email valide.');
            return;
        }

        try {
            const token = localStorage.getItem('token');

            const data = await userApi.changeEmail(token, { newEmail, password });

            if (data.success) {
                showSuccessMessage('Votre email a été bien modifié!');
                setMessage('');
                setNewEmail('');
                setPassword('');
                if(actionAfterSuccess) {
                    actionAfterSuccess();
                }
            } else {
                setMessage(data.message);
            }

        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold text-center mb-6">Changer l'adresse email</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Nouveau email</label>
                    <input
                        type="email"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                        className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Entrer le nouveau adresse email"
                        required
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Mot de passe</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Entrer votre mot de passe pour valider"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
                >
                    Confirmer le changement
                </button>
            </form>
            {message && (
                <p className="mt-4 text-green-600 text-center font-medium">{message}</p>
            )}
            {error && (
                <p className="mt-4 text-red-600 text-center font-medium">{error}</p>
            )}
        </div>
    );
};

export default ChangeEmailComponent;
