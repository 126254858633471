import React, { useContext, useState } from 'react'

import Form from 'react-bootstrap/Form';

import { FaX, FaCamera } from "react-icons/fa6"
import { dashboardContext } from '../../pages/dashboard/Dashboard'
import { FaSave } from 'react-icons/fa';
import Swal from 'sweetalert2';
import userApi from '../../api/user.api';
import beauticianApi from '../../api/beautician.api';
import { API_URL } from '../../utils/constants';
import { deleteProfileImage, getProfileImageUrl } from '../../utils/functions';

const EditModal = () => {
    const token = localStorage.getItem("token")
    const { activeID, userData, showEditModal, setReloadTrigger, activePage } = useContext(dashboardContext)
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [data, setData] = useState(userData);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return '';
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                setUploadStatus(`Erreur: ${error.error}`);
                return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            Swal.fire('Erreur', "Erreur lors de l'upload.", 'error');
            return '';
        }
    };

    const validateFields = (data) => {
        if (!data['last_name'] || !data['first_name'] || !data['phone'] || !data['email'] || (!data['address'] && !(activePage === "client" || activePage === "operator"))) {
            Swal.fire('Erreur', 'Veuillez remplir les champs : nom, prénom(s), email, numero' + (data['address'] ? ', adresse!' : '!'), 'error');
            return false;
        }


        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(data['email'])) {
            Swal.fire('Erreur', "L'adresse e-mail n'est pas valide.", 'error');
            return false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(data['phone'])) {
            Swal.fire('Erreur', 'Le numéro de téléphone n\'est pas valide!', 'error');
            return false;
        }

        return true;
    };

    const handleEdit = async () => {
        if (!validateFields(data)) return;

        if (activePage === "client" || activePage === "operator") {
            // Handle client update from there
            const userInfo = {
                phone: data["phone"],
                user_id: activeID,
                first_name: data["first_name"],
                last_name: data["last_name"],
                email: data['email'],
            }

            Swal.fire({
                title: 'Chargement...',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {

                const update_profile = await userApi.updateProfile(token, userInfo);

                if (update_profile.success) {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire("Succès", 'Modification reuissi!', 'success');
                    }, 200);
                    showEditModal(false);
                    setReloadTrigger((prev) => !prev);
                } else {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', update_profile.message || 'Erreur lors de la modification du profil.', 'error');
                    }, 200);
                }
            } catch (error) {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
                }, 200);
            }
        }
        else {
            const userInfo = {
                phone: data["phone"],
                user_id: data["user_id"],
                first_name: data["first_name"],
                last_name: data["last_name"],
                email: data["email"],
            }

            const beauticianData = {
                address: data["address"],
                business_name: data['business_name']
            }


            Swal.fire({
                title: 'Chargement...',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {

                const update_profile = await userApi.updateProfile(token, userInfo);

                if (update_profile.success) {
                    try {

                        if (selectedFile) {
                            await deleteProfileImage(data['profile_photo']);
                            const filename = await handleUpload();
                            if (filename !== '') {
                                beauticianData.profile_photo = filename;
                            }
                        }

                        const update_beautician = await beauticianApi.updateBeautician(token, data["id"], beauticianData);
                        if (update_beautician.success) {
                            Swal.close();
                            setTimeout(() => {
                                Swal.fire("Succès", 'Modification reuissi!', 'success');
                            }, 200);
                            showEditModal(false);
                            setReloadTrigger((prev) => !prev);
                        } else {
                            Swal.close();
                            setTimeout(() => {
                                Swal.fire('Erreur', update_beautician.message || 'Erreur lors de la modification du profil.', 'error');
                            }, 200);
                        }
                    } catch (error) {
                        Swal.close();
                        setTimeout(() => {
                            Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
                        }, 200);
                    }
                } else {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', update_profile.message || 'Erreur lors de la modification du profil.', 'error');
                    }, 200);
                }
            } catch (error) {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
                }, 200);
            }
        }
    }

    const getFormClass = () => {
        if ((activePage === "client" || activePage === "operator")) {
            return `relative w-1/2 overflow-y-auto p-6 flex flex-col gap-4 bg-white border rounded shadow`;
        }
        return 'relative w-1/2 h-[95vh] overflow-y-auto p-6 flex flex-col gap-4 bg-white border rounded shadow';
    }

    return (
        <div
            className="absolute top-0 z-50 left-0 w-screen h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center"
        >
            <Form
                onSubmit={(e) => e.preventDefault()}
                className={getFormClass()}
            >
                <button
                    className="absolute top-6 right-6"
                    onClick={() => showEditModal(false)}
                >
                    <FaX />
                </button>
                <h1 className="text-center text-2xl text-gray-700">Modifier les informations</h1>
                <div className=''>
                    {(activePage !== "client" && activePage !== "operator") &&
                        <label className="flex flex-col items-center gap-2 cursor-pointer mb-2">
                            {preview ? (
                                <img
                                    src={preview}
                                    alt="Preview"
                                    className="w-20 h-20 object-cover rounded-full border"
                                />
                            ) : <img
                                src={getProfileImageUrl(data['profile_photo'])}
                                alt="Preview"
                                className="w-20 h-20 object-cover rounded-full border"
                            />
                            }
                            <div className='flex gap-2 items-center mb-2'><FaCamera /><span>Modifier la photo de profil</span></div>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                        </label>
                    }

                    <div className="grid grid-cols-2 gap-3">
                        <div>
                            <Form.Label>Nom*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom"
                                value={data["last_name"]}
                                onChange={(e) => setData({ ...data, "last_name": e.target.value })}
                            />
                        </div>
                        <div>
                            <Form.Label>Prénom(s)*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Prénom(s)"
                                value={data["first_name"]} onChange={(e) => setData({ ...data, "first_name": e.target.value })}
                            />
                        </div>
                        {(activePage !== "client" && activePage !== "operator") && <>
                            <div className="col-span-2">
                                <Form.Label>Nom commercial</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nom commercial"
                                    value={data['business_name']}
                                    onChange={(e) => setData({ ...data, "business_name": e.target.value })}
                                />
                            </div></>}
                        {(activePage !== "client" && activePage !== "operator") &&
                            <div className="col-span-2">
                                <Form.Label>Adresse postal*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Adresse postal"
                                    value={data["address"]}
                                    onChange={(e) => setData({ ...data, "address": e.target.value })}
                                />
                            </div>}
                        <div>
                            <Form.Label>E-mail*</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="E-mail"
                                value={data["email"]} onChange={(e) => setData({ ...data, "email": e.target.value })}
                            />
                        </div>
                        <div>
                            <Form.Label>Numéro de téléphone*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Numéro de téléphone"
                                value={data["phone"]}
                                onChange={(e) => setData({ ...data, "phone": e.target.value })}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-end mt-4 gap-3">
                    <button className='px-3 py-2 text-white bg-gray-700 rounded hover:bg-gray-800' onClick={() => showEditModal(false)}>Annuler</button>
                    <button
                        onClick={handleEdit}
                        className="px-3 py-2 flex gap-2 items-center text-white bg-[#bd193b] rounded shadow"
                    >
                        <FaSave /> Enregistrer
                    </button>
                </div>
            </Form>
        </div>
    )
}

export default EditModal