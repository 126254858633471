import React, { useContext } from 'react'

import { FaX } from 'react-icons/fa6';
import { dashboardContext } from '../../pages/dashboard/Dashboard';
import SiteInfoComponent from './SiteInfoComponent';


const SiteInfomation = () => {

    const { showSiteInformation, setReloadTrigger } = useContext(dashboardContext);

    return <>
        <div className="absolute z-40 top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-80 flex items-center justify-center">
            <form onSubmit={(e) => e.preventDefault()} className="relative w-1/2 h-[95vh] p-4 flex flex-col gap-2 bg-white border rounded shadow">
                <button className="absolute top-6 right-6" onClick={() => { setReloadTrigger((prev) => !prev); showSiteInformation(false) }}><FaX /></button>
                <SiteInfoComponent /> 
        </form>
        </div>
    </>
}

export default SiteInfomation