import React, { useEffect, useContext } from 'react'; // Ajout de useContext
import { BiCurrentLocation } from 'react-icons/bi';
import { FaStar, FaMapMarkerAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet';
import Hero from '../../components/hero/Hero';
import BeauticianCardList from '../../components/beautician/BeauticianCardList';
import Reviews from '../../components/beautician/Reviews';
import { ServiceCardList } from '../../components/Services/Services.js';
import { AppContext } from '../../contexts/AppContext'; // Ajout de l'import du contexte
import './Home.css';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const { setActivePageForHeader } = useContext(AppContext); // Utilisation du contexte

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePageForHeader("home");
    document.body.setAttribute('data-page', 'home');

    return () => {
      setActivePageForHeader("");
      document.body.removeAttribute('data-page');
    };
  }, [setActivePageForHeader]);

  return (
    <>
      <Helmet>
        <title>PlaniBeauty - Réservation de prestations beauté en ligne</title>
        <meta name="description" content="Réservez vos prestations beauté en quelques clics. Coiffure, esthétique, massage et plus encore. La beauté à portée de main." />
        <link rel="canonical" href="https://www.planibeauty.fr" />
        <meta property="og:title" content="PlaniBeauty | Réservation beauté en ligne" />
        <meta property="og:description" content="Trouvez et réservez vos prestations beauté près de chez vous. Simple, rapide et pratique." />
      </Helmet>
      <div className="home">
        <Hero />
        
        <section className="beauticians-section mb-5">
          <div className="container">
            <div className="section-header text-center">
              <h2 className="section-title">Découvrez les profils de nos prestataires</h2>
            </div>
            <BeauticianCardList />
          </div>
        </section>

        <section className="services-wrapper">
          <ServiceCardList where="home" />
        </section>

        <section className="reviews-section">
          <Reviews id="all" />
        </section>

        {/* Features Section */}
        <section className="features py-5">
          <div className="container">
            <h2 className="text-center mb-5">Pourquoi choisir Planibeauty ?</h2>
            <div className="row">
              <div className="col-md-4">
                <div className="feature-card text-center">
                  <i className="mb-3">
                    <FaStar  size={24} />
                  </i>
                  <h3>Professionnels qualifiés</h3>
                  <p>Toutes nos professionnels de beautés sont certifiés et expérimentés</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-card text-center">
                  <i className="mb-3">
                    <FaMapMarkerAlt  size={24} />
                  </i>
                  <h3>Proximité</h3>
                  <p>Trouvez une Professionnel de beauté près de chez vous</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-card text-center">
                  <i className="mb-3">
                  <BiCurrentLocation  size={24} />
                  </i>
                  <h3>Réservation simple</h3>
                  <p>Prenez rendez-vous en quelques clics</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;