import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Star, X, ChevronLeft, ChevronRight } from 'lucide-react';
import './Reviews.css';

import reviewApi from '../../api/review.api';
import ratingApi from '../../api/rating.api';

const StarRating = ({ rating }) => {
  return (
    <div className="star-rating">
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          size={16}
          className={`star ${star <= rating ? 'filled' : ''}`}
          fill={star <= rating ? '#bd193b' : 'none'}
          stroke={star <= rating ? '#bd193b' : '#868e96'}
        />
      ))}
    </div>
  );
};

const ReviewCard = ({ review, onClick }) => {
  return (
    <div className="review-card" onClick={onClick}>
      <div className="review-header">
        <StarRating rating={review.rating} />
        <span className="reviewer-name">{review.name}</span>
      </div>
      <p className="review-content">{review.content}</p>
      <span className="review-date">{review.date}</span>
    </div>
  );
};

const Modal = ({ isOpen, onClose, reviews, selectedReviewIndex }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content bg-white">
        <button className="modal-close" onClick={onClose}>
          <X size={24} />
        </button>
        <div className="modal-reviews">
          <h2>Avis clients</h2>
          <div className="modal-reviews-list">
            {reviews.map((review, index) => (
              <div 
                key={index} 
                className={`modal-review ${index === selectedReviewIndex ? 'highlighted' : ''}`}
                id={index === selectedReviewIndex ? 'selected-review' : ''}
              >
                <div className="modal-review-header">
                  <div className="modal-review-info">
                    <StarRating rating={review.rating} />
                    <span className="reviewer-name">{review.name}</span>
                  </div>
                  <span className="review-date">{review.date}</span>
                </div>
                <p className="review-content">{review.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


const Reviews = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [reviews, setReviews] = useState([]);

  // Calculer totalPages une seule fois tant que reviews ne change pas
  const totalPages = useMemo(() => Math.ceil(reviews.length / 3), [reviews]);

  const nextPage = useCallback(() => {
    if (totalPages > 0) {
      setCurrentPage((prev) => (prev + 1) % totalPages);
    }
  }, [totalPages]);

  const prevPage = () => {
    if (totalPages > 0) {
      setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
    }
  };

  const formatDate = (date) => {
    const targetDate = new Date(date);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return targetDate.toLocaleDateString('fr-FR', options);
  };

  // Charger les reviews au premier montage
  useEffect(() => {
    const getReviews = async () => {
      try {
        // Récupère les avis selon l'ID (soit pour tous, soit pour un seul beautician)
        const allReviews = id === "all" ?
          await reviewApi.getAllReview(6) :
          await reviewApi.getBeauticianReview(id);
    
        // Récupérer les average_rating pour chaque commentaire en parallèle
        const formattedReviews = await Promise.all(allReviews.map(async (r) => {
          // Appel API pour obtenir la note moyenne (average_rating) associée à chaque commentaire
          const average_rating = await ratingApi.getRatingByCommentId(r.id);
    
          // Retourner l'avis formaté avec la note moyenne
          return {
            name: r.commentateur,
            rating: average_rating.data.average_rating,  // Remplacer r.rating par average_rating
            content: r.comment,
            date: formatDate(r.created_at),
          };
        }));
    
        // Mettre à jour les avis avec la moyenne des notes
        setReviews(formattedReviews);
      } catch (error) {
        console.error('Erreur lors du chargement des avis:', error);
      }
    };

    getReviews();
  }, [id]);

  // Carrousel automatique avec pause
  useEffect(() => {
    if (!isPaused && totalPages > 0) {
      const interval = setInterval(nextPage, 5000);
      return () => clearInterval(interval);
    }
  }, [isPaused, totalPages, nextPage]);

  const handleReviewClick = (index) => {
    setSelectedReviewIndex(index);
    setIsModalOpen(true);
  };

  const startIndex = currentPage * 3;
  const visibleReviews = reviews.slice(startIndex, startIndex + 3);

  return <>
      { reviews.length > 0 && <div className="reviews-container">
        <h2 className="reviews-title">Avis de nos clients :</h2>
        <div
          className="reviews-carousel"
          onMouseEnter={() => setIsPaused(true)}
          onMouseLeave={() => setIsPaused(false)}
        >
          <button className="carousel-button prev" onClick={prevPage}>
            <ChevronLeft size={24} />
          </button>

          <div className="reviews-grid">
            {visibleReviews.map((review, index) => (
              <ReviewCard
                key={startIndex + index}
                review={review}
                onClick={() => handleReviewClick(startIndex + index)}
              />
            ))}
          </div>

          <button className="carousel-button next" onClick={nextPage}>
            <ChevronRight size={24} />
          </button>

          <div className="carousel-dots">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                className={`carousel-dot ${currentPage === index ? 'active' : ''}`}
                onClick={() => setCurrentPage(index)}
              />
            ))}
          </div>
        </div>

        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          reviews={reviews}
          selectedReviewIndex={selectedReviewIndex}
        />
      </div> }
    </>
};

export default Reviews;