import React from 'react'

import { Modal } from 'react-bootstrap';
import ChangeEmailComponent from '../../common/ChangeEmailComponent';

const ChangeEmailModal = ({ openModal, setOpenModal, setReloadTrigger }) => {

    const closeModal = () => {
        setOpenModal(false);
        setTimeout(() => {
            setReloadTrigger(prev => !prev);
        }, 500);
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setOpenModal(false)
    }

    return <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        className="flex items-center justify-center"
        dialogClassName="flex items-center justify-center"
    >
        <ChangeEmailComponent actionAfterSuccess={closeModal} />
        <Modal.Footer>
            <button
                onClick={handleCancel}
                className="ms-auto px-3 py-2 border-2 rounded"
            >
                Annuler
            </button>
        </Modal.Footer>
    </Modal>
}

export default ChangeEmailModal