import {
  FaFacebook,
  FaYoutube,
  FaTiktok,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaPinterest,
  FaReddit,
  FaTwitch,
  FaDiscord,
  FaWhatsapp,
  FaTelegram,
  FaSnapchat,
  FaMedium,
  FaGithub,
  FaDribbble,
  FaBehance,
  FaVimeo,
  FaSoundcloud,
  FaSpotify
} from 'react-icons/fa6';


const network_type = {
  "facebook": <span style={{ color: "#1877F2", fontSize: "1.35rem" }}>
    <FaFacebook />
  </span>,
  "youtube": <span style={{ color: "#FF0000", fontSize: "1.5rem" }}>
    <FaYoutube />
  </span>,
  "tiktok": <span style={{ color: "#010101", fontSize: "1.3rem" }}>
    <FaTiktok />
  </span>,
  "twitter": <span style={{ color: "#1DA1F2", fontSize: "1.35rem" }}>
    <FaTwitter />
  </span>,
  "instagram": <span style={{ color: "#E4405F", fontSize: "1.4rem" }}>
    <FaInstagram />
  </span>,
  "linkedin": <span style={{ color: "#0A66C2", fontSize: "1.35rem" }}>
    <FaLinkedinIn />
  </span>,
  "pinterest": <span style={{ color: "#E60023", fontSize: "1.35rem" }}>
    <FaPinterest />
  </span>,
  "reddit": <span style={{ color: "#FF4500", fontSize: "1.35rem" }}>
    <FaReddit />
  </span>,
  "twitch": <span style={{ color: "#9146FF", fontSize: "1.35rem" }}>
    <FaTwitch />
  </span>,
  "discord": <span style={{ color: "#5865F2", fontSize: "1.4rem" }}>
    <FaDiscord />
  </span>,
  "whatsapp": <span style={{ color: "#25D366", fontSize: "1.35rem" }}>
    <FaWhatsapp />
  </span>,
  "telegram": <span style={{ color: "#26A5E4", fontSize: "1.35rem" }}>
    <FaTelegram />
  </span>,
  "snapchat": <span style={{ color: "#FFFC00", fontSize: "1.35rem" }}>
    <FaSnapchat />
  </span>,
  "medium": <span style={{ color: "#000000", fontSize: "1.35rem" }}>
    <FaMedium />
  </span>,
  "github": <span style={{ color: "#181717", fontSize: "1.35rem" }}>
    <FaGithub />
  </span>,
  "dribbble": <span style={{ color: "#EA4C89", fontSize: "1.35rem" }}>
    <FaDribbble />
  </span>,
  "behance": <span style={{ color: "#1769FF", fontSize: "1.35rem" }}>
    <FaBehance />
  </span>,
  "vimeo": <span style={{ color: "#1AB7EA", fontSize: "1.35rem" }}>
    <FaVimeo />
  </span>,
  "soundcloud": <span style={{ color: "#FF3300", fontSize: "1.35rem" }}>
    <FaSoundcloud />
  </span>,
  "spotify": <span style={{ color: "#1DB954", fontSize: "1.35rem" }}>
    <FaSpotify />
  </span>
}

const SocialLinkList = ({ links }) => {
  return <div className="flex items-center text-2xl gap-4">
    {Array.isArray(links) && links.map((item, key) => (<a key={key} href={item.network_url} rel="noreferrer" target="_blank">{network_type[item.network_type]}</a>))}
  </div>
}

export default SocialLinkList;