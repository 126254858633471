import React from 'react';
import { Link } from 'react-router-dom';
import { Home, Search } from 'lucide-react';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="container">
        <div className="not-found-content">
          <div className="error-code">404</div>
          <h1>Oups ! Cette page s'est fait belle ailleurs</h1>
          <p>
            Comme nos esthéticiennes, cette page prend soin d'elle... 
            mais elle n'est pas à la bonne adresse !
          </p>
          <div className="illustration">
            {/* Illustration stylisée avec CSS */}
            <div className="mirror">
              <div className="mirror-frame"></div>
              <div className="mirror-reflection"></div>
            </div>
          </div>
          <div className="actions">
            <Link to="/" className="action-button home">
              <Home size={20} />
              Retour à l'accueil
            </Link>
            <Link to="/search" className="action-button search">
              <Search size={20} />
              Rechercher une esthéticienne
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;