import React, { createContext, useContext, useState, useEffect } from "react";
import { showErrorMessage } from "../utils/messageHelper";
import userApi from "../api/user.api";
import { AppContext } from "../contexts/AppContext";
import { beauticiansSubCategoriesApi } from "../api/beauticians_subcategories.api";
import { groupPrestationsByCategoryId } from "../utils/functions";
import serviceCategoriesApi from "../api/service-categories.api";

export const BeauticianPortalContext = createContext();

export const BeauticianPortalProvider = ({ children }) => {

    const { connectedUser } = useContext(AppContext);
    const [profileData, setProfileData] = useState(null);
    const [isParentLoading, setIsParentLoading] = useState(false);
    const [prestations, setPrestations] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [reloadTrigger, setReloadTrigger] = useState(false);
    const [activeAccordionSetting, setActiveAccordionSetting] = useState(0);

    const fetchBeauticianData = async (user_id) => {
        setIsParentLoading(true);
        try {
            const data = await userApi.fetchBeauticianUserByUserId(localStorage.getItem('token'), user_id);
            if (data) {
                setProfileData(data);
                const req_prestations = await beauticiansSubCategoriesApi.getByBauticianId(data.beautician_id, localStorage.getItem('token'));
                const group_by_cat = groupPrestationsByCategoryId(req_prestations);
                setPrestations(group_by_cat || []);
                const all_cat = await serviceCategoriesApi.getAllCategories();
                setAllCategories(all_cat || []);
                setTimeout(() => {
                    setIsParentLoading(false);
                }, 500);
            }
        } catch (error) {
            showErrorMessage("Impossible de charger les données de l'utilisateur!");
            setIsParentLoading(false);
        }
    }

    useEffect(() => {
        if (connectedUser) {
            fetchBeauticianData(connectedUser.id);
        }
    }, [connectedUser]);

    useEffect(() => {
        if (connectedUser) {
            fetchBeauticianData(connectedUser.id);
        }
    }, [reloadTrigger]);


    return <BeauticianPortalContext.Provider
        value={{
            profileData,
            isParentLoading,
            prestations,
            allCategories,
            setReloadTrigger,
            activeAccordionSetting,
            setActiveAccordionSetting
        }}
    >
        {children}
    </BeauticianPortalContext.Provider>
};