import React, { useContext, useState } from 'react'

import { BiStar } from 'react-icons/bi'
import { FaStar, FaPlus, FaX } from 'react-icons/fa6'
import { dashboardContext } from '../../pages/dashboard/Dashboard'

import reviewAPI from '../../api/review.api'
import { FaSpinner } from 'react-icons/fa'
import Swal from 'sweetalert2'
import ratingApi from '../../api/rating.api'

const RenderRate = ({ type, rate, setRate }) => {
    const updateRate = (e) => {
        setRate({
            ...rate,
            [type]: parseInt(e.target.dataset.rate)
        })
    }

    const categories = {
        "Welcome": "Acceuil",
        "Cleanliness": "Propreté",
        "Ambiance": "Cadre & Ambiance",
        "Service Quality": "Qualité de la préstation"
    }

    return <>
        <section className="flex flex-col gap-1">
            <label>{categories[type]}</label>
            <span className="flex items-center gap-2">
                <input type="checkbox" data-rate="1" id={`${type}-rate-1`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="2" id={`${type}-rate-2`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="3" id={`${type}-rate-3`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="4" id={`${type}-rate-4`} onClick={updateRate} hidden />
                <input type="checkbox" data-rate="5" id={`${type}-rate-5`} onClick={updateRate} hidden />

                { rate[type] >= 1 ? <label htmlFor="rate-1" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-1`}><BiStar /></label> }
                { rate[type] >= 2 ? <label htmlFor="rate-2" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-2`}><BiStar /></label> }
                { rate[type] >= 3 ? <label htmlFor="rate-3" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-3`}><BiStar /></label> }
                { rate[type] >= 4 ? <label htmlFor="rate-4" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-4`}><BiStar /></label> }
                { rate[type] >= 5 ? <label htmlFor="rate-5" className="text-[#bd193b]"><FaStar /></label> : <label htmlFor={`${type}-rate-5`}><BiStar /></label> }
            </span>
        </section>
    </>
}

const ReviewsPanel = ({setShowReviewsPanel, setCommentTrigger}) => {
    const {
        showReviewsPanel,
        activeID,
        setError,
        setSuccess
    } = useContext(dashboardContext)

    const [rate, setRate] = useState({
        "Welcome": 0,
        "Cleanliness": 0,
        "Ambiance": 0,
        "Service Quality": 0
    })

    const [commentaire, setCommentaire] = useState("")
    const [commentateur, setCommentateur] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const validate = async() => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem("token");
            const data = {
                beautician_id: activeID,
                commentateur,
                comment: commentaire
            };
    
            console.log('Sending review with data:', data);
            const response = await reviewAPI.createReview(token, data);
    
            if(response.status === 500) {
                Swal.fire("Error", response.message, 'error');
                return;
            }
    
            console.log('Review created:', response.data);
            const comment_id = response.data.review.id;
    
            // Ajouter les ratings un par un pour mieux tracer les erreurs
            try {
                console.log('Adding Welcome rating:', {
                    beautician_id: activeID,
                    category: "Welcome",
                    rating: rate["Welcome"],
                    comment_id
                });
                await ratingApi.addRating(activeID, "Welcome", rate["Welcome"], comment_id);
    
                console.log('Adding Ambiance rating:', {
                    beautician_id: activeID,
                    category: "Ambiance",
                    rating: rate["Ambiance"],
                    comment_id
                });
                await ratingApi.addRating(activeID, "Ambiance", rate["Ambiance"], comment_id);
    
                console.log('Adding Cleanliness rating:', {
                    beautician_id: activeID,
                    category: "Cleanliness",
                    rating: rate["Cleanliness"],
                    comment_id
                });
                await ratingApi.addRating(activeID, "Cleanliness", rate["Cleanliness"], comment_id);
    
                console.log('Adding Service Quality rating:', {
                    beautician_id: activeID,
                    category: "Service Quality",
                    rating: rate["Service Quality"],
                    comment_id
                });
                await ratingApi.addRating(activeID, "Service Quality", rate["Service Quality"], comment_id);
    
                Swal.fire("Succès", 'Commentaire et notes ajoutés avec succès!', 'success');
                setCommentTrigger((prev) => !prev);
                setShowReviewsPanel(false);
            } catch (error) {
                console.error('Error adding ratings:', error);
                Swal.fire("Error", "Erreur lors de l'ajout des notes: " + error.message, 'error');
            }
        } catch (error) {
            console.error('Error in validate function:', error);
            Swal.fire("Error", "Erreur lors de la validation: " + error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="absolute z-10 w-screen h-screen flex items-center justify-center bg-gray-800 bg-opacity-80">
            <form
                onSubmit={(e) => e.preventDefault()}
                className="relative w-2/6 p-4 flex flex-col gap-3 bg-white border rounded shadow"
            >
                <button className="absolute top-6 right-6" onClick={() => setShowReviewsPanel(false)}><FaX/></button>
                
                <h2 className="text-2xl text-center">Ajouter un commentaire</h2>

                <RenderRate type="Welcome" rate={rate} setRate={setRate}/>
                <RenderRate type="Cleanliness" rate={rate} setRate={setRate}/>
                <RenderRate type="Ambiance" rate={rate} setRate={setRate}/>
                <RenderRate type="Service Quality" rate={rate} setRate={setRate}/>

                <input
                    type="text"
                    value={commentateur}
                    onChange={(e) => setCommentateur(e.target.value)}
                    className="p-3 outline-[#bd193b] border rounded shadow" placeholder="Saisir le commentateur"
                />


                <textarea
                    value={commentaire}
                    onChange={(e) => setCommentaire(e.target.value)}
                    placeholder="Ecrire votre commentaire..."
                    className="p-3 outline-[#bd193b] border rounded shadow resize-none"
                ></textarea>

                <div className="flex">
                    { isLoading ?
                    <button
                        disabled
                        onClick={validate}
                        className="px-3 py-2 ms-auto flex items-center gap-2 text-white bg-red-200 rounded shadow"
                    >
                        <FaSpinner className="animate-spin" /> Loading
                    </button> :
                    <button
                        onClick={validate}
                        className="px-3 py-2 ms-auto flex items-center gap-2 text-white bg-[#bd193b] rounded shadow"
                    >
                        <FaPlus /> Ajouter
                    </button> }
                </div>
            </form>
        </div>
    )
}

export default ReviewsPanel