import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { motion } from "motion/react";
import { formatTime } from "../../../utils/functions";
import { truncateText } from "../../../utils/stringHelper";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import MySpinner from "../../loader/Spinner";
import Swal from "sweetalert2";
import classNames from "classnames";
import NoAppointmentMessage from "./NoAppointmentMessage";
import appointmentApi from "../../../api/appointement.api";
import ShowHistoryModal from "./historique/ShowHistoryModal";

const AppointmentList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [reloadTrigger, setReloadTrigger] = useState(false);

    const fetchAppointments = async () => {
        setIsLoading(true);
        try {
            const userId = localStorage.getItem("userId");
            const result = await appointmentApi.listAppointmentsValidByUserIdJoinBeauticianAndServices(localStorage.getItem('token'), userId);
            setData(result);
        } catch (error) {
            console.error("Erreur lors de la récupération des rendez-vous :", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAppointments();
    }, [reloadTrigger]);

    const handleRedirect = (e, beautician_slug) => {
        if (!e.target.classList.contains("action"))
            window.location = window.location.origin + "/beauticians/" + beautician_slug
    }

    const handleCancel = async (appointment_id) => {
        Swal.fire({
            title: "Voulez vous vraiment annuler ce rendez vous?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Oui, annuler",
            cancelButtonText: "Non"
        }).then(async (result) => {
            if (result.isConfirmed) {
                await cancelAppointment(appointment_id);
            }
        });
    }

    const cancelAppointment = async (appointment_id) => {
        try {
            const update = await axios.put(
                `/api/appointment/${appointment_id}/status`,
                {
                    status: "cancelled"
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                }
            );

            setReloadTrigger(prev => !prev);

        }
        catch (error) {
            console.error("Une erreur est survenue lors de l'annulation du rendez-vous: ", error.message)
        }
    }

    const handleItemClick = async (appointment) => {
        setSelectedAppointment(appointment);
    }

    const statusLabels = {
        pending: "En attente",
        cancelled: "Annulé",
        confirmed: "Confirmé",
        completed: "Terminé",
    };

    const getStatusClass = (status) =>
        classNames("px-3 py-1 w-full h-full rounded border-2 text-sm", {
            "bg-gray-100 text-gray-400 border-gray-300": status === "completed",
            "bg-orange-100 text-orange-400 border-orange-300": status === "pending",
            "bg-green-100 text-green-400 border-green-300": status === "confirmed",
            "bg-red-100 text-red-400 border-red-300": status === "cancelled",
        });

    return (
        <div className="w-full">
            {isLoading ? (
                <MySpinner height="70vh" />
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="h-full w-full overflow-y-auto"
                >
                    {data.length > 0 ? (
                        <Table bordered striped hover>
                            <thead>
                                <tr>
                                    <th className="text-center">Esthéticienne</th>
                                    <th className="text-center">Date et heure</th>
                                    <th className="text-center">Prestation</th>
                                    <th className="text-center">Statut</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-auto">
                                {data.map((appointment, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{appointment.business_name || `${appointment.first_name} ${appointment.last_name}`}</td>
                                            <td>
                                                {format(
                                                    new Date(appointment.appointment_date),
                                                    "EEEE d MMMM yyyy",
                                                    { locale: fr }
                                                )} {formatTime(appointment.start_time)}
                                            </td>
                                            <td>{appointment.services.map((service, index) => (<span key={index}>{service.subcategory_name}, </span>))}</td>
                                            <td className="text-center">
                                                <div className={getStatusClass(appointment.status)}>
                                                    {statusLabels[appointment.status]}
                                                </div>
                                            </td>
                                            <td className="action flex justify-start items-center gap-2">
                                                <button onClick={() => handleItemClick(appointment)} className="bg-blue-600 cursor-pointer text-gray-100 text-sm hover:bg-blue-700 px-2 py-1 rounded">Voir détails</button>
                                                <button onClick={(e) => handleRedirect(e, appointment.slug)} className="bg-gray-700 cursor-pointer text-white text-sm hover:bg-gray-800 px-2 py-1 rounded">Voir l'établissement</button>
                                                {appointment.status === "pending" &&
                                                    <button
                                                        onClick={(e) => { e.preventDefault(); handleCancel(appointment.id) }}
                                                        className="bg-red-500 text-white text-sm cursor-pointer hover:bg-red-700 px-2 py-1 rounded"
                                                    >
                                                        Annuler
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : (
                        <NoAppointmentMessage />
                    )}
                </motion.div>
            )}

            {/* Modal */}
            {selectedAppointment && (
                <ShowHistoryModal
                    appointment={selectedAppointment}
                    onClose={() => setSelectedAppointment(null)}
                />
            )}

        </div>
    );
};

export default AppointmentList;
