import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

// Configuration des métadonnées pour chaque route
const getMetaConfig = (path, data = {}) => {
  const baseConfig = {
    '/': {
      title: 'PlaniBeauty - Réservation de prestations beauté en ligne',
      description: 'Trouvez et réservez vos prestations beauté en ligne. Coiffure, esthétique, massage et plus encore.',
      canonical: 'https://www.planibeauty.fr/'
    },
    '/concept': {
      title: 'Notre Concept | PlaniBeauty',
      description: 'Découvrez comment fonctionne PlaniBeauty, votre plateforme de réservation beauté en ligne.',
      canonical: 'https://www.planibeauty.fr/concept'
    },
    '/search': {
      title: 'Rechercher des prestataires beauté | PlaniBeauty',
      description: 'Trouvez les meilleurs prestataires beauté près de chez vous. Filtrez par service et localisation.',
      canonical: 'https://www.planibeauty.fr/search'
    },
    '/contact': {
      title: 'Contactez-nous | PlaniBeauty',
      description: 'Une question ? Notre équipe est à votre écoute pour vous accompagner.',
      canonical: 'https://www.planibeauty.fr/contact'
    },
    '/ajouter-etablissement': {
      title: 'Ajouter votre établissement | PlaniBeauty',
      description: 'Professionnel de la beauté ? Rejoignez PlaniBeauty et développez votre activité.',
      canonical: 'https://www.planibeauty.fr/ajouter-etablissement'
    }
  };

  // Pour les pages dynamiques (profils prestataires)
  if (path.startsWith('/beauticians/')) {
    const slug = path.split('/')[2];
    return {
      title: `${data.businessName || 'Prestataire'} | PlaniBeauty`,
      description: `Découvrez ${data.businessName} sur PlaniBeauty et réservez vos prestations beauté en ligne.`,
      canonical: `https://www.planibeauty.fr/beauticians/${slug}`
    };
  }

  return baseConfig[path] || {
    title: 'PlaniBeauty - Réservation beauté',
    description: 'Réservez vos prestations beauté en ligne',
    canonical: 'https://www.planibeauty.fr'
  };
};

const SEOWrapper = ({ children, pageData }) => {
  const location = useLocation();
  const metaConfig = getMetaConfig(location.pathname, pageData);

  return (
    <>
      <Helmet>
        <title>{metaConfig.title}</title>
        <meta name="description" content={metaConfig.description} />
        <link rel="canonical" href={metaConfig.canonical} />
        
        {/* Open Graph */}
        <meta property="og:title" content={metaConfig.title} />
        <meta property="og:description" content={metaConfig.description} />
        <meta property="og:url" content={metaConfig.canonical} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="PlaniBeauty" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={metaConfig.title} />
        <meta name="twitter:description" content={metaConfig.description} />
        
        {/* Autres métadonnées importantes */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="French" />
        <meta name="revisit-after" content="7 days" />
        <meta name="author" content="PlaniBeauty" />
      </Helmet>
      {children}
    </>
  );
};

export default SEOWrapper;