import React, { useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import './EstablishmentForm.css';
import { X } from 'lucide-react';


import beauticianApi from '../../api/beautician.api';
import userApi from '../../api/user.api';
import { API_URL } from '../../utils/constants';
import defaultProfile from '../../assets/images/default-profile.png';

const CreateEtabModal = ({ selectedType, showCreateEtabModal, setShowCreateEtabModal }) => {
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [nomCommercial, setNomCommercial] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [numero, setNumero] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [confirmMotDePasse, setConfirmMotDePasse] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return '';
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${API_URL}/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                setUploadStatus(`Erreur: ${error.error}`);
                return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            Swal.fire('Erreur', "Erreur lors de l'upload.", 'error');
            return '';
        }
    };

    const deleteImage = async (imageName) => {
        try {
            const response = await fetch(`${API_URL}/delete-image-file`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ image_name: imageName }),
            });

            if (response.success) {
                return await response.json();
            } else {
                const error = await response.json();
                return error.message;
            }
        } catch (error) {
            console.error('Error during deleting file:', error);
            return error;
        }
    };

    const validateFields = () => {
        if (!nom || !prenom || !numero || !email || !adresse || !motDePasse || !confirmMotDePasse) {
            Swal.fire('Erreur', 'Veuillez remplir les champs : nom, prénom(s), email, numero, adresse, et mot de passe!', 'error');
            return false;
        }

        if (!selectedFile) {
            Swal.fire('Erreur', 'Veuillez sélectionner une image.', 'error');
            return false;
        }

        if (motDePasse !== confirmMotDePasse) {
            Swal.fire('Erreur', 'Les mots de passe ne correspondent pas.', 'error');
            return false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            Swal.fire('Erreur', "L'adresse e-mail n'est pas valide.", 'error');
            return false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(numero)) {
            Swal.fire('Erreur', 'Le numéro de téléphone n\'est pas valide!', 'error');
            return false;
        }

        if (motDePasse.length < 6) {
            Swal.fire('Erreur', 'Le mot de passe doit contenir au moins 6 caractères.', 'error');
            return false;
        }

        return true;
    };

    const handleCreateUser = async () => {
        if (!validateFields()) return;

        setIsLoading(true);

        Swal.fire({
            title: 'Chargement...',
            text: 'Veuillez patienter...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {

            const filename = await handleUpload();

            if (filename !== '') {
                const user = {
                    first_name: nom,
                    last_name: prenom,
                    phone: numero,
                    email: email,
                    password: motDePasse,
                    confirmPassword: confirmMotDePasse,
                };

                const token = localStorage.getItem('token');
                try {
                    const user_create = await userApi.createBeauticianUser(token, user);

                    if (user_create.user) {
                        const id = user_create.user.id;

                        try {
                            const create_beautician = await beauticianApi.createBeauticianProfile(token, {
                                user_id: id,
                                address: adresse,
                                description: '',
                                budget_category: 'economic',
                                profile_photo: filename,
                                business_name: nomCommercial,
                                type_beautician: selectedType
                            });

                            if (create_beautician.success) {
                                setShowCreateEtabModal(false);
                                Swal.close();
                                setIsLoading(false);
                                setTimeout(() => {
                                    Swal.fire('Succès', 'Utilisateur créé avec succès.', 'success').then(() => {
                                        window.location.href = "/?status=new-user";
                                    });
                                }, 200);

                            } else {
                                await userApi.deleteAccount(token, id);
                                await deleteImage(filename);
                                Swal.close();
                                setIsLoading(false);
                                setTimeout(() => {
                                    Swal.fire('Erreur', create_beautician.message || 'Erreur lors de la création du profil.', 'error');
                                }, 200);
                            }
                        } catch (error) {
                            await userApi.deleteAccount(token, id);
                            await deleteImage(filename);
                            Swal.close();
                            setIsLoading(false);
                            setTimeout(() => {
                                Swal.fire('Erreur', error.message || 'Erreur lors de la création du profil.', 'error');
                            }, 200);
                        }
                    } else {
                        await deleteImage(filename);
                        Swal.close();
                        setIsLoading(false);
                        setTimeout(() => {
                            Swal.fire('Erreur', user_create.message || 'Erreur lors de la création du profil.', 'error');
                        }, 200);
                    }
                } catch (error) {
                    await deleteImage(filename);
                    Swal.close();
                    setIsLoading(false);
                    setTimeout(() => {
                        Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
                    }, 200);
                }
            }
        } catch (error) {
            Swal.close();
            setIsLoading(false);
            setTimeout(() => {
                Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'utilisateur.', 'error');
            }, 200);
        }
    };

    const handleClose = () => setShowCreateEtabModal(false);

    return (
        <Modal
            show={showCreateEtabModal}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="establishment-modal"
            centered
        >
            <div className="form-wrapper">
                <button className="close-button" onClick={() => setShowCreateEtabModal(false)}>
                    <X size={24} />
                </button>

                <div className="form-scrollable-content">
                    <div className="form-header">
                        <h2>Commencez l'aventure</h2>
                        <h4>{{ établissement: "Je suis un établissement", indépendant: "Je suis indépendant" }[selectedType]}</h4>
                        <p>Remplissez le formulaire ci-dessous pour nous rejoindre</p>
                    </div>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <div className='text-center mb-4'>
                            <label className="flex flex-col items-center gap-2 cursor-pointer">
                                {preview ? (
                                    <img
                                        src={preview}
                                        alt="Preview"
                                        className="w-20 h-20 object-cover rounded-full border"
                                    />
                                ) : (
                                    <img
                                        src={defaultProfile}
                                        alt="Preview"
                                        className="w-20 h-20 object-cover rounded-full border"
                                    />
                                )}
                                <div className='flex gap-2 items-center mb-2'>
                                    <FaCamera />
                                    {preview ? <span>Modifier la photo de profil</span> : <span>Ajouter une photo de profil</span>}
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                            </label>
                        </div>

                        <Row>
                            <Form.Group as={Col} controlId="formNom">
                                <Form.Label>Nom*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nom"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formPrenom">
                                <Form.Label>Prénom(s)*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Prénom(s)"
                                    value={prenom}
                                    onChange={(e) => setPrenom(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="formNomCommercial">
                            <Form.Label>Nom commercial</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nom commercial"
                                value={nomCommercial}
                                onChange={(e) => setNomCommercial(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formAdresse">
                            <Form.Label>Adresse postal*</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Adresse postal"
                                value={adresse}
                                onChange={(e) => setAdresse(e.target.value)}
                            />
                        </Form.Group>

                        <Row>
                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Label>E-mail*</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formNumero">
                                <Form.Label>Numéro de téléphone*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Numéro de téléphone"
                                    value={numero}
                                    onChange={(e) => setNumero(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} controlId="formMotDePasse">
                                <Form.Label>Mot de passe*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Mot de passe"
                                    value={motDePasse}
                                    onChange={(e) => setMotDePasse(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formConfirmMotDePasse">
                                <Form.Label>Vérification mot de passe*</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Vérification mot de passe"
                                    value={confirmMotDePasse}
                                    onChange={(e) => setConfirmMotDePasse(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </Form>
                </div>
                <div className="submit-button-container">
                    <button
                        type="submit"
                        onClick={handleCreateUser}
                        className="submit-button"
                        disabled={isLoading}
                    >
                        Valider votre inscription
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateEtabModal;