import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { formatTime } from "../../../../utils/functions";

// Fonction d'affichage du modal pour l'historique des rendez-vous
const ShowHistoryModal = ({ appointment, onClose }) => {
    return (
        <Modal show={!!appointment} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{appointment?.first_name} {appointment?.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Affichage de la date et de l'heure du rendez-vous */}
                <p className="text-muted">
                    <strong>Date et Heure :</strong> {format(new Date(appointment?.appointment_date), 'EEEE d MMMM yyyy', { locale: fr })}
                </p>

                {/* Affichage des horaires du rendez-vous */}
                <p className="text-muted">
                    <strong>Horaires :</strong> {formatTime(appointment?.start_time)} - {formatTime(appointment?.end_time)}
                </p>

                {/* Affichage du statut du rendez-vous */}
                <p>
                    <strong>Statut :</strong>
                    <span className={`badge ${appointment?.status === 'pending'
                            ? 'bg-warning'
                            : appointment?.status === 'confirmed'
                                ? 'bg-primary'
                                : appointment?.status === 'completed'
                                    ? 'bg-success'
                                    : appointment?.status === 'cancelled'
                                        ? 'bg-danger'
                                        : 'bg-secondary'
                        }`}>
                        {appointment?.status === 'pending'
                            ? 'En attente'
                            : appointment?.status === 'confirmed'
                                ? 'Confirmé'
                                : appointment?.status === 'completed'
                                    ? 'Terminé'
                                    : appointment?.status === 'cancelled'
                                        ? 'Annulé'
                                        : 'Inconnu'}
                    </span>
                </p>


                {/* Affichage des services réservés */}
                <div className="mt-3">
                    <strong>Services réservés :</strong>
                    {appointment?.services && appointment.services.length > 0 ? (
                        appointment.services.map((service, index) => (
                            <div key={index} className="mb-2">
                                <p>
                                    <strong>{service.subcategory_name}</strong> - {service.subcategory_description}
                                </p>
                                <p className="text-muted">
                                    <strong>Prix :</strong> {service.subcategory_price} € |
                                    <strong> Durée :</strong> {service.subcategory_duration} minutes
                                </p>
                            </div>
                        ))
                    ) : (
                        <p>Aucun service réservé.</p>
                    )}
                </div>

                {/* Affichage du paiement */}
                <div className="mt-3">
                    <strong>Montant total :</strong> {appointment?.total_prices} €
                    <br />
                    <strong>Montant payé :</strong> {appointment?.payed} €
                    <br />
                    <strong>Type de paiement :</strong> {appointment?.payment_type === "onsite" ? "Sur place" : "En ligne"}
                </div>

                {/* Affichage de l'adresse du client */}
                <p className="text-muted">
                    <strong>Adresse :</strong> {appointment?.address}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowHistoryModal;
