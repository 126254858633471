import React from 'react';
import './BeauticianCardPopup.css';
import defaultProfile from '../../assets/images/default-profile.png';
import { BASE_URL } from '../../utils/constants';

const BeauticianCardPopup = ({ beautician }) => {
    const formatPrice = (budgetCategory) => {
        switch(budgetCategory) {
            case 'economic': return '€';
            case 'moderate': return '€€';
            case 'premium': return '€€€';
            default: return '€';
        }
    };

    // Construire l'URL de l'image en utilisant le chemin correct
    const getImageUrl = (profilePhoto) => {
        if (!profilePhoto) return defaultProfile;
        return `${BASE_URL}/images/beauticians/${profilePhoto}`;
    };

    return (
        <div className="beautician-popup">
            <div className="popup-header">
                <img 
                    src={getImageUrl(beautician.profile_photo)}
                    alt={beautician.business_name || 'Photo profil'} 
                    className="popup-photo"
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultProfile;
                    }}
                />
                <div className="popup-title">
                    <h3>{beautician.business_name || 'Sans nom'}</h3>
                    <div className="popup-subtitle">
                        <span className="price">{formatPrice(beautician.budget_category)}</span>
                        {beautician.average_rating > 0 && (
                            <span className="rating">★ {beautician.average_rating.toFixed(1)}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="popup-content">
                <p className="address">{beautician.address}</p>
                {beautician.specialties?.length > 0 && (
                    <p className="services">
                        {beautician.specialties.slice(0, 3).join(', ')}
                        {beautician.specialties.length > 3 && '...'}
                    </p>
                )}
            </div>
        </div>
    );
};

export default BeauticianCardPopup;