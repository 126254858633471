import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Plus,
  Edit2,
  Trash2,
  Clock,
  Euro,
  Image as ImageIcon,
  MoreVertical
} from 'lucide-react';
import './BeauticianServices.css';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import { BASE_URL } from '../../../utils/constants';
import { Modal, Button } from "react-bootstrap";
import { FaPlus } from 'react-icons/fa6';
import Swal from 'sweetalert2';
import { beauticiansSubCategoriesApi } from '../../../api/beauticians_subcategories.api';
import { showErrorMessage, showSuccessMessage } from '../../../utils/messageHelper';
import { formatMoneyNumber } from '../../../utils/functions';
import AddPrestationModal from './prestations/AddPrestationModal';
import ServiceImage from './services/ServiceImage';


const EditPrestation = ({ show, handleClose, prestation, setReloadTrigger }) => {
  //on redefinit active id
  const { profileData } = useContext(BeauticianPortalContext);
  const activeID = profileData.beautician_id;

  const [localSubCategory, setLocalSubCategory] = useState({
    id: prestation.service_subcategory_id,
    name: prestation.service_subcategory_name,
    price: Number(prestation.beautician_subcategory_price),
    duration: Number(prestation.beautician_subcategory_duration),
    description: prestation.beautician_subcategory_description || "",
    image_list: [],
    image_list_saved: prestation.images,
  });

  useEffect(() => {
    setLocalSubCategory({
      id: prestation.service_subcategory_id,
      name: prestation.service_subcategory_name,
      price: Number(prestation.beautician_subcategory_price),
      duration: Number(prestation.beautician_subcategory_duration),
      description: prestation.beautician_subcategory_description || "",
      image_list: [],
      image_list_saved: prestation.images,
    });
  }, [prestation]);

  const handleFieldChange = (field, value) => {
    setLocalSubCategory((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const fileArray = Array.from(files);
      const imagePreviews = fileArray.map((file) => {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e) => {
            resolve({ id: Date.now() + Math.random(), src: e.target.result, file });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(imagePreviews).then((images) => {
        setLocalSubCategory((prev) => ({
          ...prev,
          image_list: [...prev.image_list, ...images],
        }));
      });
    }
  };

  const handleImageDelete = (imageId) => {
    setLocalSubCategory((prev) => ({
      ...prev,
      image_list: prev.image_list.filter((image) => image.id !== imageId),
    }));
  };

  const handleSavedImageDelete = async (imageId) => {
    Swal.fire({
      title: "Voullez vous vraiment supprimer cette image?",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
    }).then((result) => {
      if (result.isConfirmed) {
        const delete_image = beauticiansSubCategoriesApi.deletePortfolioImageById(imageId, localStorage.getItem("token"));
        if (delete_image) {
          setLocalSubCategory((prev) => ({
            ...prev,
            image_list_saved: prev.image_list_saved.filter((image) => image.id !== imageId),
          }));
        } else {
          showErrorMessage("Impossible de supprimer l'image!");
        }
      }
    });
  };

  const getImageUrl = (profilePhoto) => {
    return `${BASE_URL}/images/beauticians_subcategories/${encodeURIComponent(profilePhoto)}`;
  };

  // Référence pour déclencher l'input file
  const fileInputRef = useRef();

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleEditSubmit = async () => {

    try {
      Swal.fire({
        title: 'Enregistrement en cours...',
        text: 'Veuillez patienter',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Montre le spinner
        },
      });

      const token = localStorage.getItem("token");

      const data = {
        beautician_id: activeID,
        subcategory_id: localSubCategory.id,
        price: Number(localSubCategory.price),
        duration: Number(localSubCategory.duration),
        description: localSubCategory.description,
      };

      const response = await beauticiansSubCategoriesApi.saveBeauticiansSubCategories(data, token);

      if (response.success) {
        if (response.data && response.data.subcategory_id) {
          // Mise à jour avec l'ID réel retourné
          const newId = response.data.subcategory_id;

          //on enregistre l'image
          let new_image = null;
          if (localSubCategory.image_list.length > 0) {
            const save_image = await beauticiansSubCategoriesApi.saveImageBeauticianSubcategory(response.data.id, token, localSubCategory.image_list);
            new_image = save_image.data;
            if (!save_image) {
              setTimeout(() => {
                showErrorMessage("Échec lors de l'enregistrement des images!");
              }, 200);
            }
          }

          setLocalSubCategory((prev) => ({
            ...prev,
            id: newId,
            image_list: [],
            image_list_saved: new_image ? [...prev.image_list_saved, new_image] : [...prev.image_list_saved]
          }));

        }
        setReloadTrigger(prev => !prev);
        setTimeout(() => {
          showSuccessMessage("Prestation enregistrée avec succès!");
          handleClose();
        }, 200);
      } else {
        setTimeout(() => {
          showErrorMessage("Échec de la sauvegarde du prestation.");
        }, 200);
      }
    } catch (error) {
      setTimeout(() => {
        showErrorMessage(error || "Une erreur s'est produite lors de l'enregistrement.");
      }, 200);
    }
  };

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton className='p-0'>
      <Modal.Title>{prestation.service_subcategory_name}</Modal.Title>
    </Modal.Header>
    <Modal.Body className='px-0 py-2 overflow-y-auto'>
      <div className="flex flex-col gap-3">
        <div className='flex gap-2 flex-wrap justify-between'>
          <div className="flex flex-col gap-2">
            <label htmlFor='prix'>Prix en €: </label>
            <input
              type="number"
              id="prix"
              value={localSubCategory.price}
              min="1"
              onChange={(e) => handleFieldChange("price", parseFloat(e.target.value) || '')}
              className="w-24 px-2 py-1 border rounded"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor='duration'>Durée en minutes: </label>
            <input
              type="number"
              id="duration"
              value={localSubCategory.duration}
              min="1"
              onChange={(e) => handleFieldChange("duration", parseInt(e.target.value, 10) || '')}
              className="w-24 px-2 py-1 border rounded"
            />
          </div>
        </div>
        <div>
          <textarea
            placeholder="Ajouter une description"
            className="w-full p-3 outline-[#bd193b] rounded border shadow resize-none"
            rows={3}
            value={localSubCategory.description}
            onChange={(e) => handleFieldChange("description", e.target.value)}
          />
        </div>
        <div>
          <h3 className="font-semibold text-xl mb-3">Liste des images</h3>
          <div className='mb-3'>
            {localSubCategory.image_list_saved.length > 0 && <p>Images enregistrées</p>}
            {localSubCategory.image_list_saved.length > 0 ?
              <div className="flex gap-2 flex-wrap mt-2">
                {localSubCategory.image_list_saved.map((image) => (
                  <div
                    key={image.id}
                    className="relative w-24 h-24 border rounded overflow-hidden"
                  >
                    <img
                      src={getImageUrl(image.photo_url)}
                      alt="preview"
                      className="w-full h-full object-cover"
                    />
                    <button
                      onClick={() => handleSavedImageDelete(image.id)}
                      className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
              :
              <p>Il n'y a pas d'images enregistrées</p>}
          </div>
          <div>
            {localSubCategory.image_list.length > 0 && <p>Nouvelles images</p>}
            <div className="flex gap-2 flex-wrap mt-2">
              {localSubCategory.image_list.map((image) => (
                <div
                  key={image.id}
                  className="relative w-24 h-24 border rounded overflow-hidden"
                >
                  <img
                    src={image.src}
                    alt="preview"
                    className="w-full h-full object-cover"
                  />
                  <button
                    onClick={() => handleImageDelete(image.id)}
                    className="absolute top-1 right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={triggerFileInput}
            className="flex mt-3 items-center gap-2 px-3 py-2 bg-blue-600 text-white rounded shadow hover:bg-blue-500"
          >
            <FaPlus /> Ajouter des images
          </button>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            ref={fileInputRef}
            style={{ display: "none" }} // Cache l'input file
          />
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className='p-0'>
      <Button variant="secondary" onClick={handleClose}>
        Fermer
      </Button>
      <Button variant="primary" onClick={() => handleEditSubmit()}>
        Sauvegarder
      </Button>
    </Modal.Footer>
  </Modal>;
}

const ServiceCategory = ({ category, onDelete, onEdit, onShowAdd, setSelectedCategory }) => {

  return (
    <div className="service-category">
      <div className="category-header">
        <h3>{category.category_name}</h3>
      </div>

      <div className="prestations-grid">
        {category.items.map((service) => (
          <div key={service.beautician_subcategory_id} className="service-card">
            <div className="service-card-header">
              <h4>{service.service_subcategory_name}</h4>
              <div className="service-actions">
                <button className="action-button" onClick={() => onEdit(service)}>
                  <Edit2 size={16} />
                </button>
                <button className="action-button delete" onClick={() => onDelete(service.beautician_subcategory_id)}>
                  <Trash2 size={16} />
                </button>
              </div>
            </div>

            <div className="service-details">
              <div className="service-info">
                <p className="service-description">{service.beautician_subcategory_description}</p>
                <div className="service-specs">
                  <span className="spec-item">
                    <Euro size={16} />
                    {formatMoneyNumber(service.beautician_subcategory_price)}
                  </span>
                  <span className="spec-item">
                    <Clock size={16} />
                    {service.beautician_subcategory_duration} min
                  </span>
                </div>
              </div>
            </div>
            <ServiceImage images={service.images} />
          </div>
        ))}

        <button className="add-service-card" onClick={() => {setSelectedCategory(category); onShowAdd();}}>
          <Plus size={24} />
          <span>Ajouter un service</span>
        </button>
      </div>
    </div>
  );
};

const BeauticianServices = () => {

  const { prestations, allCategories, setReloadTrigger } = useContext(BeauticianPortalContext);
  const [editingPrestation, setEditingPrestation] = useState(null);
  const [showAddPrestation, setShowAddPrestation] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);


  const handleShowAddPrestation = () => setShowAddPrestation(true);
  const handleCloseAddPrestation = () => setShowAddPrestation(false);
  const handleEdit = (prestation) => setEditingPrestation(prestation);
  const handleClose = () => setEditingPrestation(null);

  const handleDeletePrestation = (beautician_subcategory_id) => {
    Swal.fire({
      title: "Voulez vous vraiment supprimer cette préstation?",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer",
      confirmButtonColor: "#dc2626",
      cancelButtonText: `Non`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const delete_req = await beauticiansSubCategoriesApi.deleteBeauticianCategoryById(beautician_subcategory_id, localStorage.getItem('token'));
          if (delete_req.success) {
            showSuccessMessage('Préstation supprimée avec succès!');
            setTimeout(() => {
              setReloadTrigger(prev => !prev);
            }, 300)
          } else {
            showErrorMessage(delete_req.message);
          }
        } catch (error) {
          showErrorMessage(error);
        }
      }
    });
  }

  return (
    <div className="services-manager">
      <header className="services-header">
        <div className="header-content">
          <h1>Mes Services</h1>
        </div>

        <div className="header-actions">
          <button className="action-button">
            <MoreVertical size={20} />
          </button>
        </div>
      </header>

      <div className="services-content">
        {prestations.map((category) => (
          <ServiceCategory
            key={category.category_id}
            category={category}
            onDelete={handleDeletePrestation}
            onEdit={handleEdit}
            onShowAdd={handleShowAddPrestation}
            setSelectedCategory={setSelectedCategory}
          />
        ))}
        {allCategories.map((category, index) => {
          if (!prestations.some((obj) => obj.category_id === category.category_id)) {
            return <ServiceCategory
              key={index}
              category={{ category_name: category.category_name, category_id: category.category_id, items: [] }}
              onEdit={() => { }}
              onDelete={() => { }}
              handleShow={() => { }}
              onShowAdd={handleShowAddPrestation}
              setSelectedCategory={setSelectedCategory}
            />;
          }
        })}
      </div>
      {/* Render EditPrestation modal only when there's a prestation being edited */}
      {editingPrestation && (
        <EditPrestation
          setReloadTrigger={setReloadTrigger}
          show={Boolean(editingPrestation)}
          handleClose={handleClose}
          prestation={editingPrestation}
        />
      )}
      {showAddPrestation && (
        <AddPrestationModal
          show={showAddPrestation}
          handleClose={handleCloseAddPrestation}
          selectedCategory={selectedCategory}
          setReloadTrigger={setReloadTrigger}
        />
      )}
    </div>
  );
};

export default BeauticianServices;