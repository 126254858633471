import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
// Modification des noms des imports du logo uniquement
import logoBlanc from '../../assets/images/logoBlanc.png';  // Était logo1
import logoRouge from '../../assets/images/logoRouge.png';  // Était logo2
import LoginModal from '../loginModal/LoginModal';
import SignupModal from '../signupModal/SignupModal';
import UserMenu from '../userMenu/UserMenu';
import SignupDropdown from '../SignupDropdown/SignupDropdown';
import { AppContext } from '../../contexts/AppContext';
import { REDIRECT_AFTER_LOGOUT } from '../../utils/constants';
import { FaCalendarAlt  } from 'react-icons/fa';
import { useBooking } from '../../context/BookingContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { HeaderContext } from '../../contexts/HeaderContext';

const Header = () => {
  const {
    isLoginModalOpen,
    isSignupModalOpen,
    setIsLoginModalOpen,
    setIsSignupModalOpen
  } = useContext(HeaderContext)

  const [isBlured, setIsBlured] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);

  const { activePageForHeader, isLoggedIn, setIsLoggedIn } = useContext(AppContext)
  const { selectedServices, currentBeauticianSlug, clearBooking } = useBooking();
  const navigate = useNavigate();

  useEffect(() => {
    // Vérifier l'authentification
    const token = localStorage.getItem('token');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const userType = localStorage.getItem('userType');

    if (token && firstName && lastName) {
      setIsAuthenticated(true);
      setUserData({ firstName, lastName, userType });
    }
  }, []),

    useEffect(() => {
      setIsAuthenticated(isLoggedIn);
      if (isLoggedIn) {
        // Vérifier l'authentification
        const token = localStorage.getItem('token');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const userType = localStorage.getItem('userType');

        if (token && firstName && lastName) {
          setIsAuthenticated(true);
          setUserData({ firstName, lastName, userType });
        }
      }
    }, [isLoggedIn])

    useEffect(() => {
      // Modification du seuil de détection du scroll
      const handleScroll = () => {
        setIsBlured(window.scrollY > 50);
        setIsScrolled(window.scrollY > 50);
      };
    
      // Gestion de l'attribut data-page
      if (activePageForHeader === "home") {
        document.body.setAttribute('data-page', 'home');
      } else if (activePageForHeader === "search") {
        document.body.setAttribute('data-page', 'search');
      } else {
        document.body.removeAttribute('data-page');
      }
    
      window.addEventListener('scroll', handleScroll);
      
      // Nettoyage
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [activePageForHeader]);


  const handleLoginClick = (e) => {
    e.preventDefault();
    setIsSignupModalOpen(false);
    setIsLoginModalOpen(true);
    setIsNavCollapsed(true);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(true);
    setIsNavCollapsed(true);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setUserData(null);
    setIsLoggedIn(false);
    window.location.href = REDIRECT_AFTER_LOGOUT;
  };

  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleNavLinkClick = () => {
    setIsNavCollapsed(true);
  };

  const CartIcon = ({ servicesCount, handleCartIconClick }) => {
    return (
      <div className="cart-icon-container" onClick={handleCartIconClick}>
        <FaCalendarAlt size={35} color="#bd193b" />
        {servicesCount >= 0 && (  // Changé de > à >= pour afficher 0
          <span className="cart-count">{servicesCount}</span>
        )}
      </div>
    );
  };


  const handleCartIconClick = () => {
    if (selectedServices.length > 0) {
      if (currentBeauticianSlug) {
        navigate(`/beauticians/${currentBeauticianSlug}/booking`);
      } else {
        clearBooking();
      }
    } else {
      Swal.fire('Informations', 'Vous n\'avez rien dans le panier!', 'info');
    }
  }

  useEffect(() => {
    window.location.search.replace("?", "") === "status=new-user" && setIsLoginModalOpen(true)
  }, [])

  return (
    <>
      <nav className={`navbar navbar-expand-md fixed-top ${isScrolled ? 'scrolled' : ''} ${isBlured ? 'blured' : ''} ${activePageForHeader === "search" ? "search" : ""}`}>
        <div className="container">
          <a className="navbar-brand" href="/">
            {/* Modification de la logique du logo */}
            <img
              src={
                activePageForHeader === "home"
                  ? (isScrolled || isBlured ? logoRouge : logoBlanc)
                  : logoRouge
              }
              alt="Planibeauty"
              className="navbar-logo"
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            onClick={handleNavCollapse}
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarContent">
            <ul className="navbar-nav ms-auto align-items-center">
              <li className="nav-item">
                <a className="nav-link" href="/concept" onClick={handleNavLinkClick}>
                  Le concept
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/search" onClick={handleNavLinkClick}>
                  Trouver mon expert
                </a>
              </li>
              {/* SignupDropdown ne s'affichera que si l'utilisateur n'est pas authentifié */}
              <li className="nav-item">
                <SignupDropdown
                  isAuthenticated={isAuthenticated}
                  setIsSignupModalOpen={setIsSignupModalOpen}
                />
              </li>
              <li className="nav-item">
                {isAuthenticated ? (
                  <UserMenu
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    userType={userData.userType}
                    onLogout={handleLogout}
                  />
                ) : (
                  <a className="nav-link login-button" href="/connexion" onClick={handleLoginClick}>
                    Connexion
                  </a>
                )}
              </li>
              <li className='nav-item ms-3'>
                <CartIcon servicesCount={selectedServices.length} handleCartIconClick={handleCartIconClick} />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSignupClick={handleSignupClick}
      />

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        onLoginClick={handleLoginClick}
      />
    </>
  );
};

export default Header;