import React, { useEffect } from 'react'
import ClientPortalLayout from './ClientPortalLayout'
import { Routes, Route } from 'react-router-dom';
import Profile from '../../../components/portals/client/Profile';
import AppointmentList from '../../../components/portals/client/AppointmentList';
import Historique from '../../../components/portals/client/Historique';
import Notification from '../../../components/portals/client/Notifications';

const ClientPortal = () => {
	useEffect(() => {
		window.scrollTo(0, 60)
	})
	return (
		<Routes>
			<Route element={<ClientPortalLayout />}>
				<Route index element={<Profile />} />
				<Route path="agenda" element={<AppointmentList />} />
				<Route path="historiques" element={<Historique />} />
				<Route path="notifications" element={<Notification />} />
			</Route>
		</Routes>
	)
}

export default ClientPortal