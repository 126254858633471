import React, { useState } from 'react';
import { Send } from 'lucide-react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contact__form">
      <div className="form-container">
        <h1 className="text-2xl font-bold mb-6">Contactez-nous</h1>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="form-group">
            <input
              type="text"
              id="name"
              name="name"
              required
              placeholder="Votre nom"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Votre email"
              value={formData.email}
              onChange={(e) => setFormData({...formData, email: e.target.value})}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              id="subject"
              name="subject"
              required
              placeholder="Sujet"
              value={formData.subject}
              onChange={(e) => setFormData({...formData, subject: e.target.value})}
            />
          </div>

          <div className="form-group">
            <textarea
              id="message"
              name="message"
              required
              placeholder="Votre message"
              rows="5"
              value={formData.message}
              onChange={(e) => setFormData({...formData, message: e.target.value})}
            />
          </div>

          <button type="submit" className="submit-btn">
            <span>Envoyer</span>
            <Send size={20} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;