import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard,
  UserCircle,
  Scissors,
  Calendar,
  BarChart2,
  Settings,
  Clock,
} from 'lucide-react';
import './BeauticianPortalSidebar.css';

import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';

const BeauticianPortalSidebar = ({ onLinkClick }) => {

  const {profileData, isParentLoading} = useContext(BeauticianPortalContext);

  const [menuItems, setMenuItems] = useState([
    {
    title: "Tableau de bord",
    icon: <LayoutDashboard size={20} />,
    path: "/beautician-dashboard/", // Chemin complet pour le dashboard
    exact: true, // Propriété pour activer uniquement ce chemin exact
  }
]);

useEffect(() => {
  if(!isParentLoading && profileData) {

    if (profileData.is_active) {
    setMenuItems(([
      {
        title: "Tableau de bord",
        icon: <LayoutDashboard size={20} />,
        path: "/beautician-dashboard/", // Chemin complet pour le dashboard
        exact: true, // Propriété pour activer uniquement ce chemin exact
      },
      {
        title: "Mon Profil",
        icon: <UserCircle size={20} />,
        path: "/beautician-dashboard/profile",
      },
      {
        title: "Mes Services",
        icon: <Scissors size={20} />,
        path: "/beautician-dashboard/services",
      },
      {
        title: "Agenda",
        icon: <Calendar size={20} />,
        path: "/beautician-dashboard/agenda",
      },
      {
        title: "Historiques",
        icon: <Clock size={20} />,
        path: "/beautician-dashboard/historiques",
      },
      {
        title: "Statistiques",
        icon: <BarChart2 size={20} />,
        path: "/beautician-dashboard/stats",
      },
      {
        title: "Paramètres",
        icon: <Settings size={20} />,
        path: "/beautician-dashboard/settings",
      }
    ]));
  }
  }
}, [profileData, isParentLoading]);

  return (
    <div className="sidebar-inner">
      <div className="sidebar-branding">
        <span className="sidebar-brand-text">BeautyPro</span>
      </div>

      <nav className="sidebar-nav">
        {menuItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              `sidebar-link ${isActive ? 'sidebar-link-active' : ''}`
            }
            onClick={onLinkClick}
            end={item.exact} // Utiliser `end` uniquement pour les chemins exacts
          >
            <span className="sidebar-link-icon">{item.icon}</span>
            <span className="sidebar-link-text">{item.title}</span>
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default BeauticianPortalSidebar;
