import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import userApi from '../../api/user.api';

const CreateOperatorModal = ({ show, handleClose }) => {
    // États pour chaque champ de formulaire
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        confirm_password: '',
        user_type: 'operator',
    });

    const close = () => {
        setFormData({
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            password: '',
            confirm_password: '',
            user_type: 'operator'
        });
        handleClose();
    }

    // État pour gérer les erreurs de validation
    const [errors, setErrors] = useState({});

    // Mise à jour des champs du formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Fonction de validation manuelle
    const validateForm = () => {
        const newErrors = {};

        if (!formData.first_name) newErrors.first_name = 'Le prénom est requis';
        if (!formData.last_name) newErrors.last_name = 'Le nom est requis';
        if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Le numéro de téléphone doit contenir 10 chiffres';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'L\'email n\'est pas valide';
        if (!formData.password || formData.password.length < 6) newErrors.password = 'Le mot de passe doit avoir au moins 6 caractères';
        if (formData.password !== formData.confirm_password) newErrors.confirm_password = 'Les mots de passe ne correspondent pas';

        setErrors(newErrors);

        // Retourne vrai si aucune erreur, sinon faux
        return Object.keys(newErrors).length === 0;
    };

    // Fonction de soumission
    const onSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            Swal.fire({
                title: 'Chargement...',
                text: 'Veuillez patienter...',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                const user_create = await userApi.register(formData);

                if (user_create.user) {
                    Swal.close();
                    setTimeout(() => {
                        setFormData({
                            first_name: '',
                            last_name: '',
                            phone: '',
                            email: '',
                            password: '',
                            confirm_password: '',
                            user_type: 'operator'
                        });
                        close(); // Ferme le modal après la soumission
                        Swal.fire('Succès', 'Opétateur créé avec succès.', 'success');
                    }, 200);
                } else {
                    Swal.close();
                    setTimeout(() => {
                        Swal.fire('Erreur', user_create.message || 'Erreur lors de la création de l\'opérateur.', 'error');
                    }, 200);
                }
            } catch (error) {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', error.message || 'Erreur lors de la création de l\'opérateur.', 'error');
                }, 200);
            }
        }
    };

    return (
        <Modal show={show} onHide={close} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un Opérateur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Row className='mb-2'>
                        <Col md={12}>
                            <div className='flex flex-col gap-1'>
                                <Form.Label>Type*</Form.Label>
                                <select name="user_type" id="user_type" className='px-3 py-2 border border-gray-500 rounded bg-gray-100' value={formData.user_type} onChange={handleChange}>
                                    <option value="operator">Opérateur</option>
                                    <option value="admin">Admin</option>
                                </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="first_name">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez le prénom"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.first_name}
                                />
                                {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="last_name">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez le nom"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    isInvalid={!!errors.last_name}
                                />
                                {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='mb-2'>
                        <Col md={6}>
                            <Form.Group controlId="phone">
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Entrez le numéro de téléphone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phone}
                                />
                                {errors.phone && <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Entrez l'email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                />
                                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="password">
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Entrez le mot de passe"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                />
                                {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="confirm_password">
                                <Form.Label>Confirmer le mot de passe</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirmez le mot de passe"
                                    name="confirm_password"
                                    value={formData.confirm_password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.confirm_password}
                                />
                                {errors.confirm_password && <Form.Control.Feedback type="invalid">{errors.confirm_password}</Form.Control.Feedback>}
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="danger" className='mt-3' type="submit">
                        Créer l'opérateur
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateOperatorModal;