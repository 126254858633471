import React, {useContext} from "react";
import BlockedDay from "../../../dashboard/siteInfoAgenda/BlockedDay";
import OpenTime from "../../../dashboard/siteInfoAgenda/OpenTime";
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';


const AvailabilitySettings = () => {

    const {profileData} = useContext(BeauticianPortalContext);

    return <div className="p-4">
        <OpenTime activeID={profileData.beautician_id}/>
        <BlockedDay activeID={profileData.beautician_id}/>
    </div>
}

export default AvailabilitySettings;