import "./UserData.css"
import styled from "styled-components";

import { useContext, useState, useEffect, useRef } from "react"
import { FaComment, FaEllipsisVertical, FaEye, FaPencil, FaTrash } from "react-icons/fa6"
import { dashboardContext } from "../../pages/dashboard/Dashboard";
import { truncateText } from "../../utils/stringHelper";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { FaCheck } from "react-icons/fa";
import Swal from "sweetalert2";
import beauticianApi from "../../api/beautician.api";
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import { fr } from "date-fns/locale";

const CustomDropdown = ({ editUser, deleteRow, showSiteInfo, showComment, acceptRegistration, is_active }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState("bottom"); // "top" ou "bottom"
    const dropdownRef = useRef(null); // Ref pour le dropdown
    const { activePage } = useContext(dashboardContext);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const callAction = (action) => {
        setIsOpen(false);
        action();
    };

    // Fermer le dropdown en cliquant en dehors
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Ajouter le gestionnaire d'événements
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Nettoyer l'événement
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Ajuster la position du menu en fonction de l'espace disponible
    useEffect(() => {
        if (isOpen && dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            // Si le menu dépasse vers le bas, afficher vers le haut
            if (rect.bottom > (windowHeight - 250)) {
                setDropdownPosition("top");
            } else {
                setDropdownPosition("bottom");
            }
        }
    }, [isOpen]);

    return (
        <div className="text-center relative" ref={dropdownRef}>
            {/* Bouton de déclenchement */}
            <button
                className="p-2 rounded-full hover:bg-gray-200"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
                aria-haspopup="true"
            >
                <FaEllipsisVertical />
            </button>

            {/* Menu déroulant */}
            {isOpen && (
                <div
                    className={`absolute w-96 p-2 bg-gray-100 shadow-lg border border-gray-300 rounded-md ${dropdownPosition === "top" ? "bottom-10" : "top-10"
                        } right-2`}
                    style={{ zIndex: "1000" }}
                >
                    {(activePage !== "client" && activePage !== "operator") && <>
                        {!is_active && <button
                            onClick={() => callAction(acceptRegistration)}
                            className="bg-green-500 flex items-center text-white gap-2 px-4 py-2 hover:bg-green-600 w-full text-left"
                        >
                            <FaCheck /> Accepter l'inscription du professionnel
                        </button>}
                    </>
                    }
                    <button
                        onClick={() => callAction(editUser)}
                        className="flex items-center bg-yellow-400 gap-2 px-4 py-2 hover:bg-yellow-500 w-full text-left"
                    >
                        <FaPencil /> Modifier les informations personnelles
                    </button>
                    <button
                        onClick={() => callAction(deleteRow)}
                        className="flex items-center bg-red-500 text-white gap-2 px-4 py-2 hover:bg-red-600 w-full text-left"
                    >
                        <FaTrash /> Supprimer le profil
                    </button>
                    {(activePage !== "client" && activePage !== "operator") && <>
                        <button
                            onClick={() => callAction(showSiteInfo)}
                            className="flex items-center bg-blue-500 text-white gap-2 px-4 py-2 hover:bg-blue-600 w-full text-left"
                        >
                            <FaEye /> Voir et modifier les caractéristiques
                        </button>
                        <button
                            onClick={() => callAction(showComment)}
                            className="flex items-center gap-2 px-4 py-2 bg-gray-200 hover:bg-gray-300 w-full text-left"
                        >
                            <FaComment /> Voir les avis
                        </button>
                    </>}
                </div>
            )}
        </div>
    );
};

const StyledCellWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;


const UserData = ({ datas }) => {
    const {
        setActiveID,
        showSiteInformation,
        showDeletionModal,
        showEditModal,
        setUserData,
        showUserSheetModal,
        activePage,
        setShowBeauticianCommentaire,
        setReloadTrigger
    } = useContext(dashboardContext)

    const [selectedRows, setSelectedRows] = useState([]);


    const handleRowClick = (row) => {
        setActiveID(row['id']);
        setUserData(row);
        showUserSheetModal(true);
    };

    const handleSelectAll = () => {
        const allIds = datas.map((row) => row.id);
        setSelectedRows(selectedRows.length === datas.length ? [] : allIds);
    };

    const handleRowSelect = (rowId) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(rowId)
                ? prevSelected.filter((id) => id !== rowId)
                : [...prevSelected, rowId]
        );
    };

    const deleteRow = (row) => {
        setActiveID(row["id"])
        showDeletionModal(true)
    }

    const editUser = (row) => {
        setActiveID(row['id']);
        setUserData(row)
        showEditModal(true)
    }

    const showSiteInfo = (row) => {
        setUserData(row)
        setActiveID(row['id']);
        showSiteInformation(true);
    }

    const showComment = (row) => {
        setActiveID(row['id']);
        setShowBeauticianCommentaire(true);
    }

    const MySwal = withReactContent(Swal);

    const handleAvailabilityChange = (row) => {
        MySwal.fire({
            title: 'Confirmation',
            text: `Voulez-vous vraiment modifier la disponibilité de ${row.first_name} ${row.last_name} ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Mettre à jour la disponibilité via une API ou une méthode
                await beauticianApi.updateAvailability(localStorage.getItem('token'), row.id, { is_available: !row.is_available });
                setReloadTrigger(prev => !prev);
            }
        });
    };

    const acceptRegistration = (row) => {
        Swal.fire({
            title: "Voulez-vous vraiment accepter l'inscription?",
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui, accepter"
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Afficher le loader
                Swal.fire({
                    title: 'Traitement en cours...',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                try {
                    await beauticianApi.acceptRegistration(
                        localStorage.getItem('token'),
                        row['id'],
                        row['email'],
                        row['first_name'] + ' ' + row['last_name']
                    );
                    
                    // Indiquer le succès
                    Swal.fire({
                        title: "Succès!",
                        text: "L'inscription a été acceptée.",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        setReloadTrigger(prev => !prev);
                    });
                } catch (error) {
                    // Gestion des erreurs
                    Swal.fire({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'acceptation de l'inscription.",
                        icon: "error",
                    });
                }
            }
        });
    };


    const formatType = (type) => {
        switch (type) {
            case 'admin':
                return 'Admin';
            case 'operator':
                return 'Opérateur';
            case 'beautician':
                return 'Professionnel';
            case 'client':
                return 'Client';
            default:
                return '';
        }
    }

    // Colonnes de la table
    const columns = ['all', 'indépendant', 'établissement'].includes(activePage) ? [
        {
            name: (
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === datas.length}
                />
            ),
            cell: (row) => (
                <input
                    type="checkbox"
                    className="ms-2"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: "50px",
            ignoreRowClick: true,
        },
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "70px",
        },
        {
            name: "Type",
            selector: (row) => formatType(row.user_type),
        },
        {
            name: "Nom",
            selector: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: "Adresse",
            cell: (row) => (row.address ? truncateText(row.address, 25) : "-"),
        },
        {
            name: "Ville",
            selector: (row) => row.city || "-",
            sortable: true
        },
        {
            name: "Téléphone",
            selector: (row) => row.phone,
        },
        {
            name: "Date création",
            cell: (row) => format(new Date(row.created_at), "d MMMM yyyy à HH:mm", { locale: fr }),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA.created_at);
                const dateB = new Date(rowB.created_at);
                return dateA - dateB; // Tri croissant
            },
        },
        {
            name: "Disponibilité",
            selector: (row) => row.is_available,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    <ReactSwitch
                        checked={row.is_available}
                        onChange={() => handleAvailabilityChange(row)}
                        offColor="#f87171" // Couleur désactivée
                        onColor="#4ade80" // Couleur activée
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={40} // Largeur du switch
                        height={20} // Hauteur du switch
                        handleDiameter={15}
                    />
                </div>
            )
        },
        {
            name: "Status compte",
            selector: (row) => row.is_active,
            cell: (row) => (
                <div className="flex justify-center items-center w-full">
                    {row.is_active ?
                        <span className="bg-green-500 px-2 py-1 text-sm text-white rounded">Accepté</span> :
                        <span className="bg-yellow-500 px-2 py-1 text-sm rounded">En attente</span>}
                </div>
            ),
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showSiteInfo={() => showSiteInfo(row)}
                            showComment={() => showComment(row)}
                            is_active={row.is_active}
                            acceptRegistration={() => acceptRegistration(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "90px",
        }
    ] : [
        {
            name: (
                <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedRows.length === datas.length}
                />
            ),
            cell: (row) => (
                <input
                    type="checkbox"
                    className="ms-2"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            ),
            width: "80px",
            ignoreRowClick: true,
        },
        {
            name: "ID",
            selector: (row) => row.id,
            sortable: true,
            width: "80px",
        },
        {
            name: "Type",
            selector: (row) => formatType(row.user_type),
        },
        {
            name: "Nom",
            selector: (row) => `${row.first_name} ${row.last_name}`,
            sortable: true,
        },
        {
            name: "Téléphone",
            selector: (row) => row.phone,
        },
        {
            name: "Date création",
            cell: (row) => format(new Date(row.created_at), "dd-MM-yyyy HH:mm", { locale: fr }),
            sortable: true,
            sortFunction: (rowA, rowB) => {
                const dateA = new Date(rowA.created_at);
                const dateB = new Date(rowB.created_at);
                return dateA - dateB; // Tri croissant
            },
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className=" w-full flex justify-center items-center">
                    <StyledCellWrapper>
                        <CustomDropdown
                            editUser={() => editUser(row)}
                            deleteRow={() => deleteRow(row)}
                            showSiteInfo={() => showSiteInfo(row)}
                            showComment={() => showComment(row)}
                        />
                    </StyledCellWrapper>
                </div>
            ),
            ignoreRowClick: true, // Empêche les clics sur cette cellule d'interagir avec les lignes
            allowoverflow: "true", // Contrôle l'affichage dans la cellule
            width: "100px",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Lignes par page',
        rangeSeparatorText: 'sur',
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Tout',
    };


    // Styles personnalisés pour la table
    const customStyles = {
        tableWrapper: {
            style: {
                minHeight: "65vh", // Définissez ici votre hauteur minimale souhaitée
            },
        },
        rows: {
            style: {
                minHeight: "50px",
                cursor: "pointer"
            },
        },
        headCells: {
            style: {
                fontWeight: "bold",
                backgroundColor: "#f7f7f7",
                fontSize: "14px"
            },
        },
        cells: {
            style: {
                padding: "5px",
                fontSize: "14px"
            },
        },
    };

    return (
        <div>
            {datas.length > 0 ?
                <DataTable
                    columns={columns}
                    data={datas}
                    onRowClicked={handleRowClick}
                    pagination
                    highlightOnHover
                    responsive
                    customStyles={customStyles}
                    paginationComponentOptions={paginationOptions}
                /> :
                <div className="p-5 text-center">
                    <p className="text-gray-700 text-xl">Aucun enregitrements à afficher...</p>
                </div>
            }
        </div>
    );
}

export default UserData