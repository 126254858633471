import { API_URL } from "../utils/constants";

const availabilityApi = {
    // Obtention du liste de disponibilité d'une esthéticienne
    getBeauticianAvailability: async (beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/availability/beautician/${beautician_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des la disponibilités de l\'esthéticienne');
        }
    },
    // Obtention de disponibilité par ID
    getAvailabilityByID: async (id) => {
        try {
            const response = await fetch(`${API_URL}/availability/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la disponibilité');
        }
    },
    // Création d'une disponibilité
    createAvailability: async(token, availabilityData) => {
        try {
            const response = await fetch(`${API_URL}/availability`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(availabilityData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création de la disponibilité');
        }
    },
    // Mise à jours d'une disponibilité
    updateAvailability: async(token, availabilityID ,availabilityData) => {
        try {
            const response = await fetch(`${API_URL}/availability/${availabilityID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(availabilityData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours de la disponibilité');
        }
    },
    // Suppression d'une disponibilité
    deleteAvailability: async(token, availabilityID) => {
        try {
            const response = await fetch(`${API_URL}/availability/${availabilityID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppression de la disponibilité');
        }
    },

    //verification si il existe déja
    verifyIfExist: async(token, beautician_id, day) => {
        try {
            const response = await fetch(`${API_URL}/availability/verify-if-exist`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({beautician_id: beautician_id, day: day})
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération de la disponibilité');
        }
    }
}

export default availabilityApi;