import { Modal, Button } from 'react-bootstrap';
import appointmentApi from '../../../../api/appointement.api';
import { useContext } from 'react';
import { BeauticianPortalContext } from '../../../../context/BeauticianPortalContext';
import Swal from 'sweetalert2';
import { sendEmailBookingStatusToClient } from '../../../../utils/mailServices';

const ShowInfoClientModal = ({ showModal, handleCloseModal, selectedEvent }) => {

  const {profileData, setChangeTrigger} = useContext(BeauticianPortalContext);


  const handleCancelAppointment = (appointment) => {
    Swal.fire({
      showCancelButton: true,
      showConfirmButton: true,
      title: 'Voulez vous Annuler ce rendez-vous?',
      text: "L'action est irreversible",
      confirmButtonText: "Oui, annuler",
      cancelButtonText: "Non",
      icon: "question"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const request = await appointmentApi.updateAppointmentStatus(localStorage.getItem('token'), appointment.extendedProps.appointment_id, { status: "cancelled" });
        if (request.appointment) {
          sendEmailBookingStatusToClient(
            {
              email: appointment.extendedProps.email,
              first_name: appointment.extendedProps.first_name,
              last_name: appointment.extendedProps.last_name,
            },
            `${profileData.first_name} ${profileData.last_name}`,
            appointment.extendedProps.appointment_date,
            appointment.extendedProps.start_time,
            appointment.extendedProps.end_time,
            'cancel'
          );
          Swal.fire("Informations", 'Le rendez-vous a été annulé!', 'success').then(() => {
            setChangeTrigger(prev => !prev);
            handleCloseModal();
          });
        }
      }
    })
  }

  return <Modal show={showModal} onHide={handleCloseModal} centered>
    <Modal.Header closeButton>
      <Modal.Title>A propos du rendez-vous</Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-y-scroll">
      <div className="mb-3">
        <strong className="text-primary">Nom du client :</strong>
        <p>
          {selectedEvent.extendedProps.first_name}{' '}
          {selectedEvent.extendedProps.last_name}
        </p>
      </div>
      <div className="mb-3">
        <strong className="text-primary">Email :</strong>
        <p>{selectedEvent.extendedProps.email}</p>
      </div>
      <div className="mb-3">
        <strong className="text-primary">Téléphone :</strong>
        <p>{selectedEvent.extendedProps.phone || 'Non spécifié'}</p>
      </div>
      <div className="mb-3">
        <strong className="text-primary">Date :</strong>
        <p>
          {selectedEvent.start.toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </p>
      </div>
      <div className="mb-3">
        <strong className="text-primary">Heure :</strong>
        <p>
          {selectedEvent.start.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          -{' '}
          {selectedEvent.end.toLocaleTimeString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </p>
      </div>
      <div className="mb-3">
        <strong className="text-primary">Services :</strong>
        {selectedEvent.extendedProps.services && (
          <ul>
            {selectedEvent.extendedProps.services.map((service, index) => (
              <li key={index}>
                {service.subcategory_name} - {service.subcategory_price}€{' '}
                ({service.subcategory_duration} min)
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mb-3">
        <strong className="text-primary">Statut du paiement :</strong>
        <p>
          Type de paiement :{' '}
          {selectedEvent.extendedProps.payment_type || 'Inconnu'}
          <br />
          Total à payer : {selectedEvent.extendedProps.total_prices} €
          <br />
          Payé : {selectedEvent.extendedProps.payed} €
          <br />
          Reste :{' '}
          {(
            Number(selectedEvent.extendedProps.total_prices) -
            Number(selectedEvent.extendedProps.payed)
          ).toFixed(2)}{' '}
          €
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={() => handleCancelAppointment(selectedEvent)}>
        Annuler le rendez-vous
      </Button>
      <Button variant="secondary" onClick={handleCloseModal}>
        Fermer
      </Button>
    </Modal.Footer>
  </Modal>;

}

export default ShowInfoClientModal;