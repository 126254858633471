import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import ServicesSelection from '../../components/booking/ServicesSelection';
import DateSelection from '../../components/booking/DateSelection';
import LoginModal from '../../components/loginModal/LoginModal';
import SignupModal from '../../components/signupModal/SignupModal';
import PaymentOptions from '../../components/booking/PaymentOptions';
import './BookingPage.css';
import { useBooking } from '../../context/BookingContext';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Swal from 'sweetalert2';
import appointmentApi from '../../api/appointement.api';
import StripePaymentForm from '../../components/booking/StripePaymentForm';
import { FaX } from 'react-icons/fa6';
import { sendEmailNotification } from '../../utils/mailServices';
import { getBeauticianIdBySlug } from '../../utils/functions';

const BookingPage = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [paymentOption, setPaymentOption] = useState('onsite');
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const {
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedServices,
    currentBeauticianSlug,
    clearBooking
  } = useBooking();

  const { setActivePageForHeader } = useContext(AppContext);

  //payement data
  const [bookingData, setBookingData] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Dans BookingPage.js
  useEffect(() => {
    document.body.setAttribute('data-page', 'search');  // Changé de 'beautician' à 'search'
    setActivePageForHeader("search");

    return () => {
      document.body.removeAttribute('data-page');
      setActivePageForHeader("");
    }
  }, [setActivePageForHeader]);  // Ajout de la dépendance

  /*useEffect(() => {
    if (selectedServices.length === 0) {
      Swal.fire('Informations', 'Le panier est vide, veuillez ajouter pour pouvoir réserver!', 'info').then(() => {
        navigate(`/beauticians/${id}`);
      });
    }
  }, [selectedServices]);*/

  const handleAuthSuccess = () => {
    setIsAuthenticated(true);
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(false);
  };

  const handleStepClick = (step) => {
    if (step == 2) {
      if (selectedServices.length > 0) {
        setCurrentStep(step);
      }
    } else if (step == 3) {
      if (selectedServices.length > 0 && selectedDate && selectedTime) {
        setCurrentStep(step);
      }
    } else {
      setCurrentStep(step);
    }
  }


  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  }

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  }

  const calculateTotal = () => {
    const total = selectedServices.reduce((sum, service) =>
      sum + Number(service.beautician_subcategory_price), 0
    );
    return total.toFixed(2);
  };

  const calculateTotalTime = () => {
    const total = selectedServices.reduce((sum, service) =>
      sum + service.beautician_subcategory_duration, 0
    );
    return total;
  }

  const handleLoginClick = (e) => {
    e.preventDefault();
    setIsSignupModalOpen(false);
    setIsLoginModalOpen(true);
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    setIsLoginModalOpen(false);
    setIsSignupModalOpen(true);
  };

  useEffect(() => {
    // Réinitialiser la position du scroll au sommet
    window.scrollTo(0, 0);
  }, []);

  const handleCancelBooking = () => {
    Swal.fire({
      title: "Voulez vous vraiment annuler?",
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui, annuler!'
    }).then((result) => {
      if (result.isConfirmed) {
        clearBooking();
        navigate("/search");
      }
    })
  }

  const renderStepIndicator = () => {
    return (
      <div className="booking-steps">
        <div className={`step ${currentStep >= 1 ? 'active' : ''}`}>
          <div className="step-number cursor-pointer" onClick={() => handleStepClick(1)}>1</div>
          <div className="step-text">Prestations</div>
        </div>
        <div className="step-line"></div>
        <div className={`step ${currentStep >= 2 ? 'active' : ''}`}>
          <div className="step-number cursor-pointer" onClick={() => handleStepClick(2)}>2</div>
          <div className="step-text">Horaire</div>
        </div>
        <div className="step-line"></div>
        <div className={`step ${currentStep === 3 ? 'active' : ''}`}>
          <div className="step-number cursor-pointer" onClick={() => handleStepClick(3)}>3</div>
          <div className="step-text">Confirmation</div>
        </div>
      </div>
    );
  };

  function addMinutesToTime(time, minutesToAdd) {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Ajouter les minutes
    date.setMinutes(date.getMinutes() + minutesToAdd);

    // Retourner l'heure au format HH:mm
    return date.toTimeString().slice(0, 5);
  }



  const bookingLogic = async (appointmentData) => {
    try {
      // Afficher le loader
      Swal.fire({
        title: 'Veuillez patienter...',
        text: 'Nous traitons votre réservation.',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      console.log(appointmentData)

      // Créer le rendez-vous
      const create_appointment = await appointmentApi.createAppointment(
        localStorage.getItem('token'),
        appointmentData
      );

      if (create_appointment.success) {

        sendEmailNotification(
          appointmentData.beautician_id,
          appointmentData.appointment_date,
          appointmentData.start_time,
          appointmentData.end_time
        );

        // Afficher un message de succès
        Swal.fire('Succès', 'Réservation effectuée avec succès!', 'success').then(() => {
          clearBooking(); // Réinitialiser les données de réservation
          setSelectedDate(null); // Réinitialiser la date
          setSelectedTime(null); // Réinitialiser l'heure
          navigate('/client-dashboard'); // Redirection
        });
      } else {
        // Afficher une erreur si la création de la réservation échoue
        Swal.fire('Erreur', 'Une erreur est survenue lors de la réservation.', 'error');
      }
    } catch (error) {
      // Gérer les erreurs générales
      Swal.fire('Erreur', 'Une erreur est survenue. Veuillez réessayer.', 'error');
    }
  };

  const getPayAmount = (payment_type, total_amount) => {
    if (payment_type === "onsite") {
      return 0.00;
    } else {
      return paymentOption === 'deposit' ? (Number.parseFloat(total_amount) * 0.5).toFixed(2) : Number.parseFloat(total_amount).toFixed(2)
    }
  }

  const handleBookingConfirm = async () => {

    const beauticianId = await getBeauticianIdBySlug(currentBeauticianSlug);

    if (beauticianId) {

      let appointmentData = {
        name: localStorage.getItem("firstName") + " " + localStorage.getItem("lastName"),
        beautician_id: beauticianId, // beautician id
        user_id: localStorage.getItem("userId"),
        appointment_date: format(selectedDate, 'yyyy-MM-dd'),
        start_time: selectedTime,
        end_time: addMinutesToTime(selectedTime, calculateTotalTime()),
        total_price: calculateTotal(),
        payed: getPayAmount(paymentOption, calculateTotal()),
        payment_type: paymentOption,
        services: selectedServices.map(service => ({
          beautician_subcategory_id: service.beautician_subcategory_id,
          price_at_booking: service.beautician_subcategory_price,
        })),
      };

      //on verifie si le créneaux est toujours disponible

      const result = await appointmentApi.isAppointmentAvailable(appointmentData.beautician_id, appointmentData.appointment_date, appointmentData.start_time, appointmentData.end_time);
      if (result.success && result.is_available) {

        setBookingData(appointmentData);

        if (paymentOption === "onsite") {
          Swal.fire({
            title: "Confirmez la réservation?",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Oui, réserver!',
            cancelButtonText: 'Non',
            icon: "question"
          }).then((result) => {
            if (result.isConfirmed) {
              bookingLogic(appointmentData);
            }
          })

        } else {
          setShowPaymentForm(true);
        }
      } else if (result.success && !result.is_available) {
        Swal.fire("Informations", 'Désolé mais ce créneau n\'est plus disponible, veuillez choisir un autre!', 'info');
      } else {
        Swal.fire("Erreur interne du serveur", '', "error");
      }
    } else {
      navigate("/404");
    }

  };

  const renderAuthSection = () => {
    if (!isAuthenticated) {
      return (
        <div className="auth-required">
          <p>Pour finaliser votre réservation, veuillez vous connecter ou créer un compte.</p>
          <div className="auth-buttons">
            <button
              className="login-button"
              onClick={handleLoginClick}
            >
              Se connecter
            </button>
            <button
              className="register-button"
              onClick={handleSignupClick}
            >
              Créer un compte
            </button>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderPaymentOptions = () => {
    const totalAmount = Number(calculateTotal());
    return (
      <PaymentOptions
        totalAmount={totalAmount}
        selectedOption={paymentOption}
        onOptionSelect={setPaymentOption}
      />
    );
  };

  const handleBack = (step) => {
    setCurrentStep(step);
  }

  const cancelPayment = (e) => {
    e.preventDefault();
    setShowPaymentForm(false);
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <ServicesSelection
            onNextStep={nextStep}
          />
        );
      case 2:
        return (
          <DateSelection
            onNextStep={nextStep}
            onPrevStep={prevStep}
          />
        );
      case 3:
        return (
          <div className="booking-confirmation">
            <h2 className='text-center'>Résumé de votre réservation</h2>
            <div className="booking-summary">
              <div className="services-summary">
                <h3>Services sélectionnés</h3>
                {selectedServices.map(service => (
                  <div key={service.service_subcategory_id} className="px-2 flex justify-between">
                    <span>{service.service_subcategory_name}</span>
                    <span>{service.beautician_subcategory_duration} min</span>
                    <span>{Number(service.beautician_subcategory_price).toFixed(2)}€</span>
                  </div>
                ))}
                <div className="total-line">
                  <span>Total</span>
                  <span className="total-amount">{calculateTotal()}€</span>
                </div>
              </div>
              <div className="datetime-summary">
                <h3>Date et heure</h3>
                <p>{format(selectedDate, "EEEE d MMMM yyyy", { locale: fr })} à {selectedTime}</p>
                <p>Durée de l'intervention : {calculateTotalTime()} minutes</p>
              </div>

              {(isAuthenticated && localStorage.getItem('userType') === "client" && !showPaymentForm) ? (
                <>
                  {renderPaymentOptions()}
                  <button
                    className="confirm-button"
                    onClick={handleBookingConfirm}
                    disabled={!paymentOption}
                  >
                    {paymentOption === 'onsite'
                      ? 'Confirmer la réservation'
                      : 'Procéder au paiement'}
                  </button>
                  <button
                    className="return-button"
                    onClick={() => handleBack(2)}
                  >
                    Retour
                  </button>
                </>
              ) : (
                renderAuthSection()
              )}

              {showPaymentForm && <>
                <div className='flex justify-end'>
                  <a href='#' className='text-red-600 bg-red-200 py-2 px-3 rounded hover:bg-red-500 text-base no-underline flex gap-2 items-center' onClick={cancelPayment}><FaX /> <span>Annuler</span></a>
                </div>
                <StripePaymentForm
                  token={localStorage.getItem('token')}
                  appointmentDetails={bookingData}
                />
              </>}

              {(isAuthenticated && localStorage.getItem("userType") !== "client") && <h3 className='text-red-400'>Vous n'avez pas le droit necéssaire à éffectuer cette action!</h3>}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="booking-page">
      {renderStepIndicator()}
      <div className="booking-content">
        <button
          className="next-step-button mb-3"
          onClick={handleCancelBooking}
        >
          <FaX />
          <span>Annuler la réservation</span>
        </button>
        {renderCurrentStep()}
      </div>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSignupClick={handleSignupClick}
        onLoginSuccess={handleAuthSuccess}
        redirectAfterLogin={false}
      />

      <SignupModal
        isOpen={isSignupModalOpen}
        onClose={() => setIsSignupModalOpen(false)}
        onLoginClick={handleLoginClick}
        onSignupSuccess={handleAuthSuccess}
        redirectAfterSignup={false}
      />
    </div>
  );
};

export default BookingPage;