import ChangeEmailComponent from '../../../common/ChangeEmailComponent';
import PasswordEditComponent from '../../../common/PasswordEditComponent';
import Accordion from 'react-bootstrap/Accordion';


const SecuritySettings = () => {
    return <div className="settings-form">

        <div className="form-section">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Adresse email</Accordion.Header>
                    <Accordion.Body className="flex flex-col gap-3">
                        <ChangeEmailComponent />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Mot de passe</Accordion.Header>
                    <Accordion.Body className="flex flex-col gap-3">
                        <PasswordEditComponent />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    </div>
}

export default SecuritySettings;