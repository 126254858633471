
const NotificationSettings = () => {
    return <div className="settings-form">
        <div className="notifications-settings">
            <div className="notification-option">
                <div className="notification-info">
                    <h4>Notifications par email</h4>
                    <p>Recevoir des emails pour les nouveaux rendez-vous</p>
                </div>
                <label className="switch">
                    <input type="checkbox" defaultChecked />
                    <span className="slider"></span>
                </label>
            </div>
            <div className="notification-option">
                <div className="notification-info">
                    <h4>Notifications SMS</h4>
                    <p>Recevoir des SMS pour les rappels de rendez-vous</p>
                </div>
                <label className="switch">
                    <input type="checkbox" defaultChecked />
                    <span className="slider"></span>
                </label>
            </div>
            <div className="notification-option">
                <div className="notification-info">
                    <h4>Notifications d'annulation</h4>
                    <p>Être notifié des annulations de rendez-vous</p>
                </div>
                <label className="switch">
                    <input type="checkbox" defaultChecked />
                    <span className="slider"></span>
                </label>
            </div>
        </div>
    </div>
}

export default NotificationSettings;