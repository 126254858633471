import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import { truncateText } from "../../../../utils/stringHelper";

const NotificationItem = ({ notification, onClick }) => {
    const { title, message, is_read } = notification;

    return (
        <div
            onClick={() => onClick(notification)}
            className={`flex items-center justify-between gap-3 py-2 px-4 border-b cursor-pointer 
                ${is_read ? "bg-gray-50" : "bg-orange-200"} hover:bg-gray-100`}
        >
            <div className="flex gap-5 items-center justify-between w-full">
                <h6 className="font-medium text-base">{truncateText(title, 50)}</h6>
                <span className="text-sm text-gray-600">
                    {format(notification.created_at, 'd MMMM yyyy HH:mm', {locale: fr})}
                </span>
            </div>
            {!is_read ? <div className="text-blue-500 font-semibold  min-w-20 text-center">New</div> : <div className=" min-w-20 text-green-500 font-semibold text-center">Lu</div>}
        </div>
    );
};

export default NotificationItem;
