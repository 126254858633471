import React, { useState, useEffect, useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';

const DashboardUserMenu = ({ user_name, user_email, user_type, onLogout }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null); // Référence pour le dropdown

    const toggleDropdown = () => setShowDropdown(!showDropdown);

    // Effet pour gérer le clic à l'extérieur
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Si le clic est en dehors du dropdown, fermer le dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        // Ajouter l'écouteur d'événement au document
        document.addEventListener('click', handleOutsideClick);

        // Nettoyer l'écouteur lors du démontage
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleLogout = () => {
        setShowDropdown(false);
        if (onLogout) onLogout();
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <a
                href="#"
                className="flex gap-2 items-center text-gray-800 no-underline"
                onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown();
                }}
            >
                <span className="text-4xl">
                    <FaUserCircle />
                </span>
                <span className="text-gray-700">{user_name}</span>
            </a>
            {showDropdown && (
                <div className="absolute mt-2 right-0 w-64 bg-white shadow-md rounded-lg p-3 z-50">
                    <p className="text-sm text-gray-600 mb-2">
                        <strong></strong> {user_email}
                    </p>
                    <p className="text-sm text-gray-600 mb-3">
                        <strong>Role : </strong> {user_type}
                    </p>
                    <div className="flex flex-col gap-2">
                        <a
                            href="/"
                            className="text-blue-600 no-underline hover:text-blue-800 text-sm"
                        >
                            Revenir à la page d'accueil
                        </a>
                        <button
                            onClick={handleLogout}
                            className="text-red-600 hover:text-red-800 text-sm text-left"
                        >
                            Déconnexion
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardUserMenu;
