import { format } from "date-fns";
import React from "react";
import { Modal, Button } from "react-bootstrap";

// Fonction pour déterminer le style en fonction du type de notification
const getNotificationStyle = (type) => {
    switch (type) {
        case "info":
            return "bg-info text-white";
        case "warning":
            return "bg-warning text-dark";
        case "success":
            return "bg-success text-white";
        case "error":
            return "bg-danger text-white";
        default:
            return "bg-secondary text-white";
    }
};

const NotificationModal = ({ notification, onClose }) => {
    return (
        <Modal show={!!notification} onHide={onClose} centered size="lg">
            <Modal.Header closeButton className={getNotificationStyle(notification?.type)}>
                <Modal.Title>{notification?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between mb-3">
                    <p className="text-muted">
                        {format(new Date(notification?.created_at), "d MMMM yyyy HH:mm")}
                    </p>
                    <div>

                        <span
                            className={`badge p-2 ${notification?.is_read ? "bg-success" : "bg-warning"} text-white`}
                        >
                            {notification?.is_read ? "Lue" : "Non lue"}
                        </span>
                    </div>
                </div>
                <p>{notification?.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotificationModal;
