import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';
import beauticianApi from '../../api/beautician.api';


const SiteInfoBudgetCategorie = ({userData, activeID, setReloadTrigger}) => {
    const [selectedBudget, setSelectedBudget] = useState('');


    useEffect(() => {
        if (userData.budget_category !== "") {
            setSelectedBudget(userData.budget_category);
        }
    }, [userData]);


    const handleSave = async () => {
        if (!selectedBudget) {
            Swal.fire("Information", "Veuillez choisir un budget", "warning");
        } else {
            const update_cat_budget = await beauticianApi.updateBudgetCategorie(localStorage.getItem('token'), activeID, { budget_category: selectedBudget });
            if (update_cat_budget) {
                Swal.fire("Succès", "Catégorie budget modifié avec succès", 'success').then(() => {
                    if(setReloadTrigger) {
                        setReloadTrigger(prev => !prev);
                    }
                });
            } else {
                Swal.fire("Error", "Une erreur s'est produite! Veuillez réessayer plus tard!", 'error');
            }
        }
    };

    return (
        <React.Fragment>
            <Accordion.Header>Choix catégorie budget</Accordion.Header>
            <Accordion.Body className="flex flex-col gap-3">
                <div>
                    <h4 className="mb-3 text-xl">Choisissez une catégorie de budget :</h4>
                    <div className="flex flex-row gap-3">
                        <label>
                            <input
                                type="radio"
                                value="economic"
                                checked={selectedBudget === 'economic'}
                                onChange={(e) => setSelectedBudget(e.target.value)}
                                className="mr-2"
                            />
                            Économique
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="moderate"
                                checked={selectedBudget === 'moderate'}
                                onChange={(e) => setSelectedBudget(e.target.value)}
                                className="mr-2"
                            />
                            Modérée
                        </label>
                        <label>
                            <input
                                type="radio"
                                value="premium"
                                checked={selectedBudget === 'premium'}
                                onChange={(e) => setSelectedBudget(e.target.value)}
                                className="mr-2"
                            />
                            Premium
                        </label>
                    </div>
                </div>
                <button
                    onClick={handleSave}
                    className="self-start px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                    Sauvegarder mon choix
                </button>
            </Accordion.Body>
        </React.Fragment>
    );
};

export default SiteInfoBudgetCategorie;
