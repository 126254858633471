import React from "react";
import Notification from "../client/Notifications";


const BeauticianNotifications = () => {

    return <Notification/>

}


export default BeauticianNotifications;