import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PortalLayout from './PortalLayout';
import BeauticianDashboard from '../../../components/portals/Beauticians/BeauticianDashboard';
import BeauticianProfile from '../../../components/portals/Beauticians/BeauticianProfile';
import BeauticianServices from '../../../components/portals/Beauticians/BeauticianServices';
import BeauticianAgenda from '../../../components/portals/Beauticians/BeauticianAgenda';
import BeauticianStats from '../../../components/portals/Beauticians/BeauticianStats';
import BeauticianSettings from '../../../components/portals/Beauticians/BeauticianSettings';
import BeauticianNotifications from '../../../components/portals/Beauticians/BeauticianNotifications';
import BeauticianHistorique from '../../../components/portals/Beauticians/BeauticianHistorique';

const BeauticianPortal = () => {

  return (
    <Routes>
      <Route element={<PortalLayout />}>
        {/* Route du tableau de bord */}
        <Route index element={<BeauticianDashboard />} />
        
        {/* Autres routes à ajouter ici */}
        <Route path="profile" element={<BeauticianProfile/>} />
        <Route path="services" element={<BeauticianServices/>} />
        <Route path="agenda" element={<BeauticianAgenda/>} />
        <Route path="stats" element={<BeauticianStats/>} />
        <Route path="settings" element={<BeauticianSettings/>} />
        <Route path='notifications' element={<BeauticianNotifications/>} />
        <Route path='historiques' element={<BeauticianHistorique />} />
      </Route>
    </Routes>
  );

};

export default BeauticianPortal;