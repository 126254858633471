import React, { useState } from 'react';
import { ImageIcon } from 'lucide-react';
import { BASE_URL } from '../../../../utils/constants';

const ServiceImage = ({ images }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const itemsPerSlide = 3; // Nombre d'images à afficher par slide

    // Calcul des images à afficher pour le slide actuel
    const getCurrentImages = () => {
        const startIndex = currentSlide * itemsPerSlide;
        return images.slice(startIndex, startIndex + itemsPerSlide);
    };

    return (
        <div className="flex flex-col items-center">
            <div className="w-full max-w-md mx-auto">
                {images?.length > 0 ? (
                    <>
                        {/* Images affichées pour le slide actuel */}
                        <div className="flex gap-3 justify-center">
                            {getCurrentImages().map((image, index) => (
                                <div key={index} className="flex-1 max-w-[30%]">
                                    <div className="aspect-square overflow-hidden rounded-lg">
                                        <img
                                            src={`${BASE_URL}/images/beauticians_subcategories/${encodeURIComponent(image.photo_url)}`}
                                            alt={`Service ${index + 1}`}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Pagination (dots pour changer de slide) */}
                        {images.length > itemsPerSlide && (
                            <div className="flex justify-center mt-3 gap-2">
                                {Array.from({ length: Math.ceil(images.length / itemsPerSlide) }).map((_, idx) => (
                                    <button
                                        key={idx}
                                        className={`w-3 h-3 rounded-full cursor-pointer transition-colors duration-300 ${
                                            currentSlide === idx ? 'bg-gray-700' : 'bg-gray-300'
                                        }`}
                                        onClick={() => setCurrentSlide(idx)}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <div className="text-center text-gray-500">
                        <div className="flex flex-col items-center">
                            <ImageIcon size={40} className="mb-2" />
                            <p>Aucune image</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServiceImage;