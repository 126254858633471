import React, { useContext } from 'react';
import defaultProfile from '../../../assets/images/default-profile.png';
import { BASE_URL } from '../../../utils/constants';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';

import {
  Camera,
  MapPin,
  Mail,
  Phone,
  Edit2,
  Euro,
  User
} from 'lucide-react';
import './BeauticianProfile.css';
import BeauticianGallery from '../../beautician/BeauticianGallery';
import { useNavigate } from 'react-router-dom';
import SocialLinkList from '../../common/SocialLinkList';

import withReactContent from 'sweetalert2-react-content';
//  import ReactSwitch from 'react-switch';
import Swal from 'sweetalert2';
//import beauticianApi from '../../../api/beautician.api';

const BeauticianProfile = () => {
  const navigate = useNavigate();

  const { profileData, setReloadTrigger } = useContext(BeauticianPortalContext);

  const MySwal = withReactContent(Swal);



  const defaultDays = [
    { day: "Lundi", field: "monday" },
    { day: "Mardi", field: "tuesday" },
    { day: "Mercredi", field: "wednesday" },
    { day: "Jeudi", field: "thursday" },
    { day: "Vendredi", field: "friday" },
    { day: "Samedi", field: "saturday" },
    { day: "Dimanche", field: "sunday" },
  ];

  const getImageUrl = (profilePhoto) => {
    if (!profilePhoto) return defaultProfile;
    try {
      return `${BASE_URL}/images/beauticians/${encodeURIComponent(profilePhoto)}`;
    } catch (error) {
      console.error('Error with image path:', error);
      return defaultProfile;
    }
  };

  const handleEditToggle = () => {
    navigate("/beautician-dashboard/settings");
  };

  /*const handleAvailabilityChange = (is_available, beautician_id) => {
    MySwal.fire({
        title: 'Confirmation',
        text: `Voulez-vous vraiment modifier la disponibilité ?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
    }).then(async (result) => {
        if (result.isConfirmed) {
            // Mettre à jour la disponibilité via une API ou une méthode
            await beauticianApi.updateAvailability(localStorage.getItem('token'), beautician_id, { is_available: !is_available });
            setReloadTrigger(prev => !prev);
        }
    });
};*/

  if (!profileData) {
    return <div>Chargement du profil...</div>;
  }

  return (
    <div className="beautician-profile profile-container">
      <div className="flex justify-between w-full p-3 px-5">
        
      <div className="availability-status available">
        <User size={20}/>
        <span>Profil {profileData.type_beautician }</span>
      </div>
      {/*
      <div className="availability-status">
      <ReactSwitch
                        checked={profileData.is_available}
                        onChange={() => handleAvailabilityChange(profileData.is_available, profileData.beautician_id)}
                        offColor="#f87171" // Couleur désactivée
                        onColor="#4ade80" // Couleur activée
                        uncheckedIcon={false}
                        checkedIcon={false}
                        width={40} // Largeur du switch
                        height={20} // Hauteur du switch
                        handleDiameter={15}
                    /> Disponibilité
      </div>*/}
        <button
          className="edit-profile-btn"
          onClick={handleEditToggle}
        >
          <Edit2 size={16} />
          <span>Modifier le profil</span>
        </button>
      </div>
      <div className="profile-header">
        <div className="profile-header-content">
          <div className="profile-avatar-wrapper">
            <div className="profile-avatar">
              <img
                src={getImageUrl(profileData.profile_photo)}
                alt="Profile"
                className="avatar-image"
              />
            </div>
          </div>

          <div className="b-profile-information">
            <div className="profile-titles">
              <h1>{profileData.first_name} {profileData.last_name}</h1>
              <p>{profileData.business_name}</p>
              <p className="bio">{profileData.beautician_description}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="profile-content">
        <div className="profile-main">

          <section className="profile-section-portal">
            <h2>Spécialités</h2>
            <div className="specialities-list">
              {profileData.subcategories.map((speciality, index) => (
                <span key={index} className="speciality-tag">
                  {speciality.name}
                </span>
              ))}
            </div>
          </section>

          <section className="profile-section-portal">
            <h2>Horaires d'ouverture</h2>
            <div className="schedule-grid">
              {defaultDays.map((disp, index) => {

                let availability = profileData.availabilities.find((obj) => obj.day === disp.field);
                if (availability) {
                  return (<div key={index} className="schedule-row">
                    <span className="day">{disp.day}</span>
                    <span className="hours">{availability.start_time.slice(0, 5)} - {availability.end_time.slice(0, 5)}</span>
                  </div>);
                } else {
                  return (<div key={index} className="schedule-row">
                    <span className="day">{disp.day}</span>
                    <span className="hours">Fermé</span>
                  </div>);
                }
              })}
              {/*profileData.availabilities.map((availability, index) => (
                
              ))*/}
            </div>
          </section>
        </div>

        <aside className="profile-sidebar">
          <div className="contact-info">
            <h2>Informations de contact</h2>

            <div className="contact-list">
              <div className="contact-item">
                <MapPin size={20} />
                <span>{profileData.address}</span>
              </div>
              <div className="contact-item">
                <Mail size={20} />
                <span>{profileData.email}</span>
              </div>
              <div className="contact-item">
                <Phone size={20} />
                <span>{profileData.phone}</span>
              </div>
              {/*<div className="contact-item">
                <Globe size={20} />
                <span>{profileData.website}</span>
              </div>*/}
            </div>
          </div>

          <section className="profile-section-portal">
            <h2>Liens vers mes réseaux sociaux</h2>
            <SocialLinkList links={profileData.social_networks} />
          </section>

          <div className="availability-card">
            <h2>Catégorie de budget</h2>
            <div className="availability-status available">
              <Euro size={20} />
              <span>{profileData.budget_category === "moderate" ? "Modéré" : (profileData.budget_category === "premium" ? "Premium" : "Economique")}</span>
            </div>
          </div>
        </aside>
      </div>
      <div className="p-4">
        <h2 className='text-xl'>Photos de mon établissement / Sallon</h2>
        <BeauticianGallery beautician_id={profileData.beautician_id} />
      </div>
    </div>
  );
};

export default BeauticianProfile;