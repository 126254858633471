// src/components/booking/DateSelection.js
import React, { useState, useEffect } from 'react';
import { Calendar, ArrowRight, ArrowLeft } from 'lucide-react';
import './DateSelection.css';
import CustomCalendar from './CustomCalendar';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import availabilityApi from "../../api/availability.api";
import blockedDatesApi from "../../api/blockedDates.api";
import appointmentApi from '../../api/appointement.api';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useBooking } from '../../context/BookingContext';
import { getBeauticianIdBySlug } from '../../utils/functions';

import TimeSlots from './TimeSlots';
import { showErrorMessage } from '../../utils/messageHelper';
import MySpinner from '../loader/Spinner';

const DateSelection = ({ onNextStep, onPrevStep }) => {
  const { slug } = useParams();
  const { selectedServices, selectedDate, setSelectedDate, selectedTime, setSelectedTime } = useBooking();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [dispoHebdo, setDispoHebdo] = useState([]);
  const [dispoHebdoTime, setDispoHebdoTime] = useState([]);
  const [blockedDates, setBlockedDates] = useState([]);
  const [blockedDatesWithTime, setBlockedDatesWithTime] = useState([]);
  const [validAppointments, setValidAppointments] = useState([]);

  const calculateTotal = () => {
    return selectedServices.reduce((acc, service) => ({
      price: acc.price + Number(service.beautician_subcategory_price),
      duration: acc.duration + Number(service.beautician_subcategory_duration)
    }), { price: 0, duration: 0 });
  };

  function getFullyBlockedDays(blockedDates) {
    const fullyBlockedDays = [];

    blockedDates.forEach((entry) => {
      if (entry.start_time === null && entry.end_time === null) {
        // Convertir date_start et date_end en Date sans erreurs de fuseau
        const startDate = parseISO(entry.date_start);
        const endDate = parseISO(entry.date_end);

        // Obtenir toutes les dates entre startDate et endDate
        const days = eachDayOfInterval({ start: startDate, end: endDate });

        // Ajouter chaque jour au tableau sous forme de chaîne formatée (YYYY-MM-DD)
        days.forEach((day) => {
          const dayString = format(day, 'yyyy-MM-dd');
          if (!fullyBlockedDays.includes(dayString)) {
            fullyBlockedDays.push(dayString);
          }
        });
      }
    });

    return fullyBlockedDays;
  }


  const fetchBlockedDates = async (id) => {
    try {
      const response = await blockedDatesApi.getBlockedDates(id);
      if (response && Array.isArray(response)) {
        setBlockedDatesWithTime(response);
        let blocked_dates = getFullyBlockedDays(response);
        setBlockedDates(blocked_dates);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur", "Impossible de charger les jours bloqués.", "error");
    }
  };

  const fetchAvailabilities = async (id) => {
    try {
      const response = await availabilityApi.getBeauticianAvailability(id);
      if (response && Array.isArray(response)) {
        const dispos = [];
        const disposTime = [];
        response.map((element) => {
          if (element.start_time !== null && element.end_time !== null) {
            dispos.push(element.day);
            disposTime.push(element);
          }
        });
        setDispoHebdo(dispos);
        setDispoHebdoTime(disposTime);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Erreur", "Impossible de charger les disponibilités.", "error");
    }
  };

  const fetchValidAppointments = async (id) => {
    try {
      const response = await appointmentApi.listAppointmentsValidByBeautician(id);
      if (response && Array.isArray(response)) {
        console.log(response);
        setValidAppointments(response);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const beauticianId = await getBeauticianIdBySlug(slug);  // Get the id before setting it

      if (beauticianId) {

        try {
          await Promise.all([
            fetchAvailabilities(beauticianId),
            fetchBlockedDates(beauticianId),
            fetchValidAppointments(beauticianId)
          ]);
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        } catch (error) {
          setIsLoading(false);
          showErrorMessage("Une erreur s'est produite lors du charmement des données!");
        }
      } else {
        navigate("/404");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="date-selection">
      {/* En-tête avec la durée totale */}
      <div className="date-selection-header">
        <div className="total-duration">
          <Calendar size={20} />
          <span>Durée totale : {calculateTotal().duration} min</span>
        </div>
      </div>

      {/* Navigation des dates */}
      {isLoading ?
        <MySpinner height={"55vh"}/> :
        (<>
          <div className="date-selection-header flex flex-col items-center gap-3">
            <h4>Selectionnez la date</h4>
            <CustomCalendar
              selectedDate={selectedDate}
              setSelectedDate={handleDateSelect}
              dispoHebdo={dispoHebdo}
              blockedDates={blockedDates}
            />
          </div>

          {/* Créneaux horaires */}
          {(selectedDate && dispoHebdoTime) && (
            <TimeSlots
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              handleTimeSelect={handleTimeSelect}
              dispoHebdoTime={dispoHebdoTime}
              duration={calculateTotal().duration}
              validAppointments={validAppointments}
              blockedDates={blockedDatesWithTime}
            />
          )}</>)}

      {/* Barre de confirmation */}
      <div className="flex justify-between mt-4">
        <button className='next-step-button' onClick={onPrevStep}>
          <ArrowLeft size={20} />
          Retour
        </button>
        <button
          className="next-step-button"
          disabled={!selectedDate || !selectedTime}
          onClick={onNextStep}
        >
          Suivant
          <ArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default DateSelection;