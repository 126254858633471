import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OnglerieFrenchManucure from '../../assets/prestations/onglerie.png';
import SoinVisage from '../../assets/prestations/soin du visage.png';
import SoinCorps from '../../assets/prestations/soin du corps.png';
import MaquillagePro from '../../assets/prestations/maquillage.png';
import EpilationVisage from '../../assets/prestations/epilation.png';
import BeauteRegard from '../../assets/prestations/beaute_regard.png';
import SoinDentaire from '../../assets/prestations/soins dentaire.png';
import './Services.css';

const ServiceCard = ({ image, title }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('service', title.toLowerCase());
    
    navigate({
      pathname: '/search',
      search: searchParams.toString()
    });
  };

  return (
    <div 
      className="service-card"
      onClick={handleClick}
      role="button"
      tabIndex={0}
      style={{ cursor: 'pointer' }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleClick();
        }
      }}
    >
      <div className="service-card-content">
        <div className="service-image-wrapper">
          <div className="bg-gradient-to-r from-[#bd193b] to-[#e85474] p-1 rounded-full">
            <div className="bg-white p-1 rounded-full">
              <img src={image} alt={title} className="service-image" />
            </div>
          </div>
        </div>
        <div className="service-text">
          <h3 className="service-title">{title}</h3>
        </div>
      </div>
    </div>
  );
};

export const ServiceCardList = ({where}) => {
  const services = [
    { title: "Soin du visage", image: SoinVisage },
    { title: "Soin du corps", image: SoinCorps },
    { title: "Beauté du regard", image: BeauteRegard },
    { title: "Maquillage", image: MaquillagePro },
    { title: "Onglerie", image: OnglerieFrenchManucure },
    { title: "Épilation", image: EpilationVisage },
    { title: "Soin dentaire", image: SoinDentaire }
  ];

  const duplicatedServices = [...services, ...services, ...services];
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="services-container">
      <h2 className="text-3xl font-bold text-center mb-12">Nos Prestations :</h2>
      <div 
        className="services-carousel"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={`services-track ${isHovered ? 'paused' : ''}`}>
          {duplicatedServices.map((service, index) => (
            <ServiceCard 
              key={index}
              {...service}
              where={where}
            />
          ))}
        </div>
      </div>
    </div>
  );
};