import React, { useState, useEffect } from 'react'

import { Save } from 'lucide-react'
import { Form, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import userApi from '../../../api/user.api'

const EditModal = ({ openModal, user, setOpenModal, setReloadTrigger }) => {

    const validateInfoUserFields = (infoUser) => {
        if (!infoUser.last_name || !infoUser.first_name || !infoUser.phone) {
            Swal.fire('Erreur', 'Veuillez remplir les champs : nom, prénom(s), email, téléphone!', 'error');
            return false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(infoUser.phone)) {
            Swal.fire('Erreur', 'Le numéro de téléphone n\'est pas valide!', 'error');
            return false;
        }

        return true;
    };

    const handleSave = async (e) => {
        e.preventDefault()
        const userInfo = {
            user_id: localStorage.getItem("userId"),
            email: email, // pour la modification : TODO à enlever
            last_name: lastname,
            first_name: firstname,
            phone: phoneNumber
        }
        if (!validateInfoUserFields(userInfo)) return;

        Swal.fire({
            title: 'Chargement...',
            text: 'Veuillez patienter...',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        try {

            const update_profile = await userApi.updateProfile(localStorage.getItem('token'), userInfo);

            if (update_profile.success) {
                Swal.close();
                setTimeout(() => {
                    Swal.fire("Succès", 'Modification reuissi!', 'success').then(() => setReloadTrigger(prev => !prev));
                    setOpenModal(false);
                }, 200);
            } else {
                Swal.close();
                setTimeout(() => {
                    Swal.fire('Erreur', update_profile.message || 'Erreur lors de la modification du profil.', 'error');
                }, 200);
            }
        } catch (error) {
            Swal.close();
            setTimeout(() => {
                Swal.fire('Erreur', error.message || 'Erreur lors de la modification du profil.', 'error');
            }, 200);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault()
        setOpenModal(false)
    }

    const [email, setEmail] = useState(user.email)
    const [lastname, setLastName] = useState(user.last_name)
    const [firstname, setFirstName] = useState(user.first_name)
    const [phoneNumber, setPhoneNumber] = useState(user.phone)
    //const [newPassword, setNewPassword] = useState("")
    //const [confirmPassword, setConfirmPassword] = useState("")


    return <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        className="flex items-center justify-center"
        dialogClassName="h-[90vh] flex items-center justify-center"
    >
        <section>
            <Form className="py-2 flex flex-col gap-3 text-gray-700 font-semibold">
                <h3 className="text-center text-gray-700 font-normal my-3">Modifier mes informations</h3>

                <Form.Group className="grid grid-cols-3 items-center gap-2">
                    <Form.Label className="col-span-1">Nom et prénom</Form.Label>
                    <div className="col-span-2 flex gap-2">
                        <Form.Control type="text" placeholder="Nom" value={lastname} onChange={(e) => setLastName(e.target.value)} />
                        <Form.Control type="text" placeholder="Prénom" value={firstname} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                </Form.Group>
                {/*<Form.Group className="grid grid-cols-3 items-center gap-2">
                    <Form.Label className="col-span-1">E-mail</Form.Label>
                    <Form.Control type="email" placeholder="Votre e-mail" value={email} className="col-span-2" onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>*/}
                <Form.Group className="grid grid-cols-3 items-center gap-2">
                    <Form.Label className="col-span-1">Téléphone</Form.Label>
                    <Form.Control type="phone" placeholder="Votre numéro de téléphone" value={phoneNumber} className="col-span-2" onChange={(e) => setPhoneNumber(e.target.value)} />
                </Form.Group>
                {/*<Form.Group>
                    <Form.Label>Mot de passe</Form.Label>
                    <Form.Control type="password" placeholder="Nouveau mot de passe" value={newPassword} autoComplete="off" onChange={(e) => setNewPassword(e.target.value)} />
                    <Form.Control type="password" placeholder="Confirmer le mot de passe" className="mt-2" autoComplete="off" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                </Form.Group>*/}

                <div className="flex items-center gap-2">
                    <button
                        onClick={handleCancel}
                        className="ms-auto px-3 py-2 border-2 rounded"
                    >
                        Annuler
                    </button>
                    <button
                        onClick={handleSave}
                        className="px-3 py-2 flex items-center gap-2 text-white bg-[#b91c1c] border-2 rounded"
                    >
                        <Save />
                        Enregistrer
                    </button>
                </div>
            </Form>
        </section>
    </Modal>
}

export default EditModal