// ResultsColumn.js adapté
import React, { useState, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import BeauticianCard from '../beautician/BeauticianCard';
import './ResultsColumn.css';

const ITEMS_PER_PAGE = 10;

const LoadingState = () => (
    <div className="loading-state">
        <div className="loading-spinner"></div>
        <p>Chargement des résultats...</p>
    </div>
);

const NoResults = () => (
    <div className="no-results">
        <p>Aucun résultat ne correspond à vos critères de recherche.</p>
        <p>Suggestions :</p>
        <ul>
            <li>Vérifiez l'orthographe des mots-clés</li>
            <li>Essayez d'élargir votre zone de recherche</li>
            <li>Utilisez moins de filtres</li>
        </ul>
    </div>
);

const ResultsColumn = ({ beauticians = [], isLoading, selectedBeautician }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const columnRef = useRef(null);
    
    const validBeauticians = beauticians.filter(b => b && b.id);
    const totalPages = Math.ceil(validBeauticians.length / ITEMS_PER_PAGE);

    const getCurrentPageItems = () => {
        const start = currentPage * ITEMS_PER_PAGE;
        return validBeauticians.slice(start, start + ITEMS_PER_PAGE);
    };

    const handlePageChange = (newPage) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setCurrentPage(newPage);
            setTimeout(() => {
                setIsTransitioning(false);
            }, 50);
        }, 300);

        if (columnRef.current) {
            columnRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    if (isLoading) {
        return (
            <div className="results-column loading">
                <LoadingState />
            </div>
        );
    }

    return (
        <div className="results-column" ref={columnRef}>
            <div className="results-header">
                <h2>
                    {validBeauticians.length === 0 
                        ? 'Aucune esthéticienne trouvée'
                        : `${validBeauticians.length} esthéticiennes trouvées`
                    }
                </h2>
            </div>
            
            {validBeauticians.length > 0 ? (
                <>
                    <div className={`beauticians-grid ${isTransitioning ? 'transitioning' : ''}`}>
                        {getCurrentPageItems().map((beautician) => (
                            <div 
                                key={beautician.id} 
                                className={`beautician-card-wrapper ${
                                    selectedBeautician?.id === beautician.id ? 'selected' : ''
                                }`}
                            >
                                <BeauticianCard 
                                    beautician={{
                                        ...beautician,
                                        name: beautician.business_name,
                                        rating: beautician.average_rating,
                                        price_range: beautician.budget_category,
                                        image: beautician.profile_photo
                                    }} 
                                />
                            </div>
                        ))}
                    </div>

                    {totalPages > 1 && (
                        <div className="pagination">
                            <button 
                            className="pagination-button prev"
                            onClick={() => handlePageChange(Math.max(0, currentPage - 1))}
                            disabled={currentPage === 0}
                            >
                            <ChevronLeft size={16} />
                            <span className="button-text">Précédent</span>
                            </button>

                            {Array.from({ length: totalPages }).map((_, index) => {
                            // Logique pour déterminer quels numéros afficher
                            const shouldShow = 
                                index === 0 || // Premier
                                index === totalPages - 1 || // Dernier
                                Math.abs(index - currentPage) <= 1; // Autour de la page courante
                            
                            if (!shouldShow) {
                                if (index === currentPage - 2 || index === currentPage + 2) {
                                return <span key={`ellipsis-${index}`} className="pagination-ellipsis">...</span>;
                                }
                                return null;
                            }

                            return (
                                <button
                                key={index}
                                className={`pagination-button number ${index === currentPage ? 'active' : ''}`}
                                onClick={() => handlePageChange(index)}
                                >
                                {index + 1}
                                </button>
                            );
                            })}

                            <button 
                            className="pagination-button next"
                            onClick={() => handlePageChange(Math.min(totalPages - 1, currentPage + 1))}
                            disabled={currentPage === totalPages - 1}
                            >
                            <span className="button-text">Suivant</span>
                            <ChevronRight size={16} />
                            </button>
                        </div>
                        )}
                                        </>
            ) : (
                <NoResults />
            )}
        </div>
    );
};

export default ResultsColumn;