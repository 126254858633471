import { API_URL } from "../utils/constants";

export const sitesApi = {

    update: async (data, token) => {
        try {
            const response = await fetch(`${API_URL}/sites/update`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jour du site');
        }

    },

    getSocialLinks: async (site_id, token) => {
        try {
            const response = await fetch(`${API_URL}/sites/${site_id}/socials`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la recuperation du social links');
        }
    },

    getSiteByBeauticianId: async (beautician_id) => {
        try {
            const response = await fetch(`${API_URL}/sites/socials/${beautician_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la recuperation du social links');
        }
    },


    saveSocialLink: async (data, token) => {
        try {
            const response = await fetch(`${API_URL}/sites/socials/create-update`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du social links');
        }
    },

    getOrCreateSiteByBeauticianId : async (beautician_id, token) => {
        try {
            const response = await fetch(`${API_URL}/sites/get-or-create/${beautician_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création / recuperation du site');
        }
    },

}