import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Navigate,
  Outlet,
  Route
} from 'react-router-dom';
import { BookingProvider } from './context/BookingContext';
import { BeauticianPortalProvider } from './context/BeauticianPortalContext';
import SEOWrapper from './components/SEOWrapper';

// Layouts et composants de page
import Layout from './components/layout/Layout';
import Home from './pages/home/Home';
import Concept from './pages/concept/Concept';
import Search from './pages/search/Search';
import Contact from './pages/contact/Contact';
import Dashboard from './pages/dashboard/Dashboard';
import Users from './pages/dashboard/user/Users';
import NotFound from './pages/notFound/NotFound';
import Loader from './components/loader/Loader';
import BeauticianProfile from './pages/beautician/BeauticianProfile';
import AjoutEtablissement from './pages/ajoutEtablissement/AjoutEtablissement';
import BookingPage from './pages/booking/BookingPage';
import BeauticianPortal from './pages/portals/Beauticians/BeauticianPortal';
import ClientPortal from './pages/portals/client/ClientPortal';

// Styles
import './App.css';
import Swal from 'sweetalert2';
import { AppContextProvider } from './contexts/AppContext';

import { API_URL } from './utils/constants';
import SearchContextProvider from './context/SearchContext';
import { HeaderContextProvider } from './contexts/HeaderContext';
import EmailConfirmation from './pages/EmailConfirmation';

// Protection des routes avec gestion des rôles
const AuthWrapper = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// Protection des routes admin/opérateur
const AdminWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || !['admin', 'operator'].includes(userType)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// Protection des routes beautician
const BeauticianWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || userType !== 'beautician') {
    return <Navigate to="/" replace />;
  }

  return (
    <BeauticianPortalProvider>
      <Outlet />
    </BeauticianPortalProvider>
  );
};

//search context
const SearchWrapper = () => {
  return <SearchContextProvider>
    <Outlet />
  </SearchContextProvider>
}

//routes clients 
const ClientWrapper = () => {
  const token = localStorage.getItem('token');
  const userType = localStorage.getItem('userType');

  if (!token || userType !== 'client') {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

// Layout principal avec BookingProvider
const LayoutWrapper = () => (
  <BookingProvider>
    <HeaderContextProvider>
      <Layout>
        <Outlet />
      </Layout>
    </HeaderContextProvider>
  </BookingProvider>
);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const minLoadingTime = 1000;
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, minLoadingTime);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/users/verify-token-validity`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Token invalide ou expiré');
        }
      } catch (error) {
        localStorage.clear();
        await Swal.fire('Informations', 'Votre session a expiré. Veuillez vous reconnecter.', 'warning');
        window.location.reload();
      }
    };

    checkToken();
  }, []);

  return (
    <AppContextProvider>
      <BrowserRouter>
        <SEOWrapper>
          <Loader isLoading={isLoading} />
          <Routes>
            {/* Routes publiques avec Layout */}
            <Route element={<LayoutWrapper />}>
              <Route index element={<Home />} />
              <Route path="concept" element={<Concept />} />

              {/*search wrapper */}
              <Route element={<SearchWrapper />}>
                <Route path="search" element={<Search />} />
              </Route>


              <Route path="contact" element={<Contact />} />
              <Route path="beauticians/:slug" element={<BeauticianProfile />} />
              <Route path="beauticians/:slug/booking" element={<BookingPage />} />
              <Route path="ajouter-etablissement" element={<AjoutEtablissement />} />

              <Route element={<ClientWrapper />}>
                <Route path='client-dashboard/*' element={<ClientPortal />} />
              </Route>

              <Route path="404" element={<NotFound />} />
            </Route>

              <Route path="confirm-user-registration" element={<EmailConfirmation />} />
            {/* Routes Beautician */}
            <Route element={<BeauticianWrapper />}>
              <Route path="beautician-dashboard/*" element={<BeauticianPortal />} />
            </Route>

            {/* Routes authentifiées */}
            <Route element={<AuthWrapper />}>
              {/* Routes Admin/Opérateur */}
              <Route element={<AdminWrapper />}>
                <Route path="dashboard" element={<Dashboard />}>
                  <Route index element={<Users />} />
                  {/* Autres routes dashboard admin */}
                </Route>
              </Route>
            </Route>

            {/* Redirection par défaut */}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </SEOWrapper>
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;