import React, { useState, useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import BeauticianPortalHeader from '../../../components/portals/Beauticians/BeauticianPortalHeader';
import BeauticianPortalSidebar from '../../../components/portals/Beauticians/BeauticianPortalSidebar';
import './PortalLayout.css';
import { BeauticianPortalContext } from '../../../context/BeauticianPortalContext';
import MySpinner from '../../../components/loader/Spinner';

const PortalLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const {isParentLoading} = useContext(BeauticianPortalContext);

  const location = useLocation();

  useEffect(() => {
    // Scroll vers le haut à chaque changement de route
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="portal-wrapper">
      <BeauticianPortalHeader 
        isSidebarOpen={isSidebarOpen}
        onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      
      <div className="portal-content">
        <aside className={`sidebar ${isSidebarOpen ? 'sidebar-open' : ''}`}>
          <BeauticianPortalSidebar onLinkClick={() => setIsSidebarOpen(false)} />
        </aside>

        <main className="main-container">
          {!isParentLoading && <Outlet />}
          {isParentLoading && <MySpinner height={"70vh"}/>}
        </main>
      </div>
    </div>
  );
};

export default PortalLayout;