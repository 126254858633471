import { API_URL } from "../utils/constants";

const serviceSubcategoriesApi = {
    // Obtention d'un sous service par id
    getServicesSubByID: async(id) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération du sous service');
        }
    },
    // Obtention du sous service par id du category
    getServicesSubByServiceID: async(category_id) => {
        try {
            const response = await fetch(`${API_URL}/subcatsegories/category/${category_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la récupération des sous services');
        }
    },
    // Création d'un sous service
    createServiceSubcategories: async(token, subcategoriesData) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subcategoriesData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la création du sous service');
        }
    },
    // Mettre à jour un sous service
    updateServiceSubcategories: async(token, subcategoriesID, subcategoriesData) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${subcategoriesID}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subcategoriesData)
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la mise à jours du sous service');
        }
    },
    // Supprimer un sous service
    deleteServiceSubcategories: async(token, subcategoriesID) => {
        try {
            const response = await fetch(`${API_URL}/services/subcategories/${subcategoriesID}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return await response.json();
        } catch (error) {
            throw new Error('Erreur lors de la suppresion du sous service');
        }
    },
};

export default serviceSubcategoriesApi;