import React, { useState, useEffect } from "react";
import { FaCamera } from 'react-icons/fa6';
import Accordion from 'react-bootstrap/Accordion';
import { sitesApi } from '../../api/sites.api';
import { FaSave, FaFileUpload } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { BASE_URL } from "../../utils/constants";

const SiteInfoApropos = ({ site, activeID, setReloadTrigger }) => {
    const [apropos, setApropos] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");

    // Récupérer les données existantes au chargement
    useEffect(() => {
        setApropos(site.about_text || '');
        if (site.logo_url) {
            setPreview(`${BASE_URL}/images/beauticians/${encodeURIComponent(site.logo_url)}`);
        }

    }, [site]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(file);

            // Générer un aperçu
            const reader = new FileReader();
            reader.onload = (e) => setPreview(e.target.result);
            reader.readAsDataURL(file);
            setUploadStatus('');
        }
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            // Si on a déjà un logo et qu'on n'a pas sélectionné de nouveau fichier
            if (site?.logo_url) {
                return site.logo_url;
            }
            setUploadStatus("Veuillez sélectionner une image.");
            return '';
        }

        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch(`${BASE_URL}/api/upload`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileName;
            } else {
                const error = await response.json();
                setUploadStatus(`Erreur: ${error.error}`);
                return '';
            }
        } catch (error) {
            console.error('Error during upload:', error);
            setUploadStatus("Erreur lors de l'upload.");
            return '';
        }
    };

    const saveApropos = async () => {
        if (!apropos.trim()) {
            Swal.fire({
                title: 'Champs manquants',
                text: "Le champ 'A propos' ne peut pas être vide.",
                icon: 'warning',
                confirmButtonText: 'OK',
            });
            return;
        }

        if (!selectedFile && !site?.logo_url) {
            Swal.fire({
                title: 'Champs manquants',
                text: "Veuillez sélectionner une image pour le logo.",
                icon: 'warning',
                confirmButtonText: 'OK',
            });
            return;
        }

        const logo_url = await handleUpload();

        if (logo_url !== '' || site?.logo_url) {
            let data = {
                beautician_id: activeID,
                about_text: apropos,
                logo_url: logo_url || site.logo_url,
            }

            if (site) {
                // Mise à jour
                let response = await sitesApi.update(data, localStorage.getItem("token"));
                if (response.success) {
                    Swal.fire({
                        title: 'Succès!',
                        text: 'Site mis à jour avec succès',
                        icon: 'success',
                        confirmButtonText: 'OK',
                    }).then(() => {
                        if(setReloadTrigger) {
                            setReloadTrigger(prev => !prev);
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Une erreur est survenue lors de l\'opération.',
                        icon: 'error',
                        confirmButtonText: 'Réessayer',
                    });
                }
            } 

        }
    }

    return (
        <React.Fragment>
            <Accordion.Header>A propos du site et logo du site</Accordion.Header>
            <Accordion.Body>
                <textarea
                    className="resize-none p-2 w-full rounded border shadow-sm"
                    value={apropos}
                    onChange={(e) => setApropos(e.target.value)}
                    placeholder="A propos du site"
                />
                <div className="w-full flex flex-col items-center justify-center">
                    <label htmlFor="uploadLogo" className="cursor-pointer flex flex-col items-center justify-center">
                        <div className="dashed size-24 flex items-center justify-center">
                            {preview ? (
                                <div>
                                    <img
                                        src={preview}
                                        alt="Preview"
                                        className="w-16 h-16 object-cover rounded-full border"
                                    />
                                </div>
                            ) : <span className="text-4xl"><FaCamera /></span>}
                        </div>
                        <div className="flex justify-center gap-2 items-center">
                            <FaFileUpload />
                            Ajouter une photo de profil
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                id="uploadLogo"
                                style={{ display: 'none' }}
                            />
                        </div>
                    </label>

                    {uploadStatus && <p className="mt-2 text-sm text-red-500">{uploadStatus}</p>}
                </div>
                <button
                    className="px-3 py-2 mt-2 ms-auto flex gap-2 items-center text-white bg-blue-600 rounded shadow"
                    onClick={saveApropos}
                >
                    <FaSave /> {'Enregistrer'}
                </button>
            </Accordion.Body>
        </React.Fragment>
    );
}

export default SiteInfoApropos;