// payment.api.js
import { API_URL } from "../utils/constants";

const abonnementApi = {

    getAbonnementByUserId: async (token, userId) => {
        const response = await fetch(`${API_URL}/abonnement/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        return response.json();
    },

    createPaymentIntent: async (token, abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/create-payment-intent`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(abonnementData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la création du paiement');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans createPaymentIntent:', error);
            throw error;
        }
    },

    addOrUpdateAbonnement: async (token, abonnementData) => {
        try {
            const response = await fetch(`${API_URL}/abonnement/create-or-update-abonnement`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(abonnementData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de la sauvegarde du rendez-vous');
            }

            return await response.json();
        } catch (error) {
            console.error('Erreur dans saveAppointment:', error);
            throw error;
        }
    }
}

export default abonnementApi;